import { useEffect, useState } from 'react';
import { Divider, Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { formatDate, formatNumber } from 'helpers/formatters';
import MDBox from 'material-ui/components/MDBox';
import StyledDataGrid from 'components/styled-data-grid';
import { useQuery } from '@tanstack/react-query';
import MDTypography from 'material-ui/components/MDTypography';
import { IVM_GridOrder } from 'models/views/gridOrderVm';
import IntegrationOrderService from 'services/integration-order';
import { IOrderRecipient } from 'models/orderRecipient';
import { OrderType } from 'models/enums/OrderType';
import MDCard from 'material-ui/components/MDCard';
import { OrderStatus } from 'models/enums/orderStatus';
import { orderProductHasNoEnvelope } from 'models/enums/ProductTypes';
import PreviewCardImagesModal from 'components/preview-card-images-modal';
import Loader from 'components/loader';
import { getColumns } from 'helpers/integrationOrderDetailColumns';

interface IntegrationOrderDetailProps {
  orderId: number;
}

function IntegrationOrderDetail({ orderId }: IntegrationOrderDetailProps) {
  const [order, setOrder] = useState<IVM_GridOrder | null>(null);

  const { getIntegrationOrder } = IntegrationOrderService();
  const { getIntegrationOrderRecipients } = IntegrationOrderService();

  const [recipients, setRecipients] = useState<IOrderRecipient[]>([]);
  const [usedColumns, setUsedColumns] = useState<string[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [showPreviewImagesModal, setShowPreviewImagesModal] = useState<boolean>(false);

  const getOrderRecipientsQuery = useQuery({
    queryKey: ['orderRecipients', orderId],
    queryFn: () => getIntegrationOrderRecipients(orderId),
  });

  useEffect(() => {
    if (getOrderRecipientsQuery.isFetched) {
      setRecipients(getOrderRecipientsQuery.data.payload.recipients);
      setUsedColumns(getOrderRecipientsQuery.data.payload.usedColumns);
    }
  }, [getOrderRecipientsQuery]);

  useEffect(() => {
    getIntegrationOrder(orderId).then((result) => {
      setOrder(result.payload);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (order) {
      setColumns(getColumns(order));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  if (!order) {
    return (
      <MDBox p={2} display="flex" justifyContent="center">
        <Loader />
      </MDBox>
    );
  }

  return (
    <MDBox p={1}>
        <MDCard border={true} boxShadow={false} borderRadiusSize="lg">
          <Grid container px={3} py={2} justifyContent="space-between" flexWrap="nowrap">
              <Grid item xs={2}>
                <MDTypography color="secondary" fontWeight="bold">
                  Integration Details:
                </MDTypography>

                <MDBox mt={2}>
                  <MDBox>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <MDTypography variant="paragraphRegular">Id:</MDTypography>
                      <MDTypography fontWeight="regular" variant="paragraphRegular">
                        {order.id}
                      </MDTypography>
                    </Stack>
                  </MDBox>
                  <MDBox mt={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <MDTypography variant="paragraphRegular">Name:</MDTypography>
                      <MDTypography fontWeight="regular" variant="paragraphRegular">
                        {order.name}
                      </MDTypography>
                    </Stack>
                  </MDBox>
                  <MDBox mt={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <MDTypography variant="paragraphRegular">Status:</MDTypography>
                      <MDTypography fontWeight="regular" variant="paragraphRegular">
                        {order.status}
                      </MDTypography>
                    </Stack>
                  </MDBox>

                  {order.orderType === OrderType.Normal && (
                    <>
                      <MDBox mt={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <MDTypography variant="paragraphRegular">Recipients:</MDTypography>
                          <MDTypography fontWeight="regular" variant="paragraphRegular">
                            {formatNumber(order.recipientCount)}
                          </MDTypography>
                        </Stack>
                      </MDBox>
                      {/* not in draft */}
                      {order.status !== OrderStatus.Draft && order.paidDate && (
                        <MDBox mt={1}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <MDTypography variant="paragraphRegular">Paid Date:</MDTypography>

                            <MDTypography fontWeight="regular" variant="paragraphRegular">
                              {formatDate(order.paidDate)}
                            </MDTypography>
                          </Stack>
                        </MDBox>
                      )}
                      {/* in production */}
                      {(order.status === OrderStatus.InProduction ||
                        order.status === OrderStatus.ReadyForProduction ||
                        order.status === OrderStatus.InShipping) && (
                          <MDBox mt={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <MDTypography variant="paragraphRegular">
                                In Production Date:{' '}
                              </MDTypography>

                              <MDTypography fontWeight="regular" variant="paragraphRegular">
                                {formatDate(order.statusDate)}
                              </MDTypography>
                            </Stack>
                          </MDBox>
                        )}
                      {order.status === OrderStatus.Mailed && order.mailedDate && (
                        <MDBox mt={1}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <MDTypography variant="paragraphRegular">Mailed Date:{' '}</MDTypography>

                            <MDTypography fontWeight="regular" variant="paragraphRegular">
                              {formatDate(order.mailedDate)}
                            </MDTypography>
                          </Stack>
                        </MDBox>
                      )}
                    </>
                  )}
                  {order.orderType === OrderType.Integration && (
                    <>
                      <MDBox mt={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <MDTypography variant="paragraphRegular">Queued: </MDTypography>

                          <MDTypography fontWeight="regular" variant="paragraphRegular">
                            {formatNumber(order.queueCount)}
                          </MDTypography>
                        </Stack>
                      </MDBox>

                      <MDBox mt={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <MDTypography variant="paragraphRegular">In Production: </MDTypography>

                          <MDTypography fontWeight="regular" variant="paragraphRegular">
                            {formatNumber(order.inProductionCount)}
                          </MDTypography>
                        </Stack>
                      </MDBox>

                      <MDBox mt={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <MDTypography variant="paragraphRegular">Mailed: </MDTypography>

                          <MDTypography fontWeight="regular" variant="paragraphRegular">
                            {formatNumber(order.mailedCount)}
                          </MDTypography>
                        </Stack>
                      </MDBox>

                      {order.mailedDate ? (
                        <MDBox mt={1}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <MDTypography variant="h6">Mailed Date:{' '}</MDTypography>

                            <MDTypography fontWeight="regular" variant="paragraphRegular">
                              {formatDate(order.mailedDate)}
                            </MDTypography>
                          </Stack>
                        </MDBox>
                      ) : null}
                    </>
                  )}
                </MDBox>
              </Grid>

              <Grid item>
                <Divider orientation="vertical" light={true} />
              </Grid>

              <Grid item>
                <MDTypography color="secondary" fontWeight="bold">
                  Analytics:
                </MDTypography>

                <MDBox mt={2}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <MDTypography variant="paragraphRegular">Qr Scans:</MDTypography>
                    <MDTypography fontWeight="regular" variant="paragraphRegular">
                      {getOrderRecipientsQuery.data?.payload?.recipients?.reduce(
                        (total: number, orderRecipient: any) =>
                          total + orderRecipient.qrScanCount,
                        0
                      )}
                    </MDTypography>
                  </Stack>
                </MDBox>

                <MDBox mt={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <MDTypography variant="paragraphRegular">Calls Received:</MDTypography>
                    <MDTypography fontWeight="regular" variant="paragraphRegular">
                      {order.callsReceivedCount ? formatNumber(order.callsReceivedCount) : 0}
                    </MDTypography>
                  </Stack>
                </MDBox>

                <MDBox mt={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <MDTypography variant="paragraphRegular">Total Call Time:</MDTypography>
                    <MDTypography fontWeight="regular" variant="paragraphRegular">
                      {order.totalCallTime ? formatNumber(order.totalCallTime) : 0} minutes
                    </MDTypography>
                  </Stack>
                </MDBox>
              </Grid>

              <Grid item>
                <Divider orientation="vertical" light={true} />
              </Grid>

              <Grid item>
                <Grid
                  container
                  gap={2}
                  flexWrap="nowrap"
                  sx={{ width: '800px', cursor: 'pointer' }}
                  onClick={() => setShowPreviewImagesModal(true)}
                >
                  <Grid item flex={1}>
                    <MDTypography mb={1} fontWeight="bold" color="secondary">
                      Card Front:
                    </MDTypography>

                    <MDCard borderRadiusSize={0} boxShadow={false} border={true}>
                      <img
                        alt=""
                        style={{ width: '100%', height: '100%' }}
                        src={order.sceneFrontFull}
                        key={order.sceneFrontFull}
                      />
                    </MDCard>
                  </Grid>

                  <Grid item flex={1}>
                    <MDTypography mb={1} fontWeight="bold" color="secondary">
                      Card Rear:
                    </MDTypography>

                    <MDCard borderRadiusSize={0} boxShadow={false} border={true}>
                      <img
                        alt=""
                        style={{ width: '100%', height: '100%' }}
                        src={order.sceneBackFull}
                        key={order.sceneBackFull}
                      />
                    </MDCard>
                  </Grid>

                  {!orderProductHasNoEnvelope(order.product) ? (
                    <Grid item xs={4} flex={1}>
                      <MDTypography mb={1} fontWeight="bold" color="secondary">
                        Envelope:
                      </MDTypography>

                      <MDCard borderRadiusSize={0} boxShadow={false} border={true}>
                        <img
                          alt=""
                          style={{ width: '100%', height: '100%' }}
                          src={order.envelopeFull}
                          key={order.envelopeFull}
                        />
                      </MDCard>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
          </Grid>
        </MDCard>

      <MDBox height="400px" mt={1}>
        {columns.length ? (
          <StyledDataGrid
            loading={getOrderRecipientsQuery.isLoading}
            rows={recipients ?? []}
            columns={[...columns.filter((value, index) => usedColumns.includes(value.field.toLowerCase()))]}
            initialState={{ pagination: { paginationModel: { pageSize: 100 } } }}
            pageSizeOptions={[100]}
            pagination={true}
            disableRowSelectionOnClick={true}
          />
        ) : null}
      </MDBox>

      <PreviewCardImagesModal
        show={showPreviewImagesModal}
        setShow={setShowPreviewImagesModal}
        images={[order.sceneFrontFull, order.sceneBackFull, order.envelopeFull]}
      />
    </MDBox>
  );
}

export default IntegrationOrderDetail;
