import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HelpOutline } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Table, TableBody, TableRow, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';

export default function HelpModal() {
  const [show, setShow] = useState<boolean>(false);
  const tableHeader = [
    'Salutation',
    'Last Name',
    'First Name',
    'To Organization',
    'Address 1',
    'Address 2',
    'City',
    'State',
    'Zip Code',
    'Custom ',
  ];

  const tableRows = [
    ['Johnny', 'John', 'Smith', '', '392 Alpine St.', 'APT 2233', 'New York', 'NY', '10045', ''],
    ['', '', '', 'Fight for the Cure', '425 Donation Way', '', 'Los Angeles', 'CA', '90210', ''],
  ];

  return (
    <>
      <MDButton color="secondary" onClick={() => setShow(true)}>
        <HelpOutline sx={{ mr: 1 }} />
        Help Guide
      </MDButton>
      <Dialog open={show} onClose={() => setShow(false)} maxWidth={'xl'} fullWidth>
        <DialogContent>
          <DialogTitle>Follow the tips below, so we can seamlessly upload your spreadsheet.</DialogTitle>
          <Box component={'ul'} ml={3}>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
              Enter recipients, their addresses and mail merge fields on the same row.
            </Typography>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
              Split addresses into five columns: Address 1, Address 2, City, State, Zip Code.
            </Typography>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
              If you're creating your own file, enter column headers (e.g. Salutation, First Name, Last Name, etc.) in
              the first row.
            </Typography>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
              Or{' '}
              <Link to={'/assets/LettrLabsUploadTemplate.csv'} target={'_blank'}>
                <Typography component={'span'} color="primary" fontSize={14} fontWeight={'normal'}>
                  download the template
                </Typography>
              </Link>{' '}
              to simplify the process.
            </Typography>
          </Box>
          <Typography variant="paragraphRegular" fontSize={16} mt={2} mb={1}>
            Table Example:
          </Typography>
          <Table border={1}>
            <TableRow sx={{ height: '2rem' }}>
              {tableHeader.map((header, index) => (
                <Typography component="th" key={index} sx={{ backgroundColor: '#C4C4C4' }} variant="paragraphRegular">
                  {header}
                </Typography>
              ))}
            </TableRow>
            <TableBody>
              {tableRows.map((row, index) => (
                <TableRow key={index}>
                  {row.map((cell, index) => (
                    <Typography component="td" key={index} variant="paragraphMedium" textAlign={'center'}>
                      {cell}
                    </Typography>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
