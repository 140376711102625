/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Fade from "@mui/material/Fade";

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";
import borders from "material-ui/theme/base/borders";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "material-ui/theme/functions/pxToRem";

const { tooltipColors, light } = colors;
const { borderRadius } = borders;

// types
type Types = any;

const tooltip: Types = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      backgroundColor: tooltipColors.main,
      color: light.main,
      textAlign: "center",
      borderRadius: borderRadius.md,
      opacity: 0.7,
      padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,

      "&.color-picker-tooltip": {
        backgroundColor: "white",
        boxShadow: "0px 5px 10px 0px #c3c3c3",
        padding: "10px",

        ".w-color-swatch": {
          gap: "10px",

          "div": {
            margin: "0 !important"
          }
        }
      }
    },

    arrow: {
      color: tooltipColors.main,
    },
  },
};

export default tooltip;
