import Dialog from "@mui/material/Dialog"
import {DialogContent, Grid} from "@mui/material"
import MDTypography from "material-ui/components/MDTypography"
import MDCard from "material-ui/components/MDCard";
import MDButton from "material-ui/components/MDButton";
import copy from "copy-to-clipboard";
import {useEffect, useState} from "react";
import MDBox from "material-ui/components/MDBox";

export interface CopyOrderModalProps {
    show: boolean
    setShow: Function
    apiKey: string
}

// Data
function ApiKeyModal({show, setShow, apiKey}: CopyOrderModalProps) {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    useEffect(() => {
        if(!show){
            setIsCopied(false)
        }
    }, [show]);

    function copyToClipboard(){
        copy(apiKey)
        setIsCopied(true)
    }

    return (
        <Dialog
            open={show}
            onClose={() => {
                setShow(false)
            }}
            maxWidth={false}
        >
            <DialogContent sx={{width: 1000}}>
                <MDTypography sx={{fontSize: 18}} color={"secondary"} fontWeight={"normal"}>Please copy and save your API key below. Do not give out this key to anyone.</MDTypography>
                <MDTypography sx={{fontSize: 18}} color={"secondary"} fontWeight={"normal"}>For security purposes your api key can only be seen once. After this screen is exited, you will not be able to retrieve it. Please store it somewhere safe.</MDTypography>

                <MDBox mt={2}>
                    <MDCard border={true} borderColor={"light"} borderRadiusSize={"xl"}>
                        <Grid container p={1} alignItems={"center"} justifyContent={"space-between"} gap={1}>
                            <Grid item>
                                <MDTypography variant={"paragraphRegular"} color={"secondary"} fontWeight={"normal"}>{apiKey}</MDTypography>
                            </Grid>
                            <Grid item>
                                <MDButton color={"primary"} onClick={copyToClipboard}>{isCopied? "Copied!" : "Copy to Clipboard"}</MDButton>
                            </Grid>
                        </Grid>
                    </MDCard>
                </MDBox>
            </DialogContent>
        </Dialog>
    )
}

export default ApiKeyModal