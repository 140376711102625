import { Box, CircularProgress, Typography } from "@mui/material";
import MDCard from "material-ui/components/MDCard";

const LoadingIndicator = () => {
    const indicatorsBox = ["Conversions", "No. of Campaigns", "Deliverability", "Total Mail", "Active Automations"]

    return (
        <>
            {
                indicatorsBox.map( val => (
                    <MDCard sx={{display: 'flex', justifyContent: 'center', padding: '2rem', minWidth: '240px', width: '240.8px', height: '107px', borderRadius: '16px !important' }}>
                        <Typography variant='paragraphMedium' >{val}</Typography>
                        <Box>
                            <CircularProgress size="15px" />
                        </Box>
                    </MDCard>
                ))
            }
        </>
    );
};

export default LoadingIndicator;
