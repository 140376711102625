import React from 'react';
import MDCard from 'material-ui/components/MDCard';
import Onboarding from './components/Onboarding';
import FunellChart from './components/FunellChart';
import SplashScreen from './components/SplashScreen';
import IndicatorCard from './components/IndicatorCard';
import LoadingIndicator from './components/IndicatorCard/loading';
import useShopifyDashboardService from 'newStandard/src/services/shopifyDashboard';

import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { getUserData, isOnboardingDone } from 'helpers/getUserInfos';
import { IDashboardProfileOverview } from 'newStandard/src/services/shopifyDashboard/types';
import { CircularProgress, Typography } from '@mui/material';
import './index.scss';
import { useLocation } from 'react-router-dom';

export default function ShopifyDashboard() {
  const location = useLocation();
  const { getOverview, getEnableDashboard } = useShopifyDashboardService();
  
  const enableQuery = useQuery({
    queryKey: ["getEnableDashboard"],
    queryFn: getEnableDashboard,
    refetchOnWindowFocus: false
  });

  const overviewQuery = useQuery({
    queryKey: ["dashboardOverviewData"],
    queryFn: getOverview,
    refetchOnWindowFocus: false,
    enabled: !!enableQuery.data?.payload
  });
 
  const isCheckoutSession = location.pathname.includes("checkout");

  if (!isOnboardingDone() && !isCheckoutSession) {
    return <Onboarding />
  }
  
  if (enableQuery.isLoading) {
    return <Box sx={{ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress size="35px" /></Box>;
  }

  if (!enableQuery.data?.payload && getUserData()?.id !== 0) {
    return <SplashScreen />;
  }

  return (
    <React.Fragment>
      {
        getUserData()?.id !== 0 && (
          <Box component={'section'} sx={{ padding: '40px', maxWidth: 1377, margin: '0 auto' }}>
            <Box>
              <Typography variant="h4" sx={{ margin: 0, padding: 0 }} data-testid="youroverview-title">
                Your overview
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', margin: '40px 0', gap: '16px', justifyContent: 'space-between'}}>
              {overviewQuery.isLoading ? (
                <LoadingIndicator />
              ) : (
                overviewQuery.data?.payload?.map((indicator: IDashboardProfileOverview) => (
                  <IndicatorCard key={indicator.title} data={indicator} />
                ))
              )}
            </Box>
            <Typography variant={'h4'} sx={{ marginBottom: '40px' }} data-testid="recentcampaigns-title">
              Recent campaigns
            </Typography>
            <MDCard sx={{ position: 'relative', borderRadius: '16px !important', minWidth: '863px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 2rem', height: '60px'}}>
                <Typography variant='h6' sx={{ margin: '5px 0 0 0', fontWeight: 500, padding: 0}} data-testid="campaignstatus-title">
                  Campaign Status
                </Typography>
                <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />
              </Box>
              <Box sx={{ height: '480px', padding: '80px 0', minWidth: '863px'}}>
                <FunellChart />
              </Box>
            </MDCard>
          </Box>
        )
      }
    </React.Fragment>
  );
}
