import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Coordinate } from 'models/coordinate';
import { mapContainerStyle } from 'helpers/google-map-helper';
import { getCenterOfPolygon } from "helpers/google-map-helper";
import { useAutomationContext } from 'pages/automations/context';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Dialog,DialogContent,Grid,Skeleton,Typography } from '@mui/material';
import { Circle, GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon } from "@react-google-maps/api";

import MDBox from 'material-ui/components/MDBox';
import colors from "material-ui/theme/base/colors";
import CloseIcon from 'assets/icons/close';
import HailTrace from './components/AutomationInfos/HailTrace';
import MDTypography from 'material-ui/components/MDTypography';
import NoImageFound from 'assets/images/no-picture.png';
import HailTraceTable from './components/HailTraceTable';
import DefaultTemplate from './components/AutomationInfos/DefaultTemplate';
import AutomationDetailTable from './components/Table';
import useAutomationsService from 'services/automations';
import TemplateScenePreview, { ITemplatePreviews } from 'components/template-scene-preview';

import './styles.scss';
import { ProductOptions } from 'newStandard/src/services/order/types';

const AutomationDetailModal: React.FC = () => {
    
    const queryClient = useQueryClient();
    
    const [center, setCenter] = useState<Coordinate>()
    const [showExpanded, setShowExpanded] = useState(false);
    const [templatePreviews, setTemplatePreviews] = useState<ITemplatePreviews>();
    const [hailTraceTableData, setHailTraceTableData] = useState([]);

    // const isBifold = template?.product === ProductOptions.HandwrittenBiFoldCard

    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }
    
    const { modalState, toggleModal, selectedAutomation, automationDetail, setAutomationDetail } = useAutomationContext();

    const { getAutomationByID } = useAutomationsService();

    const {
        data,
        isLoading
    } = useQuery({ 
        queryKey: ['getAutomationsById',selectedAutomation?.id, selectedAutomation?.integrationOrderType], 
        queryFn: () => getAutomationByID(selectedAutomation?.id ?? 0, selectedAutomation?.integrationOrderType ?? 0), 
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        enabled: Boolean(selectedAutomation?.id && selectedAutomation?.integrationOrderType)
    });

    const handleClose = () => {
        queryClient.invalidateQueries({ queryKey: ['getAutomationsById'] });
        toggleModal('isDetailModalOpen', false);
    }

    const getGeneralInfoComponentToShow = () => {
        switch (selectedAutomation?.integrationOrderType) {
            case 1 :
                return <DefaultTemplate isLoading={isLoading} />
            case 3:
                return <HailTrace isLoading={isLoading} />
            default:
                return <DefaultTemplate isLoading={isLoading} />
        }
    }

    const mapsIsVisible = () => {
        if(automationDetail?.hailTrace?.polygons &&  automationDetail?.hailTrace?.polygons.length > 0) {
            return true
        } else if (automationDetail?.hailTrace?.radii &&  automationDetail?.hailTrace?.radii.length > 0) {
            return true
        } else {
            return false
        }
    }

    const handleSeeFullScreen = () => {
        setShowExpanded(true);
        setTemplatePreviews({
            sceneFrontThumb: automationDetail?.thumbs?.sceneFrontFull,
            sceneBackThumb: automationDetail?.thumbs?.sceneBackFull,
            envelopeFull: automationDetail?.thumbs?.envelopeFull,
          });
    }

    useEffect(() => {
        if(modalState.isDetailModalOpen) {
          setAutomationDetail(data?.payload);

          if(data?.payload) {
            if (data.payload.hailTrace.radii.length) {
                let centerObject = data.payload.hailTrace.radii[0]
                setHailTraceTableData(data.payload.hailTrace.radii);
                setCenter(centerObject.coordinate)
            }
            else {
                if (data.payload.hailTrace.polygons.length) {
                    let centerObject = data.payload.hailTrace.polygons[0]
                    setHailTraceTableData(data.payload.hailTrace.polygons);
                    setCenter(getCenterOfPolygon(centerObject.coordinates))
                }
            }
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading])

    return (
        <Dialog maxWidth={'xl'} fullWidth open={modalState.isDetailModalOpen} onClose={handleClose}>
            <Box sx={{ display: 'flex', position: 'relative', padding: '2rem 2rem 0 2rem' }}>
                <Typography variant="h6" mb={2} pb={2} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
                    Detail
                </Typography>
                <CloseIcon sx={{ opacity: .6, position: 'absolute', right: 28}} onClick={handleClose} />
            </Box>
            <Box sx={{ padding: '.5rem 2rem 0 2rem', height: '800px' }}>
                <Box>
                    <Box sx={{ display: 'flex', gap: 4 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%'}}>
                            <Typography variant='h6' sx={{ marginTop: '.5rem'}}>General Information</Typography>
                            { getGeneralInfoComponentToShow() }
                        </Box>
                        <Box sx={{ 
                            width: '35%', 
                            height: '255px', 
                            margin: '.5rem 1rem 2 1rem', 
                            padding: '0 1rem 1rem 0', 
                            borderRight: '1px solid rgba(0,0,0,.08)',
                            borderLeft: '1px solid rgba(0,0,0,.08)'
                        }}
                        >
                            <Typography variant='h6' sx={{ padding: '0 0 1rem 1.5rem', minHeight: '37.5px'}}>Template</Typography>
                            {
                                isLoading && (
                                    <Skeleton width={'455px'} height={'355px'} sx={{ marginTop: '-80px', marginLeft: '20px'}} />
                                )
                            } 
                            { !isLoading && (automationDetail?.thumbs?.envelopeFull || automationDetail?.thumbs?.sceneBackFull || automationDetail?.thumbs?.sceneFrontFull)  ? (
                                    <Box 
                                    onClick={handleSeeFullScreen}
                                    sx={{ 
                                        position: 'relative', 
                                        width: '100%', 
                                        height: '100%', 
                                        marginTop: '1rem', 
                                        cursor: 'pointer',
                                        transition: '.3s ease-in-out',
                                        '&:hover': {
                                            '& .overlay': {
                                                opacity: 0.7,
                                            },
                                            '& .hoverMessage': {
                                                transition: '.3s ease-in-out',
                                                opacity: 1,
                                            },
                                        },
                                    }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginLeft: '1.5rem',
                                                marginTop: '-20px',
                                                marginBottom: '-10px',
                                                fontSize: '0.875rem',
                                            }}
                                        >   
                                            <Typography variant='paragraphRegular' sx={{ opacity: .8, color: 'black', minHeight: '38px' }}>{automationDetail?.template?.name ?? 'teste'}</Typography>

                                            <span style={{ color: '##344767', opacity: .8, marginTop: '-21px', marginBottom: '20px' }}>{automationDetail?.template?.type ?? 'teste'}</span>
                                        </Box>


                                        <Box sx={{ 
                                            position: 'relative',
                                            borderRadius: '10px',
                                            width: '250px',
                                            height: '177px',
                                            margin: automationDetail.template?.type === ProductOptions.HandwrittenBiFoldCard ? '3rem auto 0 80px' : '1rem auto 0 80px',
                                            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)', 
                                            zIndex: 3,
                                            overflow: "hidden",
                                        }}>
                                            <Box
                                                component="img"
                                                src={automationDetail?.thumbs?.sceneBackThumb}
                                                alt="Middle Image"
                                                sx={{
                                                    position: 'absolute',
                                                    top: automationDetail.template?.type === ProductOptions.HandwrittenBiFoldCard ? '-174px' : '2px',
                                                    left: '0px',
                                                    width: '100%'
                                                }}
                                            />
                                        </Box>
                                        
                                        <Box
                                            component="img"
                                            src={automationDetail?.thumbs?.sceneFrontFull}
                                            alt="Back Image"
                                            sx={{
                                                position: 'absolute',
                                                borderRadius: '10px',
                                                overflow: "hidden",
                                                top: automationDetail.template?.type === ProductOptions.HandwrittenBiFoldCard ? '25px' : '120px',
                                                right: '50px',
                                                width: automationDetail.template?.type === ProductOptions.HandwrittenBiFoldCard ? 180 : 220,
                                                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)', 
                                                zIndex: 2,
                                                transform: 'translateY(10px)',
                                            }}
                                        />
                                    </Box>
                                    
                                ) : !isLoading && (!automationDetail?.thumbs?.envelopeFull && !automationDetail?.thumbs?.sceneBackFull && !automationDetail?.thumbs?.sceneFrontFull) && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '255px'
                                        }}
                                    >
                                        <Box 
                                            component={'img'}
                                            src={NoImageFound}
                                            width={'200px'}
                                        />
                                        <b style={{ margin: '-10px', color: 'grey' }}>Template Not Found</b>
                                    </Box>
                                )
                            }
                        </Box>
                            <Box sx={{ 
                                width: '33%', 
                                height: '250px', 
                                visibility: mapsIsVisible() ? 'visible' : 'hidden'
                                }}>
                                <Typography variant='h6' sx={{ padding: '.5rem 0 2rem 1.5rem', marginLeft: '-1.5rem'}}>Map View</Typography>
                                <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0">
                                <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        options={mapOptions}
                                        center={center}
                                        zoom={9}
                                    >
                                        {automationDetail?.hailTrace?.radii.map((address: any, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={{
                                                        lat: address.coordinate.lat,
                                                        lng: address.coordinate.lng
                                                    }}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}
                                                    >
                                                        {address.name}
                                                    </Typography>
                                                </OverlayView>

                                                <Circle
                                                    center={{
                                                        lat: address.coordinate.lat,
                                                        lng: address.coordinate.lng
                                                    }}
                                                    radius={address.radius * 1609.34}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.30,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 0.8
                                                    }}
                                                />
                                            </MDBox>
                                        })}

                                        {automationDetail?.hailTrace?.polygons.map((polygon: any, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={getCenterOfPolygon(polygon.coordinates)}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}
                                                    >
                                                        {polygon.name}
                                                    </Typography>
                                                </OverlayView>

                                                <MapsPolygon
                                                    key={polygon.id}
                                                    draggable={false}
                                                    editable={false}
                                                    path={polygon.coordinates}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.40,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 1,
                                                        strokeWeight: 3
                                                    }}
                                                />
                                            </MDBox>
                                        })}
                                    </GoogleMap>
                                </LoadScript>
                            </Box>
                    </Box>
                    <Box sx={{ margin: '2rem 0 1rem 0'}}>
                        {
                            (selectedAutomation?.integrationOrderTypeName !== 'Hail Trace') && (
                                <AutomationDetailTable />
                            )
                        }

                        {
                            (selectedAutomation?.integrationOrderTypeName === 'Hail Trace') && (
                                <HailTraceTable data={hailTraceTableData} isLoading={isLoading} />
                            )
                        }
                    </Box>
                </Box>

                <Dialog open={showExpanded} fullWidth maxWidth={'xl'} onClose={() => setShowExpanded(false)}>
                    <DialogContent sx={{ paddingY: 0}}>
                        <Grid container sx={{ marginBottom: '2rem', height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                            <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                                Template Detail
                            </MDTypography>
                            <Box display={'flex'} gap={1}>
                                <CloseIcon onClick={() => setShowExpanded(false) }/>
                            </Box>
                        </Grid>
                        <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />

                        <TemplateScenePreview {...templatePreviews}  sx={{ margin: '2rem 0'}}/>
                    </DialogContent>
                </Dialog>
            </Box>
        </Dialog>
    );
}

export default AutomationDetailModal;