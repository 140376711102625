import React from 'react';

import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import { useSelfManagementPlanModal } from 'context/selfmanageplan-context';

import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import MDTypography from 'material-ui/components/MDTypography';
import DoNotMailIcon from 'assets/images/blocked-email.png';
import { useQueryClient } from '@tanstack/react-query';

const NotAuthorizedPage: React.FC = () => {
    const queryClient = useQueryClient();

    const { showModal, setTierToUpgrade } = useSelfManagementPlanModal();

    const handleOpenModal = () => {
        setTierToUpgrade('Premium Tier')
        
        setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ['checkoutData'] });

            showModal('upgrade');
        }, 500)
    }

    return (
        <MDCard sx={{ height: '88vh', margin: '.5rem', position: 'relative', overflowY: 'hidden' }}>
             <Grid px={4} container sx={{ height: '65px' }} alignItems={'center'} >
                <MDTypography sx={{ fontSize: 18, color: '#2C2D36' }} fontWeight={'bold'} data-testid="automation-title">
                    Do Not Mail
                </MDTypography>
            </Grid>
            <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '4rem', alignItems: 'center', height: '100%', gap: 4 }}>
                <Box sx={{ display: 'flex'}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '-1rem 0 0 0'}}>
                        <Typography 
                            variant='h1'
                            sx={{
                                fontWeight: '900 !important',
                                padding: '1rem 1rem 0 0',
                                background: 'white',
                                fontSize: '2.8rem !important',
                                color: 'black'
                            }}
                        >
                            Take Control with <strong style={{ color: '#F05A2A'}}>Do Not Mail</strong>,<br /> Never Send to Unwanted <br /> Recipients Again
                        </Typography>
                        <Typography variant='paragraphMedium' sx={{ background: 'rgba(255,255,255,1)', padding: '2rem 1rem 2rem 0'}}>
                            Empower your campaigns by ensuring targeted campaigns and <br /> respecting your customer's preferences.
                        </Typography>
                        <MDButton onClick={() => handleOpenModal()}>Unlock Do Not Mail</MDButton>
                    </Box>
                </Box>
                <Box>
                    <Box 
                        marginTop={'-3rem'}
                        width={'250px'}
                        component={'img'} 
                        src={DoNotMailIcon} 
                    />
                </Box>
            </Box>
        </MDCard>
    );
}

export default NotAuthorizedPage;