import React, { useState } from 'react';

import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Accordion, AccordionDetails, AccordionSummary } from './AccordionSettings';
import { Alert, Chip, CircularProgress, Dialog, DialogContent, List, ListItem, Typography } from '@mui/material';

import Wix from './components/Wix';
import Shopify from './components/Shopify';
import Webflow from './components/Webflow';
import MDInput from 'material-ui/components/MDInput';
import MDButton from 'material-ui/components/MDButton';
import Wordpress from './components/Wordpress';
import CloseIcon from 'assets/icons/close';
import CustomHTML from './components/CustomHTML';
import MDTypography from 'material-ui/components/MDTypography';
import TerminalCodeBlock from 'components/terminal-code-block';
import useDataSourcesService from 'services/data-sources';

interface IFinishStep {
    handleRefresh: () => void,
    data: any
}

const FinishStep: React.FC<IFinishStep> = ({ data, handleRefresh }) => {

    const { revokeDataSource, testLeadRevealConnection, skipTestLeadRevealConnection, finishLeadRevealSetup, shareStepByStep } = useDataSourcesService();

    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const [expanded, setExpanded] = useState<string | false>('');
    const [emailToShare, setEmailToShare] = useState<string>('');

    const [loading, setLoading] = useState(false);
    const [finishing, setFinishing] = useState(false);
    const [canFinish, setCanFinish] = useState(false);
    const [testSuccess, setTestSuccess] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [openRevokeConfirmationModal, setOpenRevokeConfirmationModal] = useState(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const { mutate: finishSetup } = useMutation({
        mutationFn: finishLeadRevealSetup,
        onMutate: () => setFinishing(true),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
            queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
            setFinishing(false);
            handleRefresh();
        },
    });

    const { mutate: testConnection, isPending: isTesting } = useMutation({
        mutationFn: testLeadRevealConnection,
        onSuccess: (result) => {
            if (result.payload) {
                setCanFinish(true);
                setTestSuccess(true);
            } else {
                enqueueSnackbar("The tracker was not correctly installed yet and couldn't be detected. If you need any help, please contact our live customer support.", { variant: 'error' });
                setTestSuccess(false);
            }
        },
    });

    const { mutate: skipTestConnection, isPending: isSkippingTesting } = useMutation({
        mutationFn: skipTestLeadRevealConnection,
        onSuccess: (result) => {
            if (result.payload) {
                setCanFinish(true);
                finishSetup();
                enqueueSnackbar("Test Script skipped successfully! Finishing Lead Reveal Configuration ...", { variant: 'success' });
            } else {
                enqueueSnackbar("Something went wrong. Please try again later.", { variant: 'error' });
            }
        },
    });

    const { mutate: handleShare } = useMutation({
        mutationFn: () => shareStepByStep(emailToShare),
        onSuccess: () => {
            setSendingEmail(false);
            enqueueSnackbar('The email was sent successfully!', { variant: 'success' });
        },
    });

    const { mutate: revoke } = useMutation({
        mutationFn: () => revokeDataSource('lead-reveal'),
        onMutate: () => setLoading(true),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
            queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
            setLoading(false);
            handleRefresh();
            setOpenRevokeConfirmationModal(false);
        },
    });


    const handleConfirm = () => { revoke() }

    const handleTest = () => {
        testConnection();
    }

    const isValidEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleFinish = () => {
        finishSetup();
    }

    return (
        <React.Fragment>
            <DialogContent sx={{ padding: '1rem 0 0 0', position: 'relative !important' }}>
                <Typography variant="h6" sx={{ margin: '1rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
                    Install script with HTML code
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, margin: '2rem 0 0 0', justifyContent: 'space-between' }}>
                    <MDInput
                        disabled
                        label="Your Website"
                        value={data?.WebsiteUrl}
                        sx={{ width: '66%' }}
                    />
                    <MDButton color='error' variant='primary' disabled={loading} onClick={() => setOpenRevokeConfirmationModal(true)} sx={{
                        '&:hover': {
                            backgroundColor: '#d32f2f !important',
                        },
                    }}>
                        {loading ? (<CircularProgress color="inherit" size={18} />) : ('Revoke')}
                    </MDButton>
                    <MDButton color='primary' variant='primary' disabled={!canFinish || finishing} onClick={handleFinish}>
                        {finishing ? <CircularProgress size={15} color='inherit' /> : 'Finish Setup'}
                    </MDButton>
                </Box>
                <Box sx={{ padding: '1rem 0 0 0' }}>
                    <Typography variant='paragraphMedium' sx={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem' }}><b  style={{ marginRight: '.25rem' }}>Step 1:</b>  Copy your Pixel code bellow and paste it into your site's <pre style={{ margin: '0 0 0 .5rem' }}>{'<head> '}</pre> tag.</Typography>
                    {/* Terminal Code Block Component interpret indentation, so we need keep it like this */}
                    <TerminalCodeBlock
                        iconColor='#f8f8f2'
                        sx={{ mt: .5, mb: 2, bgcolor: '#282c34', color: '#f8f8f2' }}
                        code={`<!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${data?.ProjectId}');</script>
    <!-- End Google Tag Manager -->`}
                    />
                </Box>
                <Typography sx={{ display: 'flex', alignItems: 'center' }} variant='paragraphMedium'><b style={{ marginRight: '.25rem' }}>Step 2:</b>  Copy the code bellow and paste it into your site's <pre style={{ margin: '0 0 0 .5rem' }}>{'<body> '}</pre> tag.</Typography>
                <TerminalCodeBlock
                    iconColor='#f8f8f2'
                    sx={{ mt: .5, mb: 2, bgcolor: '#282c34', color: '#f8f8f2' }}
                    code={`<!-- Google Tag Manager (noscript)-->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${data?.ProjectId}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager  (noscript)-->`}
                />
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ margin: '.5rem 0 .5rem 0' }}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant='paragraphMedium'>Here's how to do it</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ padding: '0 .5rem' }}>
                            <Webflow />
                            <Wordpress />
                            <Wix />
                            <CustomHTML />
                            <Shopify />
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Box sx={{ margin: '1.2rem 0 0 0', border: '1px solid rgba(0,0,0,.1)', padding: '1.2rem', borderRadius: '5px' }}>
                    <Typography variant='paragraphMedium'>
                        <b>Would you like to send these instructions to someone?</b> <br />Fill in the email below and click <b>"Send"</b> to share this step-by-step guide!
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2, padding: '1rem 0 0 0' }}>
                        <Box sx={{ width: '90%', position: 'relative' }}>
                            <MDInput
                                label="E-mail Address"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailToShare(e.target.value)}
                                value={emailToShare}
                                sx={{ width: '100%' }}
                            />
                            {
                                emailToShare !== '' && !isValidEmail(emailToShare) && (
                                    <Typography sx={{ color: 'red', position: 'absolute', left: 0, bottom: -18 }} variant='paragraphRegular'>Type a valid e-mail</Typography>
                                )
                            }
                        </Box>
                        <MDButton
                            data-testid="automation-delete-modal-send-email-btn"
                            onClick={() => { handleShare(); setSendingEmail(true); }}
                            sx={{
                                borderRadius: '5px !important',
                                '&:hover': {
                                    backgroundColor: '#d32f2f',
                                },
                            }}
                            disabled={sendingEmail || !emailToShare || !isValidEmail(emailToShare)}>
                            {sendingEmail ? <CircularProgress size={15} color='inherit' /> : 'Send'}
                        </MDButton>
                    </Box>
                </Box>

                <Typography sx={{ display: 'flex', alignItems: 'center', margin: '1rem 0 .5rem 0' }} variant='paragraphMedium'><b style={{ marginRight: '.25rem' }}>Step 3:</b> Test if your script is working</Typography>

                <MDButton sx={{ width: '140px' }} disabled={isTesting} variant='transparent' color='primary' onClick={() => handleTest()}>
                    {
                        isTesting ? (<CircularProgress color="inherit" size={18} />) : ('Test Script')
                    }
                </MDButton>

                <Alert
                    severity='info'
                    sx={{
                        width: '100%',
                        margin: '1.5rem 0 0 0',
                        borderRadius: '5px',
                        border: '1px solid rgba(0,0,0,.1)'
                    }}>
                        <Box>
                            You can skip the Google Tag Manager verification and start creating your automations right away!<br/> <b>Important:</b> It's essential to complete the setup as soon as possible to ensure Lead Reveal works correctly.
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <MDTypography fontWeight={"bold"} sx={{fontSize: "14px", marginTop: 1, color: 'black', overflow: 'hidden'}} isLink={true} linkHoverColor={"primary"} onClick={skipTestConnection}>
                                {
                                    isSkippingTesting ? (<>Please Waiting, Skipping Test <CircularProgress color="inherit" size={18} /></>) : ('Click here to Skip the Test and Get Started')
                                }
                            </MDTypography>
                        </Box>
                </Alert>

                <Box sx={{ margin: '2rem 0 0 0' }}>
                    {
                        testSuccess && (
                            <Chip
                                color='success'
                                label={<Box sx={{ padding: '0 1rem', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>{'Good News!!! The Google Tag Manager tag has been correctly configured on your site. Simply click "Finish Setup" to start creating automations with Lead Reveal.'}</Box>}
                                sx={{
                                    height: 'auto',
                                    borderRadius: '8px',
                                    background: '#d1fadf',
                                    color: 'green',
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    display: 'block'
                                }}
                            />
                        )
                    }
                </Box>
            </DialogContent>


            <Dialog open={openRevokeConfirmationModal} maxWidth='md' fullWidth onClose={() => setOpenRevokeConfirmationModal(false)}>
                <Box sx={{ padding: '1rem 2rem' }}>
                    <Box sx={{ display: 'flex', position: 'relative', padding: '2rem 2rem 0 2rem' }}>
                        <Typography data-testid="automation-delete-modal-title" variant="h6" mb={4} pb={4} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
                            Are you sure you want to revoke your setup?
                        </Typography>
                        <CloseIcon sx={{ opacity: .6, position: 'absolute', right: 28 }} onClick={() => setOpenRevokeConfirmationModal(false)} />
                    </Box>
                    <Box sx={{ padding: '0 2rem' }}>
                        <Typography variant='paragraphMedium' data-testid="automation-delete-modal-message">
                            Revoking your setup will permanently delete any old tags and discard any collected leads that were not yet processed.
                        </Typography>
                        <Typography variant='paragraphMedium' fontWeight={'500'} sx={{ margin: '1rem 0 0 0' }}>
                            To re-setup LeadReveal, please follow the following steps
                        </Typography>
                        <List>
                            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0' }}>
                                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%' }} />
                                <Typography variant='paragraphMedium' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><b style={{ display: 'flex', alignItems: 'center', gap: 4 }}>Step 1</b> Remove the old LeadReveal header and footer tags</Typography>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, margin: '1rem 0' }}>
                                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%', marginTop: '10px' }} />
                                <Typography variant='paragraphMedium' sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}><b style={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>Step 2</b> Follow the LeadReveal setup process again, adding new header and <br />footer tags to your site</Typography>
                            </ListItem>
                        </List>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', padding: '2rem' }}>
                        <MDButton
                            color='light'
                            data-testid="automation-delete-modal-cancel-btn"
                            onClick={() => setOpenRevokeConfirmationModal(false)}
                            sx={{ borderRadius: '5px !important' }}
                            disabled={loading}>
                            Cancel
                        </MDButton>
                        <MDButton
                            data-testid="automation-delete-modal-delete-btn"
                            onClick={handleConfirm}
                            sx={{
                                borderRadius: '5px !important',
                                '&:hover': {
                                    backgroundColor: '#d32f2f',
                                },
                            }}
                            disabled={loading}>
                            {loading ? <CircularProgress size={15} color='inherit' /> : 'Revoke Lead Reveal'}
                        </MDButton>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

export default FinishStep;