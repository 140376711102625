import React, { useState } from 'react';

import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { CircularProgress, Dialog, Typography } from '@mui/material';
import { useAutomationContext } from 'pages/automations/context';

import MDButton from 'material-ui/components/MDButton';
import CloseIcon from 'assets/icons/close';
import useAutomationsService from 'services/automations';

const DeleteAutomationModal: React.FC = () => {
    
    const { modalState, toggleModal, selectedAutomation, handleupdateListAfterDelete } = useAutomationContext();

    const { enqueueSnackbar } = useSnackbar();
    const { deleteAutomation } = useAutomationsService();

    const [loading, setLoading] = useState(false);

    const mutation = useMutation({
        mutationFn: () => deleteAutomation(selectedAutomation?.id ?? 0, selectedAutomation?.integrationOrderType ?? 0),
        onMutate: () => setLoading(true),
        onSuccess: () => {
          setLoading(false);
          toggleModal('isDeleteModalOpen', false);
          enqueueSnackbar('Automation successfully deleted.', { variant: 'success' } );
          handleupdateListAfterDelete(selectedAutomation?.id ?? 0)
        },
        onError: (err) => {
          setLoading(false);
        },
    });

    const handleConfirm = async() => {
        mutation.mutate();
    }

    return (
        <Dialog maxWidth={'sm'} fullWidth open={modalState.isDeleteModalOpen} onClose={() => toggleModal('isDeleteModalOpen', false)}>
            <Box sx={{ display: 'flex', position: 'relative', padding: '2rem 2rem 0 2rem' }}>
                <Typography data-testid="automation-delete-modal-title" variant="h6" mb={2} pb={2} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                    Delete
                </Typography>
                <CloseIcon sx={{ opacity: .6, position: 'absolute', right: 28}} onClick={() => toggleModal('isDeleteModalOpen', false)} />
            </Box>
            <Box sx={{ padding: '0 2rem'}}>
                <Typography variant='paragraphMedium' data-testid="automation-delete-modal-message">
                    Are you sure you want to proceed with deleting this automation? This action cannot be undone.
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', padding: '2rem'}}>
                <MDButton 
                    color='light'
                    data-testid="automation-delete-modal-cancel-btn"
                    onClick={() => toggleModal('isDeleteModalOpen', false)} 
                    sx={{ borderRadius: '5px !important' }} 
                    disabled={loading}>
                        Cancel
                </MDButton>
                <MDButton 
                    data-testid="automation-delete-modal-delete-btn"
                    onClick={handleConfirm} 
                    sx={{ borderRadius: '5px !important' }} 
                    disabled={loading}>
                    { loading ? <CircularProgress size={15} color='inherit' /> : 'Delete'}
                </MDButton>
            </Box>
        </Dialog>
    );
}

export default DeleteAutomationModal;