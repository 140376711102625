import React from 'react';

import { Box } from '@mui/system';
import { IOnboardingData } from '../..';
import { CircularProgress, Typography } from '@mui/material';

import Logo from 'assets/images/logo-blue.png'
import MDRadio from 'material-ui/components/MDRadio';
import MDButton from 'material-ui/components/MDButton';

interface IStepTwo {
  formData: IOnboardingData,
  isSaving: boolean,
  setFormData: (form: IOnboardingData) => void,
  handleBack: () => void,
  handleSave: () => void
}
const StepTwo: React.FC<IStepTwo> = ({ formData, setFormData, handleSave, handleBack, isSaving }) => {

  const industries = [
    "Personal Use",
    "Real Estate",
    "E-Commerce",
    "Home Services",
    "Marketing",
    "Tech",
    "Politics",
    "Non-Profit",
    "Insurance",
    "Healthcare",
    "Professional Services",
    "Other",
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: 520, height: '810px' }}>
      <Box component={'img'} src={Logo} alt='Logo' sx={{ width: 180, height: 47, marginBottom: '40px' }}/>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#5551FF', background: 'white', width: 100, height: 22, borderRadius: 16 }}>Step 2 of 2</Box>
      <Typography variant='h4' color={'#5551FF'} my={2}>What will you use LettrLabs for?</Typography>
      <Typography variant='paragraphRegular'>Choose your industry to customize your experience, ensuring the best strategies and settings for achieving your campaign goals.</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginY: '40px'}}>
      {industries.map((ind: string, index: number) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', margin: '.10rem 0' }}>
            <MDRadio value={ind} 
            checked={formData?.industry === ind} 
            onChange={() => setFormData({ ...formData, industry: ind})}/>
            <Typography>{ind}</Typography>
          </Box>
        )
      })}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <MDButton variant='transparent' onClick={handleBack}>Back</MDButton>
        <MDButton disabled={!formData.industry || isSaving} onClick={handleSave}>
          { isSaving ? <CircularProgress size={18} color='inherit' /> : 'Save'}
        </MDButton>
      </Box>
    </Box>
  );
}

export default StepTwo;