export enum ShopifyDashboardChart {
  LINE_CHART = '1',
  SECOND_LINE_CHART = '2',
  HEATMAP = '3',
}

export enum ChartPeriodFilter {
  LAST_7_DAYS = '1',
  LAST_4_WEEKS = '2',
  LAST_3_MONTHS = '3',
  LAST_12_MONTHS = '4',
  ALL_TIME = '5',
  CUSTOM_RANGE = '6',
}

export enum ChartTimeUnit {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export type ChartType = 'line-chart' | 'heatmap' | 'funnel' | 'timeline';

export interface IShopifyDashboardConfiguration {
  contentOrder: ShopifyDashboardChart[];
  periodFilter: ChartPeriodFilter;
  customRangeStart?: string;
  customRangeEnd?: string;
  timeUnit: ChartTimeUnit;
  updatedAt: string;
}

export interface IDashboardProfileOverview {
  title: string;
  value: string;
  ordering: number;
}

export interface IDashboardRecentCampaign {
  title: string;
  value: number;
  subValue: string;
  ordering: number;
  colors: string[];
}

export interface IDashboardResultCampaign {
  title: string;
  type: string;
  cost: number;
  revenue: number;
  roi: number;
  progress: number;
  barChartTotal: number;
  barChartMailed: number;
  barChartDelivered: number;
}

export type LineChartDataType = (string | number)[][];
export type HeatmapChartDataType = number[][];
export type FunnelChartDataType = number[][];
export type TimelineChartDataType = { label: string; start: string; end: string }[];

export interface IChart<T> {
  data: T;
  xAxis: string[];
  yAxis: string[];
  metricOptions: string[];
}
