import { KeyboardEvent, useState } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, Form, FormikProps } from 'formik';
import {
  Accordion,
  AccordionDetails,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ILeadRevealSourcePayload } from 'services/data-sources/data-sources.types';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import MonthlyLeadsField from '../monthly-leads-field';
import AccordionTitle from '../accordion-title';

interface LeadRevealManageFormProps extends FormikProps<ILeadRevealSourcePayload> {
  onClose: () => void;
}

export default function LeadRevealManageForm({
  values,
  initialValues,
  onClose,
  getFieldMeta,
}: LeadRevealManageFormProps) {
  const [expanded, setExpanded] = useState<string>('');
  const [border, setBorder] = useState<boolean>(false);
  const [newAntiPixelPage, setNewAntiPixelPage] = useState<string>('');
  const antiPixelPagesMeta = getFieldMeta('antiPixelPages');
  const antiPixelPagesError = !!(antiPixelPagesMeta.touched && antiPixelPagesMeta.error);

  const handleAntiPixelPageInputKeyDown = (
    event: KeyboardEvent<HTMLDivElement>,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      const page = newAntiPixelPage.trim();
      if (page && !values.antiPixelPages?.includes(page)) {
        arrayHelpers.push(page);
        setNewAntiPixelPage('');
      }
    }
  };

  return (
    <Form>
      <Grid container mb={1}>
        {!values.useServiceAccount && (
          <Grid item xs>
            <Typography fontSize={14} fontWeight={'bold'}>
              Account:{' '}
              <Typography component={'span'} fontSize={14}>
                {initialValues.accountName}
              </Typography>
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography fontSize={14} fontWeight={'bold'}>
            Website Url:{' '}
            <Typography component={'span'} fontSize={14}>
              {initialValues.websiteUrl}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography fontSize={14} fontWeight={'bold'}>
            Project Id:{' '}
            <Typography component={'span'} fontSize={14}>
              {initialValues.projectId}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <MonthlyLeadsField />

      <Typography variant="paragraphRegular" mt={2} mb={0.5}>
        Advanced Options
      </Typography>
      <Accordion
        elevation={0}
        disableGutters
        expanded={expanded === 'AntiPixel' || !!antiPixelPagesMeta.error}
        onChange={() => setExpanded(expanded === 'AntiPixel' ? '' : 'AntiPixel')}
        sx={{ border: '1px solid #344767' }}
      >
        <AccordionTitle checked={!!(values.createAntiPixel && values.antiPixelPages?.length)}>
          Anti-Pixel: Suppress LeadReveal when user goes to specific page
        </AccordionTitle>
        <AccordionDetails>
          <Field name="createAntiPixel">
            {({ field }: FieldProps) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="Supress LeadReveal when the website address contains one of the pages bellow"
              />
            )}
          </Field>

          <FieldArray
            name={'antiPixelPages'}
            render={(arrayHelpers) => (
              <MDCard
                color="light"
                borderRadiusSize={'xl'}
                sx={{
                  border: `${antiPixelPagesError ? '1' : '2'}px solid ${
                    antiPixelPagesError || border ? '#F05A2A' : 'transparent'
                  }`,
                }}
              >
                <Box mx={1} pt={'2px'} display="flex" alignItems="center" flexWrap="wrap">
                  {values?.antiPixelPages?.map((page, index) => (
                    <Chip
                      key={index}
                      label={page}
                      disabled={!values.createAntiPixel}
                      onDelete={() => arrayHelpers.remove(index)}
                      sx={{ m: '2px' }}
                    />
                  ))}
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Add Page"
                    value={newAntiPixelPage}
                    onFocus={() => setBorder(true)}
                    onBlur={() => setBorder(false)}
                    onChange={(evt) => setNewAntiPixelPage(evt.target.value)}
                    onKeyDown={(evt) => handleAntiPixelPageInputKeyDown(evt, arrayHelpers)}
                    sx={{ minWidth: 100, flex: 1, '& fieldset': { border: 'none' } }}
                    disabled={!values.createAntiPixel}
                  />
                </Box>
              </MDCard>
            )}
          />
          <Typography variant='paragraphRegular' color={antiPixelPagesError ? 'error' : undefined}>
            {antiPixelPagesError && antiPixelPagesMeta.error} Press "Enter" or "Space" to insert the new page.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box display={'flex'} gap={1} mt={1}>
        <MDButton fullWidth type="submit">
          Update Automation
        </MDButton>
        <MDButton color="light" onClick={() => onClose()}>
          Cancel
        </MDButton>
      </Box>
    </Form>
  );
}
