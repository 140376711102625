import {
  ChartPeriodFilter,
  ChartTimeUnit,
  ChartType,
  IDashboardProfileOverview,
  IShopifyDashboardConfiguration,
  ShopifyDashboardChart,
} from './types';

export const mockShopifyDashboardConfiguration: IShopifyDashboardConfiguration = {
  contentOrder: [
    ShopifyDashboardChart.LINE_CHART,
    ShopifyDashboardChart.SECOND_LINE_CHART,
    ShopifyDashboardChart.HEATMAP,
  ],
  periodFilter: ChartPeriodFilter.ALL_TIME,
  timeUnit: ChartTimeUnit.DAILY,
  updatedAt: 'Tue Nov 26 2024 22:30:25 GMT-0300 (Brasilia Standard Time)',
};

const mockLineChartData = [
  ['Date', 'Interactions'],
  ['Aug 6', 0],
  ['Aug 13', 0],
  ['Aug 20', 1000],
  ['Aug 27', 0],
  ['Sep 3', 0],
  ['Sep 10', 15000],
  ['Sep 17', 0],
  ['Sep 24', 0],
  ['Oct 1', 3000],
  ['Oct 8', 0],
  ['Oct 15', 0],
  ['Oct 22', 5000],
  ['Oct 29', 0],
  ['Nov 5', 231300],
  ['Nov 12', 0],
  ['Nov 14', 100000],
];

const mockHeatmapChartData = [
  // Product 1
  [0, 0, 10], // [product (y), period range (x), value (cell)]
  [0, 1, 20],
  [0, 2, 30],
  [0, 3, 40],
  [0, 4, 50],
  [0, 5, 60],
  [0, 6, 70],
  [0, 7, 80],
  [0, 8, 90],
  // Product 2
  [1, 0, 20],
  [1, 1, 30],
  [1, 2, 40],
  [1, 3, 50],
  [1, 4, 60],
  [1, 5, 70],
  [1, 6, 80],
  [1, 7, 90],
  [1, 8, 70],
  // Product 3
  [2, 0, 30],
  [2, 1, 40],
  [2, 2, 50],
  [2, 3, 60],
  [2, 4, 70],
  [2, 5, 80],
  [2, 6, 90],
  [2, 7, 70],
  [2, 8, 50],
  // Product 4
  [3, 0, 40],
  [3, 1, 50],
  [3, 2, 60],
  [3, 3, 70],
  [3, 4, 80],
  [3, 5, 90],
  [3, 6, 70],
  [3, 7, 50],
  [3, 8, 30],
  // Product 5
  [4, 0, 50],
  [4, 1, 60],
  [4, 2, 70],
  [4, 3, 80],
  [4, 4, 90],
  [4, 5, 70],
  [4, 6, 50],
  [4, 7, 30],
  [4, 8, 20],
  // Product 6
  [5, 0, 60],
  [5, 1, 70],
  [5, 2, 80],
  [5, 3, 90],
  [5, 4, 70],
  [5, 5, 50],
  [5, 6, 30],
  [5, 7, 20],
  [5, 8, 10],
];

const mockFunnelChartData = [
  [2582],
  [1584],
  [1200],
  [729],
  [531],
];

const mockTimelineChartData = [
  { label: 'Test 1', start: '2024-09-01', end: '2024-10-01' },
  { label: 'Test 2', start: '2024-09-15', end: '2024-11-01' },
  { label: 'Test 3', start: '2024-11-01', end: '2024-12-15' },
  { label: 'Test 4', start: '2024-12-01', end: '2024-12-31' },
  { label: 'Test 5', start: '2024-12-15', end: '2025-01-15' },
  { label: 'Test 6', start: '2024-11-15', end: '2025-02-15' },
];

const chartMockOptions = {
  'line-chart': mockLineChartData,
  heatmap: mockHeatmapChartData,
  funnel: mockFunnelChartData,
  timeline: mockTimelineChartData,
};

const xAxisMockOptions: { [key: string]: string[] } = {
  'line-chart': ['Interactions', 'Cost'],
  heatmap: ['90-81', '80-71', '70-61', '60-51', '50-41', '40-31', '30-21', '20-11', '10-0'],
  funnel: ['In Production', 'Mailed', 'In Transit', 'Local Facility', 'Delivered'],
};

const yAxisMockOptions: { [key: string]: string[] } = {
  'line-chart': ['Interactions', 'Cost'],
  heatmap: ['HWFlat', 'HWBifold', 'PP4x6', 'PP6x9', 'PPx6x11', 'HWPP'],
  funnel: ['Campaign 1', 'Campaign 2', 'Campaign 3', 'Campaign 4', 'Campaign 5'],
};

export const mockChart = (chart: ChartType) => ({
  data: chartMockOptions[chart],
  xAxis: xAxisMockOptions[chart] ?? [],
  yAxis: yAxisMockOptions[chart] ?? [],
  metricOptions: ['Cost', 'Conversions', 'ROI', 'Interactions', 'AOV', 'Leads', 'CPI'],
});


export const overviewData: IDashboardProfileOverview[] = [
  {
    title: 'Conversions',
    value: '$29,300',
    ordering: 0
  },
  {
    title: 'Active Orders',
    value: '32',
    ordering: 1
  },
  {
    title: 'Active Automations',
    value: '29',
    ordering: 2
  },
  {
    title: 'Deliverability',
    value: '99%',
    ordering: 3
  },
  {
    title: 'Total Mail',
    value: '24,303',
    ordering: 4
  },
]