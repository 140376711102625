import { useState } from 'react';

import {
  IconButton,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Link,
  Paper,
  TextField,
  Dialog,
  CircularProgress,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DeleteOutline } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

import CopyToClipboardButton from 'newStandard/src/components/CopyToClipboardButton';
import StyledDataGrid from 'components/styled-data-grid';
import MDButton from 'material-ui/components/MDButton';
import ApiKeyService from 'services/api-key-service';

interface OpenApiModalProps {
  open: boolean;
  onClose: () => void;
}

export default function OpenApiModal({ open, onClose }: OpenApiModalProps) {
  const queryClient = useQueryClient();
  const { getApiKeys, createApiKey, deleteApiKey } = ApiKeyService();

  const apiKeyType = { value: 2, description: 2 };

  const [newApiKey, setNewApiKey] = useState<string>('');
  const [apiKeyName, setApiKeyName] = useState<string>('');
  const [apiKeyToDelete, setApiKeyToDelete] = useState<number | null>(null);

  const { data: apiKeys = [], isLoading, isRefetching } = useQuery({ 
    queryKey: ["openAPIKeys", apiKeyType.value],
    queryFn: () => getApiKeys(apiKeyType.value)
  });

  const { mutate: generateKey, isPending: isGenerating } = useMutation({
    mutationFn: () => createApiKey(apiKeyName, 2),
    onSuccess: (data) => {
      setApiKeyName('');
      setNewApiKey(data.key);
      queryClient.invalidateQueries({ queryKey: ['openAPIKeys'] });
    },
  });

  const { mutate: deleteKey, isPending: isDeleting } = useMutation({
    mutationFn: (apiKey: number) => deleteApiKey(apiKey),
    onSuccess: () => {
      setApiKeyToDelete(null);
      queryClient.invalidateQueries({ queryKey: ['openAPIKeys'] });
    },
  });

  const columns: GridColDef[] = [
    { headerName: '#', field: 'id', width: 150, valueFormatter: (params) => `Api Key #${params.value}` },
    { headerName: 'Key Name', field: 'description', flex: 1 },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      width: 200,
      valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy hh:mm a'),
    },
    {
      type: 'actions',
      field: 'actions',
      align: 'right',
      getActions: (params) => [
        <IconButton onClick={() => setApiKeyToDelete(params.row.id)}>
          <DeleteOutline color={'error'} />
        </IconButton>,
      ],
    },
  ];

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogContent>
          <Typography variant={'h6'}>Open API Setup Guide</Typography>
          <Box component={'ol'} ml={2} mb={2}>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'} mt={1}>
              Start by creating an API key below. This key will allow you to connect with the LettrLabs platform and
              integrate it with your own systems or applications.
            </Typography>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'} mt={1}>
              To create an API key:
              <Box component={'ul'} ml={3}>
                <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
                  Enter a name for your API key in the field below.
                </Typography>
                <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
                  Click "Add New." A popup will appear with a long string of letters and numbers.
                </Typography>
              </Box>
            </Typography>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'} mt={1}>
              Copy the API key. Important: The API key will only appear once (due to security policies), so make sure to
              save it securely. If needed, you can create additional API keys later.
            </Typography>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'} mt={1}>
              Once you have your API key, you can start using it to interact with the LettrLabs Open API. Explore the
              full API documentation here:{' '}
              <Link href="https://app.lettrlabs.com/api/swagger/ui#/" color={'Highlight'}>
                Open API Documentation.
              </Link>
            </Typography>
            <Typography component={'li'} fontSize={14} fontWeight={'normal'} mt={1}>
              For users who need guidance on using LettrLabs with Zapier,{' '}
              <Link href="/integration-orders/setup" color={'Highlight'}>
                refer to this documentation.
              </Link>
            </Typography>
          </Box>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant={'h6'}>API Keys</Typography>
            <Box display={'flex'} alignItems={'flex-start'} my={2} gap={2}>
              <TextField
                fullWidth
                size="small"
                value={apiKeyName}
                label={'Key Name *'}
                disabled={isGenerating}
                onChange={(e) => setApiKeyName(e.target.value)}
                helperText="Please name your API Key, this will help you identify it later"
              />
              <MDButton sx={{ width: 150 }} disabled={!apiKeyName || isGenerating} onClick={() => generateKey()}>
                {isGenerating ? <CircularProgress size={18} color="inherit" /> : 'Add New'}
              </MDButton>
            </Box>
            <StyledDataGrid columns={columns} rows={apiKeys} loading={isLoading || isRefetching} />
          </Paper>
        </DialogContent>
      </Dialog>

      <Dialog open={!!newApiKey} maxWidth="md" onClose={() => setNewApiKey('')}>
        <DialogContent>
          <DialogTitle sx={{ mb: 1 }}>Your API Key is ready!</DialogTitle>
          <Typography fontSize={16} fontWeight={'bold'}>
            For security purposes your key can only be seen once! Make sure to store it safely.
          </Typography>
          <Typography fontSize={16} mb={1}>
            If lost, you'll need to generate a new one.
          </Typography>
          <Paper variant="outlined" sx={{ backgroundColor: '#F3F5FB' }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" pl={2}>
              <Typography variant={'paragraphRegular'} fontSize={18} fontWeight={'normal'}>
                {newApiKey}
              </Typography>
              <CopyToClipboardButton text={newApiKey} />
            </Box>
          </Paper>
        </DialogContent>
      </Dialog>

      <Dialog open={!!apiKeyToDelete} onClose={() => setApiKeyToDelete(null)}>
        <DialogContent>
          <DialogTitle sx={{ mb: 2 }}>Are you sure you want to delete your API key?</DialogTitle>
          <Box display="flex" gap={1} justifyContent="flex-end">
            <MDButton fullWidth color="light" disabled={isDeleting} onClick={() => setApiKeyToDelete(null)}>
              Cancel
            </MDButton>
            <MDButton fullWidth disabled={isDeleting} onClick={() => apiKeyToDelete && deleteKey(apiKeyToDelete)}>
              {isDeleting ? <CircularProgress size={18} color="inherit" /> : "I'm sure"}
            </MDButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
