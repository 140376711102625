import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { visuallyHidden } from '@mui/utils';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Box, Skeleton, TableContainer, TablePagination, TableSortLabel } from '@mui/material';

type SortDirection = 'asc' | 'desc';


export interface Column<T extends object> {
    key: string; 
    label: string;
    sortable?: boolean;
    render?: (item: T) => React.ReactNode;
    width?: string;
}

interface TableProps<T extends object> {
    columns: Column<T>[];
    data: T[];
    isLoading?: boolean;
    totalItems?: number;
    serverSide?: boolean;
    page?: number;
    pageSize?: number;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    onSort?: (key: string, direction: SortDirection) => void;
    defaultSort?: { key: string; direction: SortDirection };
}

export const createColumn = <T extends object>(config: Column<T>): Column<T> => config;

const GenericTable = <T extends object>({
    columns,
    data,
    isLoading = false,
    totalItems,
    serverSide = false,
    page = 0,
    pageSize = 10,
    onPageChange,
    onPageSizeChange,
    onSort,
    defaultSort
}: TableProps<T>) => {
    const [localPage, setLocalPage] = useState(0);
    const [localPageSize, setLocalPageSize] = useState(pageSize);
    const [sortConfig, setSortConfig] = useState(defaultSort || { key: '', direction: 'asc' as SortDirection });

    const sortData = (items: T[]): T[] => {
        if (!sortConfig.key) return items;

        return [...items].sort((a, b) => {
            const aValue = (a as any)[sortConfig.key];
            const bValue = (b as any)[sortConfig.key];

            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    // Handle sort
    const handleSort = (key: string) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });

        if (serverSide && onSort) {
            onSort(key, direction);
        }
    };

    const handlePageChange = (_: unknown, newPage: number) => {
        if (serverSide && onPageChange) {
            onPageChange(newPage);
        } else {
            setLocalPage(newPage);
        }
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (serverSide && onPageSizeChange) {
            onPageSizeChange(parseInt(event.target.value, 10));
        } else {
            setLocalPageSize(parseInt(event.target.value, 10));
            setLocalPage(0);
        }
    };

    const currentPage = serverSide ? page : localPage;
    const currentPageSize = serverSide ? pageSize : localPageSize;
    const currentData = serverSide
        ? data
        : sortData(data).slice(currentPage * currentPageSize, (currentPage + 1) * currentPageSize);

    return (
        <Box sx={{ width: '100%', mt: 4, borderRadius: '5px' }}>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.key}
                                    sx={{ whiteSpace: 'nowrap', fontSize: '11px', width: `${column.width || 'auto'}`,  borderBottom: 'none'}}
                                    align={'left'}
                                    className={`${column.sortable ? 'cursor-pointer' : ''}`}
                                    onClick={() => column.sortable && handleSort(column.key)}
                                >

                                    <TableSortLabel
                                        sx={{ fontWeight: 'bold', color: 'black'}}
                                        active={sortConfig.key === column.key}
                                        direction={sortConfig.key === column.key ? sortConfig.direction : 'asc'}
                                    >
                                        {column.label}
                                        {sortConfig.key === column.key ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {sortConfig.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoading && currentData.map((item, index) => (
                            <TableRow key={index} hover tabIndex={-1} sx={{ cursor: 'pointer', borderBottom: 'none' }}>
                                {columns.map((column, index) => (
                                    <TableCell key={column.key} align="left" sx={{ fontSize: '12px', color: '#464646', borderTop: '1px solid #F6F8FA' }}>
                                        {column.render ? column.render(item) : (item as any)[column.key]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}

                        {isLoading && (
                            <React.Fragment>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {Array(8).fill(null).map((_, idx) => (
                                            <TableCell key={idx} sx={{ width: idx === 0 ? '50px' : '400px' }}>
                                                <Skeleton height={35} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={currentPageSize}
                page={currentPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                sx={{
                    margin: '2rem 0 0 0',
                    '.MuiTablePagination-toolbar': {
                        fontSize: '0.875rem',
                    },
                    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                        fontSize: '0.75rem',
                    },
                    '.MuiTablePagination-select': {
                        fontSize: '0.75rem',
                    },
                }}
            />
        </Box>
    )
};

export default GenericTable;