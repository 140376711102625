import Box from '@mui/material/Box';
import "./index.scss";
type BadgeStatusProps = {
  status: string;
  mailed: number;
  inTransit: number;
  atLocalFacility: number;
  delivered: number;
};


const StatusStepperV2 = ({ status, mailed, inTransit, atLocalFacility, delivered }: BadgeStatusProps) => {

  const getValueBar = () => {
    if(status === "Draft")
      return 0
    const calc = (mailed + inTransit + atLocalFacility + delivered) / 400
    return calc * 100
  }
  
  return (
    <Box sx={{ width: '100%' }}>
      <div className="stepper-box">
        <div className='description'>
          <div className='column'>
            {status}
          </div>
        </div>
        <div className='bullet-v2'>
          <div className='pct' style={{ width: `${getValueBar()}%` }}>
            {status !== 'Draft' && (<div className='icon-pct'></div>)}
          </div>
        </div>
      </div>
    </Box>
  );

};

export default StatusStepperV2;
