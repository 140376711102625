/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";
import borders from "material-ui/theme/base/borders";

// // Material Dashboard 2 PRO React TSUI Dashboard PRO helper functions
import pxToRem from "material-ui/theme/functions/pxToRem";

const { primary, grey, transparent, secondary } = colors;
const { borderRadius } = borders;

// types
type Types = any;

const inputOutlined: Types = {
  styleOverrides: {
    root: {
      backgroundColor: "#F3F5FB",
      borderRadius: borderRadius.md,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: primary.main,
        },
      },

      "&.Mui-disabled": {
        backgroundColor: grey[200],
        color: grey[500],
        pointerEvents: "none",
        cursor: 'not-allowed !important',
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
    },

    notchedOutline: {
      borderColor: "transparent",
    },

    input: {
      color: secondary.main,
      padding: pxToRem(12),
      paddingLeft: pxToRem(16),
      backgroundColor: transparent.main,

      "&::placeholder": {
        color: secondary.main,
        opacity: 1,
      },

      "&.Mui-disabled": {
        color: grey[500],
        cursor: 'not-allowed'
      },
    },

    inputSizeSmall: {
      padding: pxToRem(10),
    },

    multiline: {
      color: grey[700],
      padding: 0,
    },

    adornedStart: {
      input: {
        paddingLeft: 0,
      },
    },
  },
};

export default inputOutlined;
