import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import moment from "moment";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ProfileService from 'services/profile';

import { useQuery } from '@tanstack/react-query';
import { useGlobal } from 'context/global-context';
import { formatPrice } from 'helpers/formatters';
import { visuallyHidden } from '@mui/utils';
import { Skeleton, Typography } from '@mui/material';

interface Data {
  date: string;
  orderNumber: number;
  productType: string;
  orderStatus: string;
  orderQuantity: number;
  lineItemTotal: number;
  doubleSidedPrinting: number;
  extraText: number;
  postageUpgrade: number;
  totalCostPerPiece: number;
  discount: number;
}

interface DisplayData extends Data {
  Amount: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: keyof DisplayData | 'Amount';
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    label: 'Date',
  },
  {
    id: 'orderNumber',
    numeric: false,
    label: 'Order Number',
  },
  {
    id: 'productType',
    numeric: false,
    label: 'Product Type',
  },
  {
    id: 'orderStatus',
    numeric: false,
    label: 'Order Status',
  },
  {
    id: 'orderQuantity',
    numeric: true,
    label: 'Order Quantity',
  },
  {
    id: 'lineItemTotal',
    numeric: true,
    label: 'Line Item Total',
  },
  {
    id: 'doubleSidedPrinting',
    numeric: true,
    label: 'Double Sided Printing',
  },
  {
    id: 'extraText',
    numeric: true,
    label: 'Extra Text',
  },
  {
    id: 'postageUpgrade',
    numeric: true,
    label: 'Postage Upgrade',
  },
  {
    id: 'totalCostPerPiece',
    numeric: true,
    label: 'Total Cost Per Piece',
  },
  {
    id: 'discount',
    numeric: true,
    label: 'Discount',
  },
  {
    id: 'Amount',
    numeric: true,
    label: 'Amount',
  },
];

interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DisplayData) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DisplayData | 'Amount') => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({setDataToExport}: any) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof DisplayData | 'Amount'>('date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { showLoader } = useGlobal();

  const { getOrderSummary} = ProfileService()

  const { data, isLoading }  = useQuery({
    queryKey: ["orderSummary"],
    queryFn: () => {
        return getOrderSummary()
    },
    refetchOnWindowFocus: false
})

  const rows = data?.payload ?? [];

  React.useEffect(() => {
    if (data?.payload && data?.payload.length > 0) {
      let temp = [...rows]
        .sort(getComparator(order, orderBy));
        
      let finalData = temp.map((row: any) => ({
        ...row,
        date: moment(row.date).format('YYYY-MM-DD'),
        lineItemTotal: formatPrice(row.lineItemTotal),
        doubleSidedPrinting: formatPrice(row.doubleSidedPrinting),
        extraText: formatPrice(row.extraText),
        postageUpgrade: formatPrice(row.postageUpgrade),
        totalCostPerPiece: formatPrice(row.totalCostPerPiece),
        discount: formatPrice(row.discount),
        amount: formatPrice(row.lineItemTotal + row.doubleSidedPrinting + row.extraText + row.postageUpgrade - row.discount)
      }))
      
      setDataToExport(finalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.payload, order, orderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DisplayData | 'Amount'
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...rows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage, isLoading, rows]
  );

  const renderAmount = (row: Data) => {
    const { lineItemTotal, doubleSidedPrinting, extraText, postageUpgrade, discount } = row;
    const totalAmount = lineItemTotal + doubleSidedPrinting + extraText + postageUpgrade - discount;
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {formatPrice(totalAmount)}
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%', padding: '2rem', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px' }}>
      {
        (!showLoader && !isLoading && rows?.length === 0) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='paragraphMedium'>You don't have any orders yet!</Typography> 
          </Box>
        )
      }
      {
        (showLoader || isLoading || rows?.length > 0) && (
          <React.Fragment>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!isLoading && visibleRows.map((row, index) => {
                    const labelId = `enhanced-table-label-${index}`;
      
                    return (
                      <TableRow hover tabIndex={-1} key={index} sx={{ cursor: 'pointer' }}>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} component="th" id={labelId} scope="row">
                          {row.date ? moment(row.date).format('YYYY-MM-DD') : ''}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}}>{row.orderNumber}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}}>{row.productType}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}}>{row.orderStatus}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">{row.orderQuantity}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">{formatPrice(row.lineItemTotal)}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">{formatPrice(row.doubleSidedPrinting)}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">{formatPrice(row.extraText)}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">{formatPrice(row.postageUpgrade)}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">{formatPrice(row.totalCostPerPiece)}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">{formatPrice(row.discount)}</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}}>{renderAmount(row)}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
      
                  {isLoading && (
                    <React.Fragment>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          {Array(12).fill(null).map((_, idx) => (
                            <TableCell key={idx} sx={{ width: idx === 0 ? '50px' : '400px' }}>
                              <Skeleton height={35} />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                margin: '2rem 0 0 0',
                '.MuiTablePagination-toolbar': {
                  fontSize: '0.875rem',
                },
                '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                  fontSize: '0.75rem',
                },
                '.MuiTablePagination-select': {
                  fontSize: '0.75rem',
                },
              }}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </React.Fragment>
        )
      }
    </Box>
  );
}
