import React from 'react';

import { Box } from '@mui/system';
import { List, ListItem, Typography } from '@mui/material';

const Webflow: React.FC = () => {
  return (
    <Box>
        <Typography variant='paragraphMedium' sx={{ fontWeight: 'bold'}}>Webflow</Typography>
        <List >
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium'>Log in to your Webflow account and click on <b>Open Designer</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium'>On the left side, click on <b>Add</b> and find <b>Code Embed</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium'>Post the scripts, click <b>Save</b> and publish the changes</Typography>
            </ListItem>
        </List>
        
    </Box>
  );
}

export default Webflow;