import { IProfile } from "models/profile";

interface IPermission {
    description: string,
    id: number,
    key: string,
    name: string
}

export function getUserData() {
    const userData = sessionStorage.getItem('dXNlckRhdGE=');
    
    if (userData) {
        try {
            const decodedData = atob(userData);
            return JSON.parse(decodedData);
        } catch (error) {
            return null;
        }
    }

    return null;
}

export function isOnboardingDone(): boolean {
    return (getUserData()?.id !== 0 || (getUserData()?.firstName && !getUserData()?.lastName && !getUserData()?.companyName && !getUserData()?.annualMailVolumeId && !getUserData()?.industry)) 
}

export function clearUserData() {
    sessionStorage.removeItem('dXNlckRhdGE=')
}

export function getUserFeatures() {
    const userData = sessionStorage.getItem('dXNlckRhdGE=');
    return JSON.parse(atob(userData ?? ''))?.systemFeatures;
}

export function isAllowedPermission(permissionToChecK: string) {
    return getUserFeatures().some((p: IPermission) => p.key === permissionToChecK)
}

export function handleUpdateUserData(userData: IProfile) {
    sessionStorage.setItem(btoa('userData'), btoa(JSON.stringify(userData)))
}