import React from "react";
import App from "App";
import theme from "material-ui/theme";
import ReactDOM from "react-dom/client";
import DataSourceCallback from "pages/data-source-callback";
import CheckoutAnonymousSession from "newStandard/src/pages/Subscription/CheckoutAnonymousSession";

import { ThemeProvider } from "@mui/material/styles";
import { GlobalProvider } from "context/global-context";
import { loginRequest, msalConfig } from "authConfig";
import { MaterialUIControllerProvider } from "./context/material-ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { AuthenticationResult, EventMessage, EventType, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { SelfManagementPlanModalProvider } from "context/selfmanageplan-context";
import { SnackbarProvider } from "notistack";
import { clearUserData } from "helpers/getUserInfos";

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    localStorage.setItem("token", payload.idToken);
  }
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    clearUserData();
    localStorage.removeItem("token");
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

const authRequest = {
  ...loginRequest,
};

const DynamicMsalTemplate = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  // ToDo: Change logic bellow if is necessary in future work with more unauthenticated routes
  const unauthenticatedCheckoutSession = location.pathname.startsWith("/subscription-checkout-session");

  return (
    <MsalAuthenticationTemplate
      interactionType={(unauthenticatedCheckoutSession) ? InteractionType.None : InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(

  <SnackbarProvider 
  anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
  }}
  maxSnack={3}
>
  <SelfManagementPlanModalProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <ThemeProvider theme={theme}>
            <GlobalProvider>
              <Routes>
                <Route path="/oauth/callback/:source" element={<DataSourceCallback />} />
                <Route path="/subscription-checkout-session/:id" element={<CheckoutAnonymousSession />} />
                <Route
                  path="*"
                  element={
                    <MsalProvider instance={msalInstance}>
                      <DynamicMsalTemplate>
                        <App />
                      </DynamicMsalTemplate>
                    </MsalProvider>
                  }
                />
              </Routes>
            </GlobalProvider>
          </ThemeProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </SelfManagementPlanModalProvider>
</SnackbarProvider>
);
