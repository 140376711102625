import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const StepZero: React.FC = () => {
  const [showText, setShowText] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowText(true), 1500);
    setTimeout(() => setShowMessage(true), 2000);
  }, []);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >

        <Typography
          variant="h1"
          sx={{
            color: '#5551FF',
            marginTop: 2,
            zIndex: 10,
            marginBottom: '24px',
            opacity: showText ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          Welcome to LettrLabs.
        </Typography>


        <Typography
          variant="paragraphLarge"
          sx={{
            marginTop: 2,
            zIndex: 10,
            opacity: showMessage ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          Let's learn a bit about you to help you get started.
        </Typography>
      </Box>
    </>

  );
};

export default StepZero;
