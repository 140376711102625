import { useEffect, useRef } from 'react';
import { Box, CircularProgress } from '@mui/material';
import FunnelGraph from 'funnel-graph-js';
import { useQuery } from '@tanstack/react-query';
import useShopifyDashboardService from 'newStandard/src/services/shopifyDashboard';
import 'funnel-graph-js/dist/css/main.min.css';
import 'funnel-graph-js/dist/css/theme.min.css';
import './styles.scss';

export default function FunnelChart() {
  const { getShopifyDashboardRecentCampaigns } = useShopifyDashboardService();
  const { data: chart, isLoading } = useQuery({
    queryKey: ['getShopifyDashboardRecentCampaigns'],
    queryFn: () => getShopifyDashboardRecentCampaigns(),
  });

  const graphRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chart || !graphRef.current) return;

    const container = graphRef.current;
    container.innerHTML = '';

    const labelsValues = chart.payload.map(val => val.title);
    const subLabelsValues = chart.payload.map(val => val.subValue);
    const dataValues = chart.payload.map(val => [val.value]);

    const graph = new FunnelGraph({
      container: `#${container.id}`,
      gradientDirection: 'horizontal',
      data: {
        labels: labelsValues,
        subLabels: subLabelsValues,
        values: dataValues,
        colors: [
          ['#A4A2FE', '#5955FF'],
          ['#B5B3FF', '#A4A2FE'],
          ['#7C79FE', '#A4A2FE'],
          ['#8685FF', '#B5B3FF'],
          ['#5955FF', '#8484FF'],
        ],
      },
      tooltip: { enabled: false },
      displayPercent: false,
      direction: 'horizontal',
    });

    graph.draw();

    const labels = container.querySelectorAll('.svg-funnel-js__label');
    labels.forEach((label) => {
      const labelValue = label.querySelector('.label__value');
      if (labelValue) {
        const originalValue = labelValue.innerHTML.trim();
        labelValue.innerHTML = `${originalValue} <span>Cards</span>`;
      }
    });

    const handleResize = () => {
      container.innerHTML = '';
      graph.draw();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chart]);

  const content = isLoading ? <Box sx={{alignItems: "center", justifyContent: "center", display: "flex"}}><CircularProgress size="25px" /></Box> : <Box ref={graphRef} id="funnelGraphContainer" style={{ width: '100%', height: '350px' }} />;;

  return (
    <>
      {content}
    </>
  )
}