import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import HomeIcon from '@mui/icons-material/Home';
import StepLabel from '@mui/material/StepLabel';
import CheckIcon from '@mui/icons-material/Check';
import StepConnector from '@mui/material/StepConnector';

import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const steps = [
  { label: 'Personal Info' },
  { label: 'Service Areas' },
  { label: 'Finish' },
];

const CustomStepConnector = styled(StepConnector)({
  '& .MuiStepConnector-line': {
    borderColor: '#5551FF !important',
    borderWidth: '1px !important',
    marginLeft: '2px !important'
  },
});

interface IVerticalLinearStepper {
  activeStep: number
}

export default function VerticalLinearStepper({ activeStep }: IVerticalLinearStepper) {

  const navigate = useNavigate()

  return (
    <Box sx={{ maxWidth: 400}}>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomStepConnector />}>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconProps={{
                sx: {
                  width: 18,
                  height: 18,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  justifyContent: 'center',
                  borderRadius: '50%',
                  border: '2px solid #5551FF',
                  backgroundColor: activeStep >= index ? '#5551FF' : '#FFF',
                  color: activeStep >= index ? '#FFF' : '#5551FF',
                  fill:
                    activeStep === index ? '#5551FF' : activeStep > index ? '#5551FF' : '#FFF',
                  margin: '0 !important',
                  padding: '0 !important',
                  '&.MuiStepLabel-labelContainer': {
                    fontSize: '30px !important'
                  },
                  '&.MuiStepIcon-text': {
                    display: 'none !important',
                    fill: 'transparent !important'
                  },
                  '&.Mui-active': {
                    fill: '#FFF !important',
                    margin: '0 !important'
                  },
                },
              }}
              sx={{
                margin: '0 !important',
                padding: '0 !important',
                fontSize: '14px !important',
                '&.MuiStepLabel-labelContainer': {
                    fontSize: '10px !important'
                  },
                '&.MuiStepIcon-text': {
                    display: 'none !important',
                    fill: 'transparent !important'
                  },
                '& .MuiStepLabel-label': {
                  margin: '0 !important',
                  padding: '0 !important',
                  color: activeStep >= index ? '#5551FF' : 'grey',
                  fontSize: '14px !important',
                },
                '& .Mui-active': {
                  margin: '0 !important',
                },
              }}
              icon={activeStep > index ? <CheckIcon sx={{ fontSize: 14, width: 18, padding: '.25rem', height: 18, fill: '#FFF !important', background: '#5551FF', borderRadius: '100%' }} /> : ''}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
        <Box onClick={() => navigate('/')}  sx={{ display: 'flex', flexDirection: 'column', width: '145px', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 20, cursor: 'pointer !important' }}>
          <HomeIcon sx={{ color: '#5551ff', cursor: 'pointer !important' }}/>
          <Typography sx={{ cursor: 'pointer', color: '#5551ff' }} variant='paragraphSmall' fontWeight={'bold'}>Go to Home</Typography>
        </Box>
      </Stepper>
    </Box>
  );
}
