import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { IDealSubscription } from 'services/checkout/checkout.types';

interface IProduct {
    data: IDealSubscription,
}

const Product: React.FC<IProduct> = ({ data }) => {

    const formatDate = (dateString: string) => {
        if(!dateString) return '';

        const date = new Date(dateString);
        const options: any = { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric' 
        };
        return date.toLocaleDateString('en-US', options);
    }

    const formatRecorrencyDescription = (id: number) => {
        switch (id) {
            case 0:
                return 'One Off'
            case 1:
                return 'day'
            case 2:
                return 'week'
            case 3:
                return 'month'
            case 4:
                return 'year'
            case 5:
                return 'every 3 months'
            case 6:
                return 'every 6 months'
            case 7:
                return 'Custom'
            default:
                return ''
        }
    }

    return (
        <Box sx={{ display: 'flex', margin: '2rem 0 0 0' }}>
            <Box sx={{ background: '#efeaff', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '.75rem', borderRadius: '5px'}}>
                <Box 
                    component={'img'} 
                    width={30}
                    src='https://cdn-icons-png.flaticon.com/512/8226/8226975.png'
                /> 
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography component={'b'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>{data.productName}</Typography>
                    <Typography component={'b'} sx={{ fontSize: '15px', fontWeight: 'bold', opacity: '.5'}}>$ {data?.totalPrice?.toLocaleString("en-US", {
                        minimumFractionDigits: 2, 
                        maximumFractionDigits: 2 
                    })}/{formatRecorrencyDescription(data?.billingPeriodId)}</Typography>
                </Box>
                <Typography variant='paragraphRegular' sx={{ fontWeight: 'normal', color: 'grey' }}>{data?.productDescription}</Typography>
                <Typography component={'b'} sx={{ fontSize: '13px', opacity: '.7'}}>Auto-renews on {formatDate(data?.nextPaymentOn)}</Typography>
            </Box>
        </Box>
    );
}

export default Product;