import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult, mockedObjectResult } from 'services/endpoint';

import { ChartType, IChart, IDashboardProfileOverview, IDashboardRecentCampaign, IDashboardResultCampaign, IShopifyDashboardConfiguration } from './types';
import { mockChart, mockShopifyDashboardConfiguration } from './mocks';

export default function useShopifyDashboardService() {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/shopify-dashboard`;
  const shouldMock = true;

  const getShopifyDashboardConfiguration = (): Promise<IObjectResult<IShopifyDashboardConfiguration>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: mockShopifyDashboardConfiguration }));
    return execute({ endpoint: `${API_PATH}/configuration`, method: 'GET' });
  };
  
  const getOverview = (): Promise<IObjectResult<IDashboardProfileOverview[]>> => {
    return execute({ 
      endpoint: `${API_ENDPOINT}/dashboard/overview`, 
      method: 'GET'
    })
  }

  const getEnableDashboard = (): Promise<IObjectResult<IDashboardProfileOverview[]>> => {
    return execute({ 
      endpoint: `${API_ENDPOINT}/dashboard/enable`, 
      method: 'GET'
    })
  }

  const setShopifyDashboardConfiguration = (
    config: Partial<IShopifyDashboardConfiguration>
  ): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(mockedObjectResult({})));
    return execute({ endpoint: `${API_PATH}/configuration`, method: 'PUT', data: config });
  };

  const getShopifyDashboardChartData = <T>(
    chart: ChartType,
    metric: string = ''
  ): Promise<IObjectResult<IChart<T>>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: mockChart(chart) as IChart<T> }));
    return execute({ endpoint: `${API_PATH}/${chart}/${metric}`, method: 'GET' });
  };

  const getShopifyDashboardRecentCampaigns = (): Promise<IObjectResult<IDashboardRecentCampaign[]>> => {
    return execute({ endpoint: `${API_ENDPOINT}/dashboard/recent-campaign`, method: 'GET' });
  };

  const getShopifyDashboardResultCampaigns = (): Promise<IObjectResult<IDashboardResultCampaign[]>> => {
    return execute({ endpoint: `${API_ENDPOINT}/dashboard/result-campaign`, method: 'GET' });
  };

  return {
    getOverview,
    getShopifyDashboardConfiguration,
    setShopifyDashboardConfiguration,
    getShopifyDashboardChartData,
    getShopifyDashboardRecentCampaigns,
    getShopifyDashboardResultCampaigns,
    getEnableDashboard
  };
}
