export enum IntegrationOrderType {
    GENERIC = 0,
    NORMAL = 1,
    RADIUS_MAIL = 2,
    HAIL_TRACE = 3,
    SHOPIFY = 4,
    KLAVIYO = 5,
    LEAD_REVEAL = 6,
    MOVER = 7,
}

export interface IVM_GridOrder {
    id: number
    expectedMailDate: Date
    estimatedDeliveryDateMin: Date
    estimatedDeliveryDateMax: Date
    friendlyId: string
    mailedCount: number
    mailedDate: Date
    name: string
    orderType: string
    paidDate: Date
    postageType: string
    product: string
    queueCount: number
    mockedQueueCount: number
    processedCount: number
    recipientCount: number
    totalRecipients: number
    status: string
    statusDate: Date
    templateId: number
    inProductionCount: number
    qrScansCount: number
    callsReceivedCount: number
    totalCallTime: number
    envelopeFull: string
    sceneBackFull: string
    sceneBackThumb: string
    sceneFrontFull: string
    sceneFrontThumb: string
    platform: string
    integrationOrderType: IntegrationOrderType
    inProcessing: boolean
    mailed: number
    inTransit: number
    atLocalFacility: number
    delivered: number
}