import { Home, FormatListBulleted, Person, Star, AutoMode, CreditCard, Construction } from '@mui/icons-material';

import OrdersPage from 'pages/orders';
import MyTemplatesPage from 'pages/my-templates';
import CallTrackingOrderPage from 'pages/call-tracking/order';
import CallTrackingHistoryPage from 'pages/call-tracking/manage-numbers';
import DiscountCodes from 'pages/discount-codes';
import AddressBookPage from 'pages/address-book';
import RecipientSearchHistoryPage from 'pages/recipient-search/history';
import RadiusMailHistoryPage from 'pages/radius-mail/history';
import SmartAutomationPage from 'pages/smart-automation';
import Billing from 'pages/settings/billing';
import DoNotMail from 'pages/do-not-mail';

import { RouteType } from './types';
import OrderSummary from 'pages/settings/billing/order-summary';
import TransactionSummary from 'pages/settings/billing/transaction-summary';
import ShopifyDashboard from '../pages/ShopifyDashboard';

const sidebarRoutes: RouteType[] = [
  {
    icon: <Home />,
    name: 'Home',
    route: '/home',
    component: <ShopifyDashboard />,
  },
  {
    icon: <FormatListBulleted />,
    name: 'Orders',
    collapse: [
      {
        name: 'Orders',
        route: '/orders',
        component: <OrdersPage />,
      },
      {
        name: 'Radius Mail',
        route: '/radius-mailing',
        component: <RadiusMailHistoryPage />,
      },
    ],
  },
  {
    icon: <Person />,
    name: 'Leads',
    collapse: [
      {
        name: 'Address Book',
        route: '/address-book',
        component: <AddressBookPage />,
      },

      {
        name: 'Recipient Search',
        route: '/recipient-search',
        component: <RecipientSearchHistoryPage />,
      },
      // Data enrichment doesn't exist yet
      {
        name: 'Do Not Mail',
        route: '/do-not-mail',
        component: <DoNotMail />,
      },
    ],
  },
  {
    icon: <AutoMode />,
    name: 'Automations',
    collapse: [
      {
        name: 'Automations',
        route: '/automations',
        component: <SmartAutomationPage />,
      }
    ],
  },
  {
    icon: <Star />,
    name: 'Goals',
    collapse: [
      {
        name: 'Call Tracking Order',
        route: '/call-tracking/order',
        component: <CallTrackingOrderPage />,
      },
      {
        name: 'Manage Call Tracking',
        route: '/call-tracking/history',
        component: <CallTrackingHistoryPage />,
      },
      {
        name: 'Discount Codes',
        route: '/discount-codes',
        component: <DiscountCodes />,
      },
    ],
  },
  {
    icon: <Construction />,
    name: 'Tools',
    collapse: [
      {
        name: 'Templates',
        route: '/my-templates',
        component: <MyTemplatesPage />,
      },
    ],
  },
  {
    icon: <CreditCard />,
    name: 'Billing',
    collapse: [
      {
        name: 'Billing',
        route: '/settings/billing',
        component: <Billing />,
      },
      {
        name: 'Order Summary',
        route: '/settings/order-summary',
        component: <OrderSummary />,
      },
      {
        name: 'Transaction Summary',
        route: '/settings/transaction-summary',
        component: <TransactionSummary />,
      }
    ],
  },
];

export default sidebarRoutes;
