import React from 'react';

import { Box } from '@mui/system';
import { List, ListItem, Typography } from '@mui/material';

const Wordpress: React.FC = () => {
  return (
    <Box sx={{ margin: '1rem 0'}}>
        <Typography variant='paragraphMedium' sx={{ fontWeight: 'bold'}}>WordPress</Typography>
        <List>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium'>Log in to your WordPress admin dashboard</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Navigate to <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> Appearance <pre>{'>'}</pre> Theme Editor</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium'>In the Theme Files section, find and click on the <b>header.php</b> file</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium' sx={{ display: 'flex', gap: 1}}>Insert the provided script just before the closing <b><pre>{'</head>'}</pre></b> tag in the <b>header.php</b> file</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium' sx={{ display: 'flex', gap: 1}}>Next, navigate to the <b>footer.php</b> file</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium' sx={{ display: 'flex', gap: 1}}>Insert the other script just before the closing <b><pre>{'</body>'}</pre></b> tag in the <b>footer.php</b> file</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='paragraphMedium' sx={{ display: 'flex', gap: 1}}>Click <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> Update File</b> to save your changes</Typography>
            </ListItem>
        </List>
    </Box>
  );
}

export default Wordpress;
