import { IVM_GridOrder } from "models/views/gridOrderVm"
import "./index.scss"
import { LocalShipping, Mail, PersonOutline } from "@mui/icons-material"
import { OrderStatus } from "models/enums/orderStatus"
import moment from "moment"
import Actions from "../order-actions"
import StatusStepperV2 from "components/status-stepper-v2"
import { Tooltip } from "@mui/material"
import OrderDetailsModal from "components/order-details-modal"
import { useState } from "react"

interface OrderCardProps {
    order: IVM_GridOrder
    statusTracker: string
}

const OrderCard = ({ order, statusTracker }: OrderCardProps) => {
    const defaultImage = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/defaults/large_handwritten_card-back.png'
    const [showModal, setShowModal] = useState<string>('')
    return (
        <div className="card-box">
            <div className="card-status-box">
                {(order.status === OrderStatus.Draft || order.status === OrderStatus.PaymentNeeded || order.status === OrderStatus.EditsNeeded) && <Actions row={order} />}
            </div>
            <OrderDetailsModal show={showModal === 'details'} setShow={() => setShowModal('')} order={order} />
            <div className="card" onClick={() => {
                if (showModal === "") {
                    setShowModal('details')
                }
            }}>
                <div className="card-container">
                    <div className="row-header">
                        <div className="order-name">
                            <div className={`container-icon-top active-folder`} >
                                <Mail />
                            </div>
                            <div className="info-order">
                                <span className="info-id">#{order.id} - {order.name}</span>
                                <span className="product-order">
                                    {order.product}
                                </span>
                            </div>
                        </div>
                        <div className="order-name">


                        </div>
                    </div>
                    <div className="row-step">
                        <StatusStepperV2 status={statusTracker} mailed={order.mailed} inTransit={order.inTransit} atLocalFacility={order.atLocalFacility} delivered={order.delivered} />
                    </div>
                    <div className="row-images">
                        <div className="front">
                            <img alt="Front" src={order.sceneFrontThumb || defaultImage} />
                        </div>
                        <div className="back">
                            <img alt="Back" src={order.sceneBackFull || defaultImage} />
                        </div>
                    </div>
                    <div className="row-delivery">
                        <div className="col-delivery">
                            {order.estimatedDeliveryDateMin && order.estimatedDeliveryDateMax ?
                                <div className="col-delivery-info">
                                    <Tooltip title={!(order.status === OrderStatus.Mailed && order.mailedDate) && "Est. Delivery"}>
                                        <div className="title-delivery">
                                            <div className="container-icon">
                                                <LocalShipping />
                                            </div>
                                            <div className="infos-delivery">
                                                <div className="info-delivery">
                                                    {`${moment(order.estimatedDeliveryDateMin).format("MMM Do")} - ${moment(order.estimatedDeliveryDateMax).format("MMM Do")}`}
                                                </div>
                                            </div>
                                        </div>
                                    </Tooltip>

                                </div>
                                : <> {
                                    order.mailedDate ?
                                        <div className="col-delivery-info">
                                            <Tooltip title="Mailed Date">
                                                <div className="title-delivery">
                                                    <div className="container-icon">
                                                        <LocalShipping />
                                                    </div>
                                                    <div className="infos-delivery">
                                                        <div className="info-delivery">
                                                            {moment(order.mailedDate).format("MMM Do")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                        </div> :

                                        <div className="col-delivery-info">
                                            <div className="title-delivery">

                                            </div>
                                        </div>
                                }
                                </>}
                        </div>
                        {
                            order.totalRecipients && (
                                <Tooltip title="Recipients">
                                    <div className="col-delivery-info">
                                        <div className="title-delivery">
                                            <div className="container-icon">
                                                <PersonOutline />
                                            </div>
                                            <div className="infos-delivery">
                                                <div className="info-delivery">
                                                    {order.totalRecipients}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderCard