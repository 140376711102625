
import { useEffect, useState } from 'react';

import { useGlobal } from 'context/global-context';
import { ITemplate } from 'models/template';
import { IObjectResult } from 'services/endpoint';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import { ISmartAutomationPayload } from 'services/smart-automation/smart-automation.types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AuthenticatedStatusType, DataSourceType, IDataSource } from 'services/data-sources/data-sources.types';
import { Alert, Box, MenuItem, Select, SelectChangeEvent, Snackbar, Typography } from '@mui/material';

import MDCard from 'material-ui/components/MDCard';
import MoversFormik from './components/movers-formik';
import lettrLabsLogo from 'assets/images/logo-inverted.png';
import TemplateService from 'services/template';
import SmartAutomationForm from './components/smart-automation-form';
import MoversContextProvider from 'context/movers-context';
import useDataSourcesService from 'services/data-sources';
import SmartAutomationService from 'services/smart-automation';
import TwoButtonWithOptionalFormFieldModal from 'components/modal-with-two-buttons-and-textfield';
import SkeletonForm from './components/skeleton-form';

const SmartAutomation = () => {
  const SHOPIFY_SOURCE = '1';

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: automationId } = useParams();
  const { pathname, search } = useLocation();
  const { getAllDataSources } = useDataSourcesService();

  const { setShowLoader, templateFlow, setTemplateFlow } = useGlobal();

  const queryParams = new URLSearchParams(search);
  
  const name = queryParams.get('name');
  const isEdit = automationId !== 'new';
  const source = queryParams.get('source');

  // TODO: replace any
  const [error, setError] = useState<string>('');
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [dataSource, setDataSource] = useState<IDataSource>();
  const [newSource, setNewSource] = useState<number | null>(null);

  const { getTemplate } = TemplateService();
  const { getSmartAutomation, createSmartAutomation, updateSmartAutomation } = SmartAutomationService();

  const mutationOptions = {
    onMutate: () => setShowLoader(true),
    onSuccess: (response: IObjectResult<ISmartAutomationPayload>) => {
      if (response.hasErrors) {
        setError(response.errors?.[0] ?? 'Something went wrong.');
      } else {
        navigate(`/automations?status=createdSuccessfully`);
        setShowLoader(false);
      }
    },
  };

  const { mutate: createAutomation } = useMutation({
    mutationFn: createSmartAutomation,
    onMutate: () => setShowLoader(true),
    onSuccess: (response) => navigate(`/automations?status=createdSuccessfully`),
  });

  const { mutate: updateAutomation } = useMutation({
    mutationFn: updateSmartAutomation,
    ...mutationOptions,
  });
  
  const {
    data: smartAutomation,
    isLoading: isLoadingSM,
    isFetching: isFetchingSM,
  } = useQuery({
    queryKey: ['getSmartAutomation', isEdit ? Number(automationId) : null],
    queryFn: () => getSmartAutomation(isEdit ? Number(automationId) : undefined),
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });

  const { data: dataSourcesData , isLoading: isLoadingDataSources} = useQuery({
    queryKey: ['getAllDataSources'],
    queryFn: () => getAllDataSources(),
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });

  const dataSources = dataSourcesData?.payload;

  const {
    data: template,
    refetch: refetchTemplate,
    isLoading: isLoadingTemplate,
  } = useQuery({
    queryKey: ['getTemplate', smartAutomation?.payload.templateId],
    queryFn: () => getTemplate(smartAutomation?.payload.templateId ?? null),
    enabled: (!!isEdit && !!smartAutomation),
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });

  useEffect(() => {
    const isLoading =
      isLoadingSM ||
      isFetchingSM ||
      isLoadingTemplate ||
      isLoadingDataSources ||
      (smartAutomation?.payload?.payload && JSON.parse(smartAutomation.payload.payload)?.IsCalculatingTargets);

      if(!isLoading) {
        setIsAllLoaded(true);
      }
  }, [isLoadingSM, isFetchingSM, isLoadingTemplate, isLoadingDataSources, smartAutomation]);


  useEffect(() => {
    if (dataSources) {
      if (source) {
        const auxSource = dataSources?.find((ds: IDataSource) => ds.id === Number(source));
        if (
          (auxSource?.isEnabled && auxSource.authenticationStatus === AuthenticatedStatusType.Authenticated) ||
          isEdit
        )
          setDataSource(auxSource);
        else navigate('/automations');
      } else if (smartAutomation) {
        const { dataSourceId } = smartAutomation.payload;
        navigate(`${pathname}?source=${dataSourceId}`, { replace: true });
      } else setShowLoader(true);
    } else setShowLoader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSources, smartAutomation, navigate, setShowLoader, source, pathname, isEdit]);

  useEffect(() => {
    if (templateFlow.order?.template) {
      setInitialValues(templateFlow.order);
      setTemplateFlow({});
    } else if (isEdit) {
      if (!smartAutomation?.payload) return; // Exit if there is no payload in smartAutomation

      const { payload, templateId } = smartAutomation.payload; // Destructure payload and templateId from smartAutomation
      const newInitialValues = payload 
        ? { ...convertKeysToCamelCase(JSON.parse(payload)) } // Parse and convert keys to camelCase if payload exists
        : {};

      // Check template and templateId to determine the value of `newInitialValues.template`
      if (!template) {
        refetchTemplate(); // If the template is missing, refetch it
      } else {
        newInitialValues.template = templateId ? template : null; // Assign template if templateId exists; otherwise, set it to null
      }

      setInitialValues(newInitialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSources, smartAutomation, navigate, setShowLoader, source, pathname, isEdit]);

  const handleSourceChange = (e: SelectChangeEvent<string | number>) => {
    const newSourceId = Number(e.target.value);
    if (newSourceId !== Number(source)) setNewSource(newSourceId);
  };

  const handleReset = () => {
    navigate(`/smart-automation/new?source=${newSource}&name=${name}`);
    setNewSource(null);
  };

  const handleSubmit = <T extends { template: ITemplate }>(values: T) => {
    queryClient.invalidateQueries({ queryKey: ['allSmartAutomations'] });

    if(source === SHOPIFY_SOURCE) {
      handleSubmitShopify(values);
    } else {
      const { template, ...payload } = values;

      const smartAutomationPayload: ISmartAutomationPayload = {
        dataSourceId: dataSource?.id,
        templateId: template.id,
        payload: handleRemoveDisplayOnlyProperties(JSON.stringify(payload)),
        name: name || smartAutomation?.payload.name,
      };
      if (isEdit) updateAutomation({ ...smartAutomationPayload, id: smartAutomation?.payload.id });
      else createAutomation(smartAutomationPayload);
    }
  };

  const handleSubmitShopify = <T extends { template: ITemplate }>(values: T) => {
  
    const { template, ...payload } = values;
  
    const smartAutomationPayload: ISmartAutomationPayload = {
      dataSourceId: dataSource?.id,
      templateId: template.id,
      payload: handleRemoveDisplayOnlyProperties(
        JSON.stringify({
          ...payload,
          userCreatedFilters: (() => {
            try {
              const storedFilters = sessionStorage.getItem('tmpIntegrationOrderFilters');
              setShowLoader(false);
              return storedFilters ? JSON.parse(storedFilters) : null;
            } catch (error) {
              return null;
            }
          })(),
        })
      ),
      name: name || smartAutomation?.payload.name,
    };
    
    if (isEdit) updateAutomation({ ...smartAutomationPayload, id: smartAutomation?.payload.id });
    else createAutomation(smartAutomationPayload);
  };
  
  const handleRemoveDisplayOnlyProperties = (data: string) => {
    return data 
      .replace(/"order":"[^"]*".*?[,}]/g, '')
  }

  const disabled = isEdit && !smartAutomation?.payload.canEdit;

  const renderContent = () => {
    const isMovers = isAllLoaded && dataSource?.displayName === DataSourceType.Movers;
    const isSmartAutomationVisible = isAllLoaded && dataSource?.displayName !== DataSourceType.Movers;

    return (
      <>
        {
          isMovers && (
            <Box sx={{ display: isMovers ? 'block' : 'none' }}>
              <MoversContextProvider disabled={disabled}>
                <MoversFormik initialValues={initialValues} />
              </MoversContextProvider>
            </Box>
          )
        }
        <Box sx={{ display: isSmartAutomationVisible ? 'block' : 'none' }}>
          <SmartAutomationForm
            status={smartAutomation?.payload?.status}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            disabled={disabled}
          />
        </Box>

        <Box sx={{ display: smartAutomation?.hasErrors ? 'block' : 'none' }}>
          <Alert severity="error">{smartAutomation ? smartAutomation.errors?.[0] : ''}</Alert>
        </Box>

        <Box sx={{ display: !isAllLoaded && !smartAutomation?.hasErrors ? 'block' : 'none' }}>
          <MDCard sx={{ padding: '2rem 3rem' }}>
            <SkeletonForm />
          </MDCard>
        </Box>
      </>
    );
  };

  //work with detail data built when is edit
  useEffect(() => {
    if (!smartAutomation?.payload && !template) return;
  
    const payload = smartAutomation?.payload?.payload;
  
    if (payload && template) {
      setInitialValues({ 
        ...convertKeysToCamelCase(JSON.parse(payload)),
        template: template
      });
    }
  }, [smartAutomation, template]);

  return (
    <>
      {isAllLoaded && disabled && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography variant="h6" fontWeight="normal">
            This automation cannot be edited, as it is not in a Testing status.
          </Typography>
        </Alert>
      )}
      <MDCard sx={{ flexDirection: 'row', p: 2, mb: 1 }}>
        <Typography fontSize={24} fontWeight={'bold'} color={'secondary'} flex={1}>
          {isEdit ? 'Edit' : 'New'} Automation
        </Typography>
        <Box display="flex" alignItems={'center'}>
          <Typography variant="h6" pb={1} mr={2}>
            Source:{' '}
          </Typography>
          <Select value={source ?? ''} sx={{ width: 220 }} onChange={handleSourceChange} disabled={isEdit}>
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            {dataSources?.map((source: IDataSource) => (
              <MenuItem
                key={`source${source.displayName}`}
                value={source.id}
                disabled={!source.isEnabled || source.authenticationStatus !== AuthenticatedStatusType.Authenticated}
              >
                <Box display="flex" gap={1}>
                  <img alt="" src={source.logoImageUrl || lettrLabsLogo} width={22} height={22} />
                  {source.displayName}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </MDCard>
      {renderContent()}
      <TwoButtonWithOptionalFormFieldModal
        open={newSource !== null}
        onClose={() => setNewSource(null)}
        primaryButtonOnClick={() => handleReset()}
        primaryButtonText="Continue"
        secondaryButtonOnClick={() => setNewSource(null)}
        secondaryButtonText="Never mind"
        title={'Confirm Source Change'}
        description="Changing the source will reset your current selections for templates and filters. Do you want to continue?"
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError('')} severity="error" variant="standard" sx={{ py: 0 }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SmartAutomation;
