import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { CSVBoxButton } from '@csvbox/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useDiscountCodesService from 'services/discount-codes';
import MDButton from 'material-ui/components/MDButton';
import { dicountCodeValidation } from 'services/discount-codes/discount-codes.schema';
import FormikErrorMessage from 'components/formik-error-message';
import { IMultiUseCoupon, ISingleUseList } from 'services/discount-codes/discount-codes.types';
import ProfileService from 'services/profile';
import colors from 'material-ui/theme/base/colors';
import MDDatePicker from 'material-ui/components/MDDatePicker';
import { IObjectResult } from 'services/endpoint';

export interface DiscountCodesUpsertModalProps {
  open: boolean;
  onClose?: () => void;
  actionType?: 'create' | 'edit';
  discountType?: 'multiUse' | 'singleUse';
  initialValues?: IMultiUseCoupon | ISingleUseList;
  isCouponsStep?: boolean;
}

export default function DiscountCodesUpsertModal({
  open,
  onClose,
  actionType,
  discountType,
  initialValues = { id: undefined, name: '', expirationDate: '' },
  isCouponsStep,
}: DiscountCodesUpsertModalProps) {
  const isCreate = actionType === 'create';
  const isMulti = discountType === 'multiUse';
  const submitButtonText = isCreate ? (isMulti ? 'Create Coupon' : 'Next: Coupons') : 'Save Changes';
  const [nextStep, setNextStep] = useState<{ open: boolean; listId?: number }>({ open: false });
  const [tableError, setTableError] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>('');

  const queryClient = useQueryClient();
  const { getProfile } = ProfileService();
  const { createMultiUseCoupon, createSingleUseCoupon, updateMultiUseCoupon, updateSingleUseList } =
    useDiscountCodesService();

  useEffect(() => {
    if (open && isCouponsStep && !nextStep.open) setNextStep({ open: true, listId: initialValues.id });
  }, [open, initialValues.id, isCouponsStep, nextStep.open]);

  const handleClose = () => {
    onClose?.();
    setTableError(false);
    setResponseError('');
    if (nextStep.open) setNextStep({ open: false });
  };

  const onSuccess = (result: IObjectResult<ISingleUseList>) => {
    if (result.hasErrors) setResponseError(result.errors?.[0] ?? 'Something went wrong.');
    else {
      if (isCreate && !isMulti) setNextStep({ open: true, listId: result.payload.id });
      else handleClose();
      queryClient.invalidateQueries({ queryKey: ['getAllDiscountCodes'] });
    }
  };

  const { data: profile } = useQuery({ queryKey: ['profile'], queryFn: getProfile, refetchOnWindowFocus: false });
  const { mutate: updateMultiUse, isPending: isPendingUpdateMultiUse } = useMutation({
    mutationFn: updateMultiUseCoupon,
    onSuccess,
  });
  const { mutate: updateSingleUse, isPending: isPendingUpdateSingleUse } = useMutation({
    mutationFn: updateSingleUseList,
    onSuccess,
  });
  const { mutate: createMultiUse, isPending: isPendingCreateMultiUse } = useMutation({
    mutationFn: createMultiUseCoupon,
    onSuccess,
  });
  const { mutate: createSingleUse, isPending: isPendingCreateSingleUse } = useMutation({
    mutationFn: createSingleUseCoupon,
    onSuccess,
  });
  const isPending =
    isPendingCreateMultiUse || isPendingCreateSingleUse || isPendingUpdateMultiUse || isPendingUpdateSingleUse;

  const handleSubmit = (values: IMultiUseCoupon | ISingleUseList) => {
    if (isMulti) {
      if (isCreate) createMultiUse(values);
      else updateMultiUse(values);
    } else if (isCreate) createSingleUse(values);
    else updateSingleUse(values);
  };

  const handleUploadFinished = (result: boolean) => {
    if (result) {
      queryClient.invalidateQueries({ queryKey: ['getAllDiscountCodes'] });
      if (initialValues?.id) queryClient.invalidateQueries({ queryKey: ['getCouponByList', initialValues.id] });
      handleClose();
    } else setTableError(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {nextStep.open ? (
        <DialogContent>
          <Typography variant="h6" mb={2}>
            Choose Coupons Source
          </Typography>

          <Box display={'flex'} gap={1} justifyContent={'center'}>
            {profile?.id && <CSVBoxButton
              licenseKey={process.env.REACT_APP_CSVBOX_DISCOUNT_CODES ?? ''}
              user={{ user_id: 'LettrLabsCsvBoxAdmin', profile_id: profile?.id, couponList_id: nextStep.listId }}
              onImport={handleUploadFinished}
              render={(launch) => (
                <Paper variant="outlined" sx={{ width: 200 }}>
                  <Stack m={1} alignItems={'center'} spacing={1}>
                    <Typography fontWeight={'bold'}>CSV File</Typography>
                    <MDButton onClick={launch} size="small">
                      Upload
                    </MDButton>
                  </Stack>
                </Paper>
              )}
            />}

            <Paper variant="outlined" sx={{ width: 200 }}>
              <Stack m={1} alignItems={'center'} spacing={1}>
                <Typography fontWeight={'bold'}>Shopify</Typography>
                <Typography fontWeight={'bold'} fontSize={14} color={colors.primary.main}>
                  Coming soon!
                </Typography>
              </Stack>
            </Paper>
          </Box>
          {tableError && (
            <Alert severity="error" onClose={() => setTableError(false)} sx={{ mt: 1 }}>
              CSV upload failed. Please review your list for duplicate codes and try again, or contact support for
              assistance.
            </Alert>
          )}
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="h6" mb={2}>
            {isMulti ? 'Multi Use Coupon' : 'Single Use Coupon List'}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={dicountCodeValidation(isMulti && !isCreate)}
            onSubmit={handleSubmit}
          >
            <Form>
              <Box display={'flex'} gap={2} mb={2}>
                {(!isMulti || isCreate) && (
                  <Field name="name">
                    {({ field, meta }: FieldProps) => (
                      <Box flex={1}>
                        <Typography variant="paragraphRegular">{isMulti ? 'Coupon Code' : 'List Name'}</Typography>
                        <TextField {...field} fullWidth />
                        <FormikErrorMessage meta={meta} />
                      </Box>
                    )}
                  </Field>
                )}
                <Field name="expirationDate">
                  {({ field, form }: FieldProps) => (
                    <Box flex={1}>
                      <Typography variant="paragraphRegular">Expiration Date (Optional)</Typography>
                      <MDDatePicker
                        options={{ minDate: new Date() }}
                        onChange={(_: Date, dateStr: string) => form.setFieldValue('expirationDate', dateStr)}
                        input={{
                          fullWidth: true,
                          value: field.value ? format(new Date(field.value), 'MM/dd/yyyy') : '',
                        }}
                      />
                    </Box>
                  )}
                </Field>
              </Box>

              {responseError && (
                <Alert severity="error" onClose={() => setResponseError('')} sx={{ my: 1, py: 0 }}>
                  {responseError}
                </Alert>
              )}
              <Box display={'flex'} gap={2}>
                <MDButton fullWidth type="submit" disabled={isPending}>
                  {isPending ? <CircularProgress size={20} color="secondary" /> : submitButtonText}
                </MDButton>
                <MDButton color="light" onClick={handleClose} disabled={isPending}>
                  Cancel
                </MDButton>
              </Box>
            </Form>
          </Formik>
        </DialogContent>
      )}
    </Dialog>
  );
}
