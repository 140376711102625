import { useEffect } from 'react';
import { DialogContent, DialogTitle, Dialog, Typography, Box, Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import Loader from 'components/loader';
import useSmartAutomationService from 'services/smart-automation';
import { ISmartAutomationPayload, ISmartAutomationSchema } from 'services/smart-automation/smart-automation.types';
import MDButton from 'material-ui/components/MDButton';
import { formatPrice } from 'helpers/formatters';
import { useLocation } from 'react-router-dom';

interface LeadRevealEnableProps {
  open: boolean;
  payload?: ISmartAutomationSchema;
  onSubmit: () => void;
  onCancel: () => void;
}

const LeadRevealEnable = ({ open, payload, onCancel, onSubmit }: LeadRevealEnableProps) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');
  const name = queryParams.get('name');

  const { getLeadRevealCheckout } = useSmartAutomationService();
  const { data, mutate: getCheckout, isPending } = useMutation({ mutationFn: getLeadRevealCheckout });
  const checkout = data?.payload;

  useEffect(() => {
    if (!payload) return;
    const { template, ...payloadToStringify } = payload;
    const checkoutPayload: ISmartAutomationPayload = {
      dataSourceId: Number(source),
      templateId: template.id,
      payload: JSON.stringify(payloadToStringify),
      name: name ?? '',
    };
    getCheckout(checkoutPayload);
  }, [getCheckout, name, payload, source]);

  return (
    <Dialog open={open} onClose={() => onCancel()} fullWidth maxWidth={'sm'}>
      <DialogContent>
        <DialogTitle>
          <Typography variant="h6">
            LeadReveal Activation
          </Typography>
        </DialogTitle>
        {isPending ? (
          <Box display="flex" justifyContent="center">
            <Loader />
          </Box>
        ) : (
          <>
            <Grid container>
              <Grid item xs={8} />
              <Grid item xs>
                <Typography variant="paragraphRegular" fontSize={16} textAlign={'center'}>
                  Maximum
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="paragraphRegular" fontSize={16}>
                  Cards Mailed{' '}
                  <Typography component="span" variant="paragraphRegular" fontSize={16} fontWeight={'normal'}>
                    (Per Month)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.totalQuantity ?? '-'}
              </Grid>
            </Grid>
            <Grid container mb={1}>
              <Grid item xs={8}>
                <Typography variant="paragraphRegular" fontSize={16}>
                  Cost{' '}
                  <Typography component="span" variant="paragraphRegular" fontSize={16} fontWeight={'normal'}>
                    (Per Month)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.totalCharges ? formatPrice(checkout.totalCharges) : '-'}
              </Grid>
            </Grid>
            <Typography variant="paragraphRegular" fontSize={16}>
              Please Note:
            </Typography>
            <Box component={'ul'} ml={3} mb={2}>
              <Typography component={'li'} fontSize={16} fontWeight={'normal'}>
                LeadReveal will activate for every website visitor who visits your website AND meets the filters you
                defined.
              </Typography>
              <Typography component={'li'} fontSize={16} fontWeight={'normal'}>
                LeadReveal will only activate for the same person every 6 months
              </Typography>
              <Typography component={'li'} fontSize={16} fontWeight={'normal'}>
                <strong>COMING SOON</strong> - Exclude existing customers and lead from activating LeadReveal.
              </Typography>
            </Box>

            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <MDButton fullWidth onClick={() => onSubmit()}>
                Enable Automation
              </MDButton>
              <MDButton color="light" onClick={() => onCancel()}>
                Cancel
              </MDButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LeadRevealEnable;
