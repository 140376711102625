import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { ILeadRevealSource } from 'services/data-sources/data-sources.types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Chip, CircularProgress, FormControlLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import useDataSourcesService from 'services/data-sources';

interface IManageLeadReveal {
  handleRefresh: () => void
  leadRevealData: ILeadRevealSource
}

const ManageLeadReveal: React.FC<IManageLeadReveal> = ({ handleRefresh, leadRevealData }) => {

  const queryClient = useQueryClient();

  const data = leadRevealData?.payload ? JSON.parse(leadRevealData.payload) : null;

  const { enqueueSnackbar } = useSnackbar();
  const { revokeDataSource, testLeadRevealConnection, updateMaximumMonthlyLeads, updateAntiPixel } = useDataSourcesService();

  const [loading, setLoading] = useState(false);
  const [updatign, setUpdating] = useState(false);
  const [updatignAntiPixel, setUpdatingAntiPixel] = useState(false);
  const [monthlyLeadsNumber, setMonthlyLeadsNumber] = useState('');
  const [initialMonthlyNumber, setInitialMonthlyNumber] = useState('');

  const [border, setBorder] = useState(false);
  const [expanded, setExpanded] = useState('');
  const [newPixelData, setNewPixelData] = useState('');
  const [newAntiPixelPage, setNewAntiPixelPage] = useState('');
  const [createAntiPixel, setCreateAntiPixel] = useState(false);
  const [antiPixelPages, setAntiPixelPages] = useState<string[]>([]);
  const [antiPixelPagesError, setAntiPixelPagesError] = useState(false);
  const [antiPixelsToCompare, setAntiPixelsToCompare] = useState<string[]>([]);
  const [createAntiPixelToCompare, setCreateAntiPixelToCompare] = useState(false);

  const { mutate: revoke } = useMutation({
    mutationFn: () => revokeDataSource('lead-reveal'),
    onMutate: () => setLoading(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
      setLoading(false);
      handleRefresh();
    },
  });

  const { mutate: testConnection, isPending: isTesting } = useMutation({
    mutationFn: testLeadRevealConnection,
    onSuccess: (result) => {
      if (result.payload) {
        enqueueSnackbar("The Google Tag Manager tag has been correctly configured on your site.", { variant: 'success' });
      } else {
        enqueueSnackbar("The tracker was not correctly installed yet and couldn't be detected. If you need any help, please contact our live customer support.", { variant: 'error' });
      }
    },
  });

  const { mutate: updatMonthlyLeads } = useMutation({
    mutationFn: () => updateMaximumMonthlyLeads({ maxLeads: Number(monthlyLeadsNumber), dataProviderId: 3 }),
    onMutate: () => setUpdating(true),
    onSuccess: () => {
      setUpdating(false);
      enqueueSnackbar('Lead Reveal successfully updated.', { variant: 'success' });
    },
  });

  const { mutate: updateAntiPixelPages } = useMutation({
    mutationFn: () => updateAntiPixel(newPixelData),
    onMutate: () => setUpdatingAntiPixel(true),
    onSuccess: (response) => {
      setUpdatingAntiPixel(false);
      if (response?.payload?.error?.errors) {
        enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
      } else {
        enqueueSnackbar('Lead Reveal successfully updated.', { variant: 'success' });
      }
    },
    onError: () => {
      setAntiPixelPagesError(true);
    }
  });

  const handleUpdate = () => {
    const payload = {
      ...data,
      AntiPixelPages: antiPixelPages,
      CreateAntiPixel: createAntiPixel
    }

    if (createAntiPixel !== createAntiPixelToCompare || antiPixelPages.toString() !== antiPixelsToCompare.toString()) {
      setNewPixelData(JSON.stringify(payload));
      updateAntiPixelPages()
    }

    if (initialMonthlyNumber !== monthlyLeadsNumber) {
      updatMonthlyLeads()
    }
  }

  const handleRevoke = () => {
    revoke();
  }

  const handleAccordionToggle = () => {
    setExpanded(expanded === 'AntiPixel' ? '' : 'AntiPixel');
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateAntiPixel(event.target.checked);
  };

  const handleAddPage = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === 'Enter' || event.key === ' ') && newAntiPixelPage.trim()) {
      setAntiPixelPages([...antiPixelPages, newAntiPixelPage.trim()]);
      setNewAntiPixelPage('');
    }
  };

  const handleDeletePage = (index: number) => {
    const updatedPages = [...antiPixelPages];
    updatedPages.splice(index, 1);
    setAntiPixelPages(updatedPages);
  };

  const canUpdate = () => {
    if (createAntiPixel !== createAntiPixelToCompare) {
      return true
    }

    if (antiPixelPages.toString() !== antiPixelsToCompare.toString()) {
      return true
    }
  }

  useEffect(() => {
    if (data) {
      setMonthlyLeadsNumber(data.MonthlyLeads);
      setInitialMonthlyNumber(data.MonthlyLeads);
      setAntiPixelPages(data.AntiPixelPages);
      setAntiPixelsToCompare(data.AntiPixelPages);
      setCreateAntiPixel(data.CreateAntiPixel);
      setCreateAntiPixelToCompare(data.CreateAntiPixel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '1rem' }}>

      <Typography variant="h6" sx={{ margin: '2rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
        Manage LeadReveal
      </Typography>

      <Box sx={{ margin: '1rem 0 0 0' }}>
        <Typography variant="paragraphRegular">Maximum Monthly Leads</Typography>
        <Select value={monthlyLeadsNumber} onChange={(e) => setMonthlyLeadsNumber(e.target.value)}>
          <MenuItem value={0} disabled>
            Select an option
          </MenuItem>
          {[250, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000].map((leads) => (
            <MenuItem key={leads} value={leads}>
              {leads}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ padding: '2rem 0', display: 'flex' }}>
        <Box sx={{ width: '70%' }}>
          <Typography variant="paragraphMedium" fontWeight={'bold'}>Website URL</Typography>
          <Typography variant="paragraphMedium" sx={{ marginLeft: 1 }}>{data?.WebsiteUrl}</Typography>
        </Box>
        <Box sx={{ width: '30%%' }}>
          <Typography variant="paragraphMedium" fontWeight={'bold'}>Project ID</Typography>
          <Typography variant="paragraphMedium" sx={{ marginLeft: 1 }}>{data?.ProjectId}</Typography>
        </Box>
      </Box>

      <Accordion
        elevation={0}
        disableGutters
        expanded={true}
        onChange={handleAccordionToggle}
        sx={{ border: '2px solid rgba(0,0,0,.1)' }}
      >
        <AccordionSummary>
          <FormControlLabel
            control={
              <Checkbox
                checked={createAntiPixel}
                onChange={handleCheckboxChange}
              />
            }
            label="Anti-Pixel: Suppress LeadReveal when the website address contains one of the pages below"
          />
        </AccordionSummary>
        <AccordionDetails>
          <MDCard
            color="light"
            borderRadiusSize="xl"
            sx={{
              border: `${antiPixelPagesError ? '1' : '2'}px solid ${antiPixelPagesError || border ? 'rgba(0,0,0,0, .1)' : 'transparent'
                }`,
            }}
          >
            <Box mx={1} pt="2px" display="flex" alignItems="center" flexWrap="wrap">
              {antiPixelPages.map((page, index) => (
                <Chip
                  key={index}
                  label={page}
                  disabled={!createAntiPixel}
                  onDelete={() => handleDeletePage(index)}
                  sx={{
                    m: '2px',
                    backgroundColor: 'rgb(252 220 210)',
                    '& .MuiChip-deleteIcon': {
                      color: '#F05A2A',
                    },
                  }}
                />
              ))}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Add Page"
                value={newAntiPixelPage}
                onFocus={() => setBorder(true)}
                onBlur={() => setBorder(false)}
                onChange={(e) => setNewAntiPixelPage(e.target.value)}
                onKeyDown={handleAddPage}
                sx={{ minWidth: 100, flex: 1, '& fieldset': { border: 'none' } }}
                disabled={!createAntiPixel}
              />
            </Box>
          </MDCard>
          <Box sx={{ margin: '.5rem 0 0 0' }} />
          <small>Press "Enter" or "Space" to insert the new page.</small>
          <Typography variant='paragraphRegular' color={antiPixelPagesError ? 'error' : undefined}>
            {antiPixelPagesError && 'Error message here. Press "Enter" or "Space" to insert the new page.'}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '2rem 0', gap: 2 }}>
        <MDButton color='primary' variant='transparent' disabled={loading} onClick={() => testConnection()} sx={{
          '&:hover': {
            backgroundColor: '#d32f2f',
          },
        }}>
          {
            isTesting ? (<CircularProgress color="inherit" size={18} />) : ('Test Script')
          }
        </MDButton>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <MDButton color='error' disabled={loading} onClick={handleRevoke} sx={{
            '&:hover': {
              backgroundColor: '#d32f2f',
            },
          }}>
            {loading ? <CircularProgress size={15} color='inherit' /> : 'Revoke'}
          </MDButton>
          <MDButton color='primary' onClick={() => handleUpdate()} disabled={!monthlyLeadsNumber || (!canUpdate() && initialMonthlyNumber === monthlyLeadsNumber) || updatignAntiPixel}>
            {(updatign || updatignAntiPixel) ? <CircularProgress size={15} color='inherit' /> : 'Update'}
          </MDButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ManageLeadReveal;