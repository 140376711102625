import { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { IProfile } from 'models/profile';
import { useGlobal } from 'context/global-context';
import { sidebarFullWidth } from '../Sidebar';
import { NavLink, useNavigate } from 'react-router-dom';
import { getUserData, handleUpdateUserData } from 'helpers/getUserInfos';
import { AccountCircle, HelpOutlineRounded } from '@mui/icons-material';
import { useIsFetching, useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppBar, Autocomplete, Box, IconButton, List, ListItem, ListItemButton, ListItemText, TextField, Toolbar, Tooltip, Typography } from '@mui/material';

import logoBlue from 'assets/images/logo-blue.png';
import logoWhite from 'assets/images/logo-full.svg';
import ProfileService from 'services/profile';

export default function Topbar() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();
  const { getLoggedInProfile, getAvailableImpersonationProfile, postProfile } = ProfileService();

  const { data: profile } = useQuery({
    queryKey: ['loggedInProfile'],
    queryFn: getLoggedInProfile,
  });

  const { data: impersonationDataResult } = useQuery({
    queryKey: ['impersonationDataResult'],
    queryFn: getAvailableImpersonationProfile,
    refetchOnWindowFocus: false,
  });

  const currentActingAs = impersonationDataResult?.payload.find(
    (option) => option.id === profile?.actingAsUserId
  ) || null;

  const { mutate: updateProfile } = useMutation({
    mutationFn: postProfile,
    onMutate: () => setShowLoader(true),
    onSuccess: (updatedProfile: IProfile) => {
      const actingAs = updatedProfile?.actingAsUserId;
      handleUpdateUserData(updatedProfile);
      sessionStorage.setItem('actingAsUserId', actingAs ? actingAs.toString() : '');
      queryClient.invalidateQueries();
    },
  });

  const handleHelpClose = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (!isFetching && !isMutating) setShowLoader(false);
  }, [isFetching, isMutating, setShowLoader]);

  return (
    <AppBar
      position="sticky"
      color={currentActingAs ? 'secondary' : 'default'}
      sx={{
        boxShadow: 'none',
        background: currentActingAs ? 'rgba(226,0,0, .8)' : 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
      }}
    >
      <Toolbar sx={{ ml: `${sidebarFullWidth}px` }}>
        <NavLink to={'/home'}>
          <Box
            alt="Logo"
            height={30}
            component="img"
            sx={{ verticalAlign: 'middle' }}
            src={currentActingAs ? logoWhite : logoBlue}
          />
        </NavLink>
        <Box mx="auto" width={350}>
          {(profile?.canActAsUser || profile?.isActingAsUser) && (
            <Autocomplete
              fullWidth
              blurOnSelect
              options={impersonationDataResult?.payload ?? []}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.email)}
              renderInput={(params) => <TextField {...params} placeholder="Act as ..." />}
              value={currentActingAs}
              onChange={(_, newValue) => {
                const newActingAs = newValue && typeof newValue !== 'string';
                updateProfile({
                  ...profile,
                  actingAsUserId: newActingAs ? newValue.id : null,
                });
              }}
            />
          )}
        </Box>

        <Tooltip
          title={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1 }}>
              <List dense disablePadding sx={{ width: '100%' }}>
                <ListItem
                  disablePadding
                  sx={{
                    marginBottom: '3px',
                    '&:last-child': { marginBottom: 0 },
                  }}
                >

                  <ListItemButton
                    onClick={() => handleHelpClose('https://www.lettrlabs.com/help-guides')}
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderRadius: 1,
                      paddingY: '6px',
                      paddingX: '8px',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                  >
                    <ListItemText
                      primary="Tutorials"
                      primaryTypographyProps={{
                        variant: 'paragraphRegular',
                        sx: { fontWeight: 'normal', fontSize: '14px', color: '#344767' },
                      }}
                    />
                  </ListItemButton>
                  
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{
                    marginBottom: '2px',
                    '&:last-child': { marginBottom: 0 },
                  }}
                >
                  <ListItemButton
                    onClick={() => handleHelpClose('https://lettrlabs.kampsite.co/')}
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderRadius: 1,
                      paddingY: '6px',
                      paddingX: '8px',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                  >
                    <ListItemText
                      primary="Suggestions"
                      primaryTypographyProps={{
                        variant: 'paragraphRegular',
                        sx: { fontWeight: 'normal', fontSize: '14px', color: '#344767' },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          }
          placement="bottom"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                color: '#344767',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: 1,
                padding: '1px',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '140px',
              },
            },
            arrow: {
              sx: {
                color: '#d1d5e1',
              },
            },
          }}
        >
          <IconButton>
            <HelpOutlineRounded sx={{ height: 20, color: currentActingAs ? '#FFF' : '#344767' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1 }}>
              <List dense disablePadding sx={{ width: '100%' }}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate('/settings/profile')}>
                    <ListItemText primary="Profile"  primaryTypographyProps={{
                        variant: 'paragraphRegular',
                        sx: { fontWeight: 'normal', fontSize: '14px', color: '#344767' },
                      }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => { instance.logout(); }}>
                    <ListItemText primary="Logout"  primaryTypographyProps={{
                        variant: 'paragraphRegular',
                        sx: { fontWeight: 'normal', fontSize: '14px', color: '#344767' },
                      }}/>
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          }
          placement="bottom"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                color: '#344767',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: 1,
                padding: '1px',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '140px',
              },
            },
            arrow: {
              sx: {
                color: '#d1d5e1',
              },
            },
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
          >
            <AccountCircle
              sx={{
                width: '30px',
                height: '30px',
                marginRight: '5px',
                color: currentActingAs ? '#FFF' : '#344767',
                ml: 1,
              }}
            />
            <Typography
              variant='paragraphSmall'
              textAlign={'start'}
              fontWeight={'normal'}
              sx={{ lineHeight: 1 }}
              color={currentActingAs ? '#FFF' : '#344767'}
            >
              Hi {getUserData()?.firstName ? getUserData()?.firstName : ''}
              <br />
              <span style={{ opacity: 0.7 }}>{getUserData()?.email ?? ''}</span>
            </Typography>
          </Box>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}
