import { Field, FieldProps } from 'formik';
import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material';

import FormikErrorMessage from 'components/formik-error-message';

import { States } from './statesEnum';

const ADDRESS_FIELDS = [
  { name: 'firstName', label: 'First Name', md: 6 },
  { name: 'lastName', label: 'Last Name', md: 6 },
  { name: 'email', label: 'Email', md: 12 },
  { name: 'address1', label: 'Address 1', md: 12 },
  { name: 'address2', label: 'Address 2', md: 12 },
  { name: 'city', label: 'City', md: 5 },
];

interface AddressFormikFieldsProps {
  fields?: { name: string; label: string; md: number }[];
  zipName?: string;
}

export default function AddressFormikFields({
  fields = ADDRESS_FIELDS,
  zipName = 'zipCode',
}: AddressFormikFieldsProps) {
  return (
    <Grid container spacing={1}>
      {fields.map((f) => (
        <Field key={f.name} name={f.name}>
          {({ field, meta }: FieldProps) => (
            <Grid item xs={12} md={f.md}>
              <Typography variant="paragraphRegular">{f.label}</Typography>
              <TextField {...field} value={field.value ?? ''} fullWidth error={!!(meta?.touched && meta?.error)} />
              <FormikErrorMessage meta={meta} />
            </Grid>
          )}
        </Field>
      ))}

      <Field name={'state'}>
        {({ field, meta }: FieldProps) => (
          <Grid item xs={6} md={3}>
            <Typography variant="paragraphRegular">State</Typography>
            <Select {...field} value={field.value ?? ''} error={!!(meta?.touched && meta?.error)}>
              {Object.keys(States).map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
            <FormikErrorMessage meta={meta} />
          </Grid>
        )}
      </Field>

      <Field name={zipName}>
        {({ field, meta }: FieldProps) => (
          <Grid item xs={6} md={4}>
            <Typography variant="paragraphRegular">Zip Code</Typography>
            <TextField {...field} fullWidth error={!!(meta?.touched && meta?.error)} />
            <FormikErrorMessage meta={meta} />
          </Grid>
        )}
      </Field>
    </Grid>
  );
}
