import React, { useState } from 'react';

import { Box } from '@mui/system';
import { Step } from 'pages/automations/context/types';
import { useQuery } from '@tanstack/react-query';
import { IPlatform } from 'services/automations/automatios.service.types';
import { useGlobal } from 'context/global-context';
import { ITemplate } from 'models/template';
import { useNavigate } from 'react-router-dom';
import { getUserData, isAllowedPermission } from 'helpers/getUserInfos';
import { useAutomationContext } from 'pages/automations/context';
import { useSelfManagementPlanModal } from 'context/selfmanageplan-context';
import { Dialog, DialogContent, InputAdornment, TextField, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import CoreIcon from 'assets/icons/core';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from 'assets/icons/search';
import ZapierLogo from 'assets/images/Logo - Zapier.png';
import OpenAPILogo from 'assets/images/Logo - Open API.png';
import KlaviyoLogo from 'assets/images/klaviyo-logo.png';
import ShopifyLogo from 'assets/images/shopify-logo.svg';
import ApiKeyService from 'services/api-key-service';
import MoverMailLogo from 'assets/images/Logo - MoverMail.png';
import HailTraceLogo from 'assets/images/hail-trace-logo.svg';
import LeadRevealLogo from 'assets/images/Logo - LeadReveal.png';
import EnterpirseIcon from 'assets/icons/enterprise';
import IntegrationOrderModal from 'components/integration-order-modal';
import useDataSourcesService from 'services/data-sources';

const NewAutomationModal: React.FC = () => {
    const navigate = useNavigate();

    const apiKeyType = { value: 1, description: 'Zapier' };
    
    const { setTemplateFlow } = useGlobal()
    const { getAllDataSources } = useDataSourcesService();

    const { showModal, setTierToUpgrade } = useSelfManagementPlanModal();

    const { getApiKeys } = ApiKeyService()
    const { toggleModal, modalState, setStepToRender } = useAutomationContext();

    const [template, setTemplate] = useState<ITemplate>();
    const [showCreateNewIntegrationOrderModal, setshowCreateNewIntegrationOrderModal] = useState<boolean>(false);

    const { data: zapierAPIs } = useQuery({
        queryKey: ["apiKeys", apiKeyType.value],
        queryFn: () => getApiKeys(apiKeyType.value),
        refetchOnWindowFocus: false,
        enabled: modalState.isNewAutomationModalOpen
    })
    
    const { data } = useQuery({ 
        queryKey: ['getAllDataSources'], 
        queryFn: () => getAllDataSources(), 
        refetchOnWindowFocus: false 
    });

    const [showConfirmationAutomationNameModal, setShowConfirmationAutomationNameModal] = useState(false);

    const [automationName, setAutomationName] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedAutomation, setSelectedAutomation] = useState<IPlatform | null>(null);

    const platforms: IPlatform[] = [
        {
            id: 2,
            displayName: 'Klaviyo',
            logoImageUrl: KlaviyoLogo,
            description: 'Integrate direct mail into your SMS and Email campaigns by leveraging your Klaviyo segments.',
            requires: 'Core Tier',
            isLockedFeature: getUserData()?.customerSubscription?.subscription.name.includes('Starter')
        },
        {
            id: 1,
            displayName: 'Shopify',
            logoImageUrl: ShopifyLogo,
            description: 'Retarget your Shopify customers by segmenting first time buyers, win-back campaigns and more.',
            requires: 'Core Tier',
            isLockedFeature: getUserData()?.customerSubscription?.subscription.name.includes('Starter')
        },
        {
            id: 3,
            displayName: 'Lead Reveal',
            logoImageUrl: LeadRevealLogo,
            description: 'Reveal your website traffic and retarget them using LettrLabs LeadReveal.',
            requires: 'Core Tier',
            isLockedFeature: getUserData()?.customerSubscription?.subscription.name.includes('Starter')
        },
        {
            id: 4,
            displayName: 'Movers',
            logoImageUrl: MoverMailLogo,
            description: 'Automatically target new movers in your area.',
            requires: 'Core Tier',
            isLockedFeature: getUserData()?.customerSubscription?.subscription.name.includes('Starter')
        },
        {
            id: 5,
            displayName: 'Storm Automation',
            logoImageUrl: HailTraceLogo,
            description: 'Automatically target storm-affected homeowners in your service area as soon as a storm happens. Powered by HailTrace.',
            requires: 'Core Tier',
            isLockedFeature: getUserData()?.customerSubscription?.subscription.name.includes('Starter')
        },
        {
            id: 6,
            displayName: 'Open API',
            logoImageUrl: OpenAPILogo,
            description: 'Integrate and automate your sending through the LettrLabs Open API.',
            requires: 'Enterprise Tier',
            isLockedFeature: !getUserData()?.customerSubscription?.subscription.name.includes('Enterprise')
        },
        {
            id: 7,
            displayName: 'Zapier',
            logoImageUrl: ZapierLogo,
            description: "Connect to over 7,000+ apps through Zapier's workflow automation platform.",
            requires: 'Core Tier',
            isLockedFeature: getUserData()?.customerSubscription?.subscription.name.includes('Starter')
        }
    ];

    const filteredPlataforms = platforms.filter((platform: IPlatform) =>
        platform.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleClick = (item: IPlatform) => {
        const allOptions = [ ...data.payload, ...platforms.filter((p: IPlatform) => p.displayName === item.displayName).map(p => ({ ...p, authenticationStatus: 'Authenticated'}))]
        const selectedItem = allOptions.find((p: IPlatform) => p.displayName === item.displayName);

        if(item.displayName === 'Open API' && !isAllowedPermission('OpenAPI')) {
            showModal('upgrade');
            setTierToUpgrade(item.requires ?? '');
            return
        }

        if(item.displayName !== 'Open API' && !isAllowedPermission('DirectMailAutomations')) {
            showModal('upgrade');
            setTierToUpgrade(item.requires ?? '');
            return
        }

        if(!selectedItem) return

        if(item.displayName.toLocaleLowerCase() === 'zapier' && zapierAPIs?.length === 0) {
            handleRedirectToSetup(item);
            return
        };

        if(item.displayName.toLocaleLowerCase() === 'zapier' && zapierAPIs?.length === 0) {
            handleRedirectToSetup(item);
            return
        };
        
        if(item.displayName.toLocaleLowerCase() === 'open api' && zapierAPIs?.length === 0) {
            handleRedirectToSetup(item);
            return
        };
        
        if(item.displayName.toLocaleLowerCase() === 'zapier' && zapierAPIs) handleRedirectToCreate(item);

        if(item.displayName.toLocaleLowerCase() === 'open api' && zapierAPIs) handleRedirectToCreate(item);

        switch(selectedItem.authenticationStatus) {
            case 'Not Authenticated' :
                handleRedirectToSetup(item);
            break;
            case 'Authenticated' :
                handleRedirectToCreate(item);
            break;
            case 'Pending' :
                handleRedirectToSetup(item);
            break;
            default :
                return
        }
    };

    const handleRedirectToCreate = (platform: IPlatform) => {
        setSelectedAutomation(platform);

        switch (platform?.displayName?.toLowerCase()) {
            case 'klaviyo':
                setShowConfirmationAutomationNameModal(true);
            break;
            case 'shopify':
                setShowConfirmationAutomationNameModal(true);
            break;
            case 'movers':
                setShowConfirmationAutomationNameModal(true);
            break;
            case 'lead reveal':
                setShowConfirmationAutomationNameModal(true);
            break;
            case 'zapier':
                handleCreateNewIntegrationOrder()
            break;
            case 'storm automation' :
                handleCreateNewStormAutomation()
            break;
            default:
                return
        }
    }

    const handleRedirectToSetup = (platform: IPlatform) => {
        switch(platform.displayName.toLocaleLowerCase()) {
            case 'klaviyo' :
                setStepToRender(Step.KLAVIYO)
            break;
            case 'shopify' :
                setStepToRender(Step.SHOPIFY)
            break;
            case 'lead reveal' :
                setStepToRender(Step.LEAD_RAVEAL)
            break;
            case 'zapier' :
                setStepToRender(Step.ZAPIER)
            break;
            case 'storm automation' :
                setStepToRender(Step.STORM)
            break;
            case 'open api' :
                setStepToRender(Step.OPENAPI)
            break;
            default:
                setStepToRender(Step.INITIAL)
        }
        setSelectedAutomation(null);
        toggleModal('isSetupModalOpen', true);
        toggleModal('isNewAutomationModalOpen', false);
    }

    const handleCreateNewIntegrationOrder = () => {
        setTemplateFlow({ 
        showModal: true, 
        shouldCheckNotSupported: true,
        originalRoute: '/automations',
        customCreateCallback:() => {
            setTemplate(undefined)
            setshowCreateNewIntegrationOrderModal(true)
        },
        onTemplateChange: (newTemplate) => {
            setTemplate(newTemplate)
            setshowCreateNewIntegrationOrderModal(true)
        }})
    }

    const handleCreateNewStormAutomation = () => {
        navigate('/storm-automation/order');
    }

    const getPlanIcon = (idx: number) => {
        switch (idx) {
            case 5:
                return <EnterpirseIcon />
            default:
                return <CoreIcon />
        }
    }
    
    const handleConfirm = () => {
        if(!automationName) return
        switch(selectedAutomation?.displayName.toLowerCase()) {
            case 'shopify' :
                navigate(`/smart-automation/new?source=1&name=${automationName}`);
            break;
            case 'klaviyo' :
                navigate(`/smart-automation/new?source=2&name=${automationName}`);
            break;
            case 'lead reveal' :
                navigate(`/smart-automation/new?source=3&name=${automationName}`);
            break;
            case 'movers' :
                navigate(`/smart-automation/new?source=4&name=${automationName}`);
            break;
        }
    }

    return (
        <Dialog 
            open={modalState.isNewAutomationModalOpen} 
            maxWidth={'xl'}
            fullWidth
            onClose={() => { toggleModal('isNewAutomationModalOpen', false) }}>

            <DialogContent sx={{ padding: '2rem 4rem 2rem 4rem'}}>

                <Box sx={{ display: 'flex', position: 'relative'}}>
                    <Box />
                    <CloseIcon data-testid="setup-modal-close-btn" sx={{ top: '20px', opacity: .6, position: 'absolute', right: 0}} onClick={() => toggleModal('isNewAutomationModalOpen', false)} />
                </Box>
                
                <Typography variant="h6" sx={{ margin: '1rem 0 0 0', fontSize: 26}}>
                    Create a New Automation
                </Typography>


                <Box mb={6} sx={{ opacity: .6 }}>Choose an automation below and set up your automations in just a few steps.</Box>

                <TextField 
                    placeholder={'Type a automation name to search'} 
                    data-testid="automation-name-input-search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    error={false}
                    sx={{ width: '320px', marginBottom: '2rem' }}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                        ),
                    }}
                />

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, minHeight: '600px'}}>
                    {
                        filteredPlataforms.map((platform: IPlatform, index: number) => (
                            <Box 
                                key={index} 
                                onClick={() => handleClick(platform)}
                                p={2} 
                                sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    width: '271px', 
                                    height: '396px', 
                                    border: '1px solid rgba(0,0,0,.1)', 
                                    transition: 'border 0.15s',
                                    borderRadius: '5px',
                                    '&:hover': {
                                        border: '1px solid #F05A2A',
                                        cursor: 'pointer'
                                    },
                                    '&:hover .plan_and_lock::before': {
                                        width: '100%',
                                        left: '0',
                                    },
                                    '&:hover .text': {
                                        opacity: 1,
                                        transform: 'translateX(0)',
                                    },
                                    }}
                                >
                                {
                                    platform.isLockedFeature && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center',
                                                position: 'relative',
                                            }}
                                            >
                                                <Box
                                                    className="plan_and_lock"
                                                    sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0 10px',
                                                    height: '35px',
                                                    width: '85%',
                                                    borderRadius: '25px',
                                                    overflow: 'hidden',
                                                    position: 'relative',
                                                    transition: 'width 0.3s ease, left 0.3s ease',
                                                    '&:hover::before': {
                                                        width: '100%',
                                                        left: '0',
                                                    },
                                                    '&::before': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: '-100%',
                                                        width: '0%',
                                                        height: '100%',
                                                        backgroundColor: platform.requires === 'Enterprise Tier' ? '#fdf9e6' : '#e1f7fb',
                                                        transition: 'width 0.3s ease, left 0.3s ease',
                                                    },
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', width: 30, zIndex: 10 }}>
                                                        {getPlanIcon(index)}
                                                    </Box>

                                                    <Box
                                                        className="text"
                                                        sx={{
                                                            color: platform.requires === 'Enterprise Tier' ? '#cdaf19' : '#4eb1c5',
                                                            paddingLeft: '10px',
                                                            opacity: 0,
                                                            transform: 'translateX(-20px)',
                                                            transition: 'opacity 0.3s ease, transform 0.3s ease',
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                    {platform.requires}
                                                    </Box>
                                                </Box>
                                        </Box>
                                    )
                                }
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', height: '100px', position: 'relative'}}>
                                    <img data-testid="automation-image" src={platform.logoImageUrl} alt={platform.displayName} width="80%" height="auto"/>
                                </Box>
                                <b data-testid="automation-logo" style={{ fontSize: '20px', padding: '1rem 0 .5rem 0'}}>{platform.displayName}</b>
                                <Box data-testid="automation-description" sx={{ height: '150px'}}>{platform.description}</Box>
                            </Box>
                        ))
                    }

                </Box>
            </DialogContent>

            <Dialog
                maxWidth={'md'}
                open={showConfirmationAutomationNameModal}
                onClose={() => { setShowConfirmationAutomationNameModal(false); setSelectedAutomation(null) }}
            >
                <DialogContent sx={{ padding: '1rem 4rem 2rem 4rem', width: '600px'}}>
                    <Box sx={{ display: 'flex', position: 'relative' }}>
                        <Typography variant="h6" py={4} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                            New {selectedAutomation?.displayName} Automation
                        </Typography>
                        <CloseIcon data-testid="update-status-modal-close-btn" sx={{ opacity: .6, position: 'absolute',top: 30, right: 0}} onClick={() => { setShowConfirmationAutomationNameModal(false); setSelectedAutomation(null)}} />
                    </Box>
                    <Box sx={{ padding: '2rem 0 1rem 0'}}>
                        <Typography variant='paragraphMedium'>What name would you like to use for this automation?</Typography>
                    </Box>
                    <TextField 
                        label="Automation Name"
                        placeholder={'Enter the name for your new automation here.'} 
                        data-testid="automation-name-input-search"
                        onChange={(e) => setAutomationName(e.target.value)}
                        error={false}
                        sx={{ width: '100%', marginBottom: '2rem' }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <MDButton disabled={!automationName} color="primary" sx={{ borderRadius: '5px !important' }} onClick={handleConfirm}>
                            Save
                        </MDButton>
                    </Box>
                </DialogContent>
            </Dialog>
            {
                modalState.isNewAutomationModalOpen && (
                    <IntegrationOrderModal
                        show={showCreateNewIntegrationOrderModal}
                        setShow={setshowCreateNewIntegrationOrderModal}
                        template={template}
                    />
                )
            }
        </Dialog>
    );
}

export default NewAutomationModal;