import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProfileService from 'services/profile';
import MDCard from 'material-ui/components/MDCard';
import AutomationImage from 'assets/images/automation-image.jpg';

const SubscriptionRedirect = () => {
  const { subscriptionName } = useParams();
  const navigate = useNavigate();
  const { getProfile } = ProfileService();

  const { data: profile, isLoading } = useQuery({
    queryKey: ['profile'],
    queryFn: getProfile,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isLoading) return; // Ensure the profile query is finished before proceeding
    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    // Define subscription links for different environments
    const subscriptionLinks: Record<string, Record<string, string>> = {
      dev: {
        'saas-starter-monthly': '',
        'saas-core-monthly': '',
        'saas-premium-monthly': '',
        'saas-core-annual': '',
        'saas-premium-annual': '',
        'saas-enterprise-annual': ''
      },
      uat: {
        'saas-starter-monthly': '',
        'saas-core-monthly': 'https://buy.stripe.com/test_00g29v5Ra6S3aha9AS',
        'saas-premium-monthly': 'https://buy.stripe.com/test_aEUg0l5Ra7W7cpi4gv',
        'saas-core-annual': 'https://buy.stripe.com/test_fZe8xTfrK2BNcpidRa',
        'saas-premium-annual': 'https://buy.stripe.com/test_28o5lH2EY6S3ahaeVf',
        'saas-enterprise-annual': 'https://buy.stripe.com/test_00g29v1AUdgrexq8wS'
      },
      prd: {
        'saas-starter-monthly': '',
        'saas-core-monthly': 'https://buy.stripe.com/eVabJ93lCgrS7LybJx',
        'saas-premium-monthly': 'https://buy.stripe.com/00gcNdcWcdfGaXKbJy',
        'saas-core-annual': 'https://buy.stripe.com/00gfZpaO45Ne4zmcND',
        'saas-premium-annual': 'https://buy.stripe.com/28o6oPaO4grS9TGbJA',
        'saas-enterprise-annual': 'https://buy.stripe.com/28o28z3lC4Jagi4cNF'
      }
    };

    const links = subscriptionLinks[ENVIRONMENT || 'prd']; // Default to 'prd' if ENVIRONMENT is undefined

    if (
      subscriptionName &&
      links[subscriptionName] &&
      profile &&
      profile.email
    ) {
      window.location.href = `${links[subscriptionName]}?prefilled_email=${profile.email}`;
    } else {
      navigate('/home'); // Redirect to home if the subscription name is not found
    }
  }, [subscriptionName, profile, isLoading, navigate]);

  if (isLoading) {
    return (
      <MDCard border={false} boxShadow={false} sx={{ minHeight: '80vh' }}>
        <Box px={4}>
          <Box sx={{ display: 'flex', padding: '4rem 4rem', alignItems: 'center' }}>
            <Box sx={{ width: '70%' }}>
              <Typography variant='h2' fontWeight={'normal'} color={'#000'} lineHeight={'4rem'} marginBottom={'2rem'}>
                Redirecting...
              </Typography>
              <Typography variant='paragraphMedium' sx={{ opacity: .7 }}>
                For your convenience and security, you will be redirected to the Stripe website to complete your payment. Stripe is a trusted and secure platform for processing online transactions. Once your payment is confirmed, you'll be automatically redirected back to LettrLabs to access your services.</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, margin: '1rem 0 1.5rem 0' }}>
                <Typography variant='paragraphMedium' sx={{ opacity: .7 }}>
                  Thank you for choosing LettrLabs!
                </Typography>
                <Box>🚀</Box>
              </Box>
            </Box>
            <Box sx={{ width: '45%' }} data-testid="performance-illustrtion">
              <img src={AutomationImage} alt='Performance Illustration' width={'100%'} />
            </Box>
          </Box>
        </Box>
      </MDCard>
    );
  }

  return (
    <Box
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      color={'#F05A2A'}
    >
      <Typography variant="h3" color={'#130B46'} mb={3} textAlign={'center'} width={'90vw'}>
        {`Redirecting...`}
      </Typography>
    </Box>
  );
};

export default SubscriptionRedirect;
