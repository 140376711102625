import {Grid, styled, TablePaginationProps} from "@mui/material";
import {
    DataGridPro,
    DataGridProProps, GridPagination, useGridApiContext
} from "@mui/x-data-grid-pro";
import MDBox from "material-ui/components/MDBox";
import colors from "material-ui/theme/base/colors";
import borders from "material-ui/theme/base/borders";
import DataGridSpinner from "../datagrid-spinner";
import MuiPagination from '@mui/material/Pagination';

const Styles = styled(DataGridPro)<StyledDataGridProps>(({theme, backgroundColor, disableBorders, noMinWidth}) => ({
    WebkitFontSmoothing: 'auto',
    borderRadius: "12px",
    letterSpacing: 'normal',
    border: `1px solid #E4E6F1 !important`,
    fontSize: 14,
    height: "100%",
    
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    ".MuiDataGrid-detailPanel": {
        backgroundColor: colors.white.main
    },
    '& .MuiDataGrid-iconSeparator': {
        opacity: 0,
    },
    '.MuiDataGrid-menuIcon': {
        'button': {
            color: "white"
        }
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#f9fbfc',
        borderStartStartRadius: "12px",
        borderStartEndRadius: "12px",
        
        '& .MuiDataGrid-columnHeader': {
            '& .MuiDataGrid-sortIcon': {
                color: theme.palette.light.main
            }
        },
        
        '& .MuiDataGrid-columnHeaderTitle': {
            color: 'rgba(0,0,0,.6)'
        }
    },
    '& .MuiDataGrid-row': {
        backgroundColor: backgroundColor? colors[backgroundColor].main : "",

        "&.Mui-selected": {
            backgroundColor: "rgba(240, 90, 42, 0.08) !important"
        },

        ".MuiDataGrid-cell": {
            fontWeight: 500,

            "&.error": {
                backgroundColor: "#ed1c244a"
            },

            "&.warning": {
                backgroundColor: "#F2B20D4A"
            },

            "&:not(:last-child)": {
                borderRight: disableBorders? "none" : "1px solid #E4E6F1",
                borderBottom: disableBorders? "none" : "1px solid #E4E6F1"
            },
            "&:last-child": {
                borderBottom: disableBorders? "none" : "1px solid #E4E6F1"
            }
        },
        "&.even": {
            backgroundColor: "#F3F5FB"
        }
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
            theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
    },
    '& .MuiToolbar-root': {
        paddingRight: "25px",
        justifyContent: "flex-end",
        width: "100%"
    },
    '& .MuiTablePagination-root': {
        width: "100%"
    },
    '& .MuiTablePagination-spacer': {
        display: "none"
    },
    '& .MuiDataGrid-footerContainer': {
        justifyContent: "flex-start"
    },
    '& .MuiTablePagination-displayedRows': {
        fontWeight: 500
    },
    '& .MuiDataGrid-virtualScroller': {
        minHeight: noMinWidth? "unset" : 160
    },
    '& .MuiPagination-ul': {
        'li': {
            '&:first-child': {
                '& .MuiPaginationItem-root': {
                    borderStartStartRadius: borders.borderRadius.md,
                    borderEndStartRadius: borders.borderRadius.md,
                }
            },
            '&:last-child': {
                '& .MuiPaginationItem-root': {
                    borderStartEndRadius: borders.borderRadius.md,
                    borderEndEndRadius: borders.borderRadius.md,
                }
            },

            '& .MuiPaginationItem-ellipsis': {
                padding: "3px 6px"
            },
            '& .MuiPaginationItem-root': {
                fontWeight: 500,
                margin: 0,
                borderRadius: 0,
                border: `1px solid ${colors.grey[400]}`,

                '&.MuiPaginationItem-previousNext': {
                    fontSize: "20px"
                },

                '&.Mui-selected': {
                    backgroundColor: colors.light.main,
                    borderColor: colors.secondary.main,
                    color: colors.text.main
                }
            }
        },
    }

}));

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <MDBox sx={{ mt: 1 }}>No Data</MDBox>
        </StyledGridOverlay>
    );
}

function Pagination({page, onPageChange, className,}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext();

    let totalRows = apiRef.current.getRowsCount()
    let rowsPerPage = apiRef.current.state.pagination.paginationModel.pageSize

    let totalPages = Math.ceil(totalRows / rowsPerPage)

    return (
        <MuiPagination
            size="small"
            shape={"rounded"}
            color="primary"
            className={className}
            count={totalPages}
            page={page + 1}
            onChange={(event: any, newPage: any) => {
                onPageChange(event as any, newPage - 1);
            }}
        />
    );
}

function Footer({allSelected, totalRowCount, loading}: {allSelected?: boolean, totalRowCount?: number, loading?: boolean}){
    // const apiRef = useGridApiContext();

    // let selectedRows = apiRef.current.getSelectedRows()

    // let text = ``
    // if(allSelected){
    //     text = `All rows selected (${totalRowCount} total)`
    // }
    // else{
    //     text = `${selectedRows.size? `${selectedRows.size} row${selectedRows.size > 1 ? 's' : ''} selected` : ''}`
    // }

    return <Grid container alignItems={"center"} justifyContent={"flex-end"} sx={{borderTop: "1px solid #d6d6d6"}}>
        {/* <Grid item pl={2}>
            <MDTypography sx={{fontSize: 16}}>{text}</MDTypography>
        </Grid> */}
        <Grid item>
            {!loading && <GridPagination ActionsComponent={Pagination}/>}
        </Grid>
    </Grid>
}

interface StyledDataGridProps extends DataGridProProps{
    backgroundColor?: "primary" | "secondary" | "white"
    disableBorders?: boolean
    noMinWidth?: boolean
    allSelected?: boolean
}

const StyledDataGrid = (props: StyledDataGridProps) => {
    return <Styles
        {...props}
        density="compact"
        getRowClassName={(params) =>
            !props.backgroundColor && params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        slots={{
            ...props.slots,
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: DataGridSpinner,
            footer: () => <Footer allSelected={props.allSelected} totalRowCount={props.rowCount} loading={props.loading} />
        }}
        initialState={{
            ...props.initialState
        }}
    />
}

export default StyledDataGrid