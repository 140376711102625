import React, { useState } from 'react';
import DeleteIcon from 'assets/icons/delete';
import SettingsIcon from '@mui/icons-material/Settings';

import { Box } from '@mui/system';
import { Tooltip } from '@mui/material';
import { IVM_GridOrder } from 'models/views/gridOrderVm';
import { useNavigate } from 'react-router-dom';
import OrderDetailsModal from 'components/order-details-modal';
import InfoModal from 'components/info-modal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGlobal } from 'context/global-context';
import OrderService from 'services/order';

interface IActions {
    row: IVM_GridOrder
}

const Actions: React.FC<IActions> = ({ row }) => {
    const navigate = useNavigate()
    const { setShowLoader } = useGlobal()
    const { deleteOrder } = OrderService()
    const queryClient = useQueryClient()
    const [showModal, setShowModal] = useState<string>('')
    const handleDeleteAutomation = () => {
        setShowModal('delete')
    }

    const handleEdit = (row: IVM_GridOrder) => {
        navigate(`/order/${row.id}`)
    }

    const { mutate: removeOrder } = useMutation({
        mutationFn: () => deleteOrder(row.id),
        onMutate: () => setShowLoader(true),
        onSuccess: () => {
            setShowLoader(false)
            setShowModal('')
            queryClient.invalidateQueries({ queryKey: ["orders"] })
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title='Edit'>
                    <Box>
                        <SettingsIcon
                            data-testid="automation-edit-btn"
                            onClick={() => handleEdit(row)}
                            sx={{
                                fontSize: '16px !important',
                                width: '26px',
                                height: '26px',
                                color: '#344767',
                                background: '#f3f5fb',
                                borderRadius: '100%',
                                padding: '4px'
                            }}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={'Delete'} placement='bottom'>
                    <Box>
                        <DeleteIcon
                            data-testid="automation-delete-btn"
                            onClick={(event: React.MouseEvent<SVGElement>) => {
                                event.stopPropagation();
                                handleDeleteAutomation();
                            }}
                            sx={{
                                fontSize: '16px !important',
                                width: '26px',
                                height: '26px',
                                color: '#344767',
                                background: '#f3f5fb',
                                borderRadius: '100%',
                                padding: '4px',
                            }}
                        />
                    </Box>
                </Tooltip>
            </Box>
            <OrderDetailsModal show={showModal === 'details'} setShow={() => setShowModal('')} order={row} />
            <InfoModal
                showCancelButton
                showConfirmButton
                show={showModal === 'delete'}
                setShow={() => setShowModal('')}
                headerText={"Are you sure you want to delete this order?"}
                cancelButtonOnClick={() => setShowModal('')}
                confirmButtonOnClick={() => removeOrder()}
            />
        </>


    );
}

export default Actions;