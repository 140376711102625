import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAutomationContext } from 'pages/automations/context';
import { Dialog, TextField, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import CloseIcon from 'assets/icons/close';
import useAutomationsService from 'services/automations';

const UpdateAutomationNameModal: React.FC = () => {
    const { modalState, toggleModal, selectedAutomation, setSelectedAutomation, handleUpdateListAfterSaveName } = useAutomationContext();

    const { enqueueSnackbar } = useSnackbar();
    const { updateAutomationName } = useAutomationsService();

    const [loading, setLoading] = useState(false);
    const [newAutomationName, setNewAutomationName] = useState('');

    const mutation = useMutation({
        mutationFn: () =>
            updateAutomationName(
                selectedAutomation?.id ?? 0,
                selectedAutomation?.integrationOrderType ?? 0,
                newAutomationName
            ),
        onMutate: () => setLoading(true),
        onSuccess: () => {
            handleClose()
            enqueueSnackbar('Automation name successfully updated.', { variant: 'success' });
            handleUpdateListAfterSaveName(selectedAutomation?.id ?? 0, newAutomationName);
        },
        onError: (err) => {
            setLoading(false);
        },
    });
    
    useEffect(() => {
        if(modalState.isUpdateAutomationNameModalOpen) {
            setNewAutomationName(selectedAutomation?.name ?? '');
        }
    }, [modalState.isUpdateAutomationNameModalOpen, selectedAutomation?.name]);
    
    const handleUpdateName = async () => {
        mutation.mutate();
    };
    
    const handleClose = () => {
        setNewAutomationName('');
        setLoading(false);
        setSelectedAutomation(null);
        toggleModal('isUpdateAutomationNameModalOpen', false);
    }

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth
            open={modalState.isUpdateAutomationNameModalOpen}
            onClose={handleClose}
        >
            <Box sx={{ display: 'flex', position: 'relative', padding: '2rem 2rem 0 2rem' }}>
                <Typography
                    variant="h6"
                    mb={2}
                    pb={2}
                    data-testid="automation-updatename-modal-title"
                    sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)' }}
                >
                    Edit Name
                </Typography>
                <CloseIcon
                    data-testid="automation-updatename-modal-close-btn"
                    sx={{ opacity: 0.6, position: 'absolute', right: 28 }}
                    onClick={() => toggleModal('isUpdateAutomationNameModalOpen', false)}
                />
            </Box>
            <Box sx={{ padding: '0 2rem 2rem 2rem', position: 'relative' }}>
                <TextField
                    id="outlined-basic"
                    value={newAutomationName}
                    data-testid="automation-updatename-input"
                    label="Automation Name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setNewAutomationName(event.target.value)
                    }
                    placeholder="Type automation name here"
                    variant="outlined"
                    sx={{ margin: '1rem 0 0 0', width: '100%' }}
                    inputProps={{
                        maxLength: 65,
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0 2rem 2rem 2rem' }}>
                <MDButton
                    data-testid="automation-updatename-modal-save-button"
                    onClick={handleUpdateName}
                    sx={{ borderRadius: '5px !important' }}
                    disabled={loading || newAutomationName === selectedAutomation?.name}
                >
                    Save
                </MDButton>
            </Box>
        </Dialog>
    );
};

export default UpdateAutomationNameModal;