import React, { useEffect, useState } from 'react';

import { IProfile } from 'models/profile';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Dialog, DialogContent } from '@mui/material';
import { getUserData, handleUpdateUserData, isOnboardingDone } from 'helpers/getUserInfos';

import Logo from 'assets/images/logo-blue.svg';
import StepTwo from './components/StepTwo';
import StepOne from './components/StepOne';
import StepZero from './components/StepZero';
import ProfileService from 'services/profile';
import { useSnackbar } from 'notistack';


export interface IOnboardingData {
  firstName: string;
  lastName: string;
  companyName: string;
  annualMailVolumeId: number | null;
  industry: string;
}

const Onboarding: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { finishOboarding } = ProfileService()
  const { enqueueSnackbar } = useSnackbar();

  const [isSaving, setIsSaving] = useState(false);
  const [showGlows, setShowGlows] = useState(false);
  const [isStepTwoVisible, setIsStepTwoVisible] = useState(false);
  const [isStepOneVisible, setIsStepOneVisible] = useState(false);
  const [isStepZeroVisible, setIsStepZeroVisible] = useState(true);

  const [formData, setFormData] = useState<IOnboardingData>({
    firstName: '',
    lastName: '',
    companyName: '',
    annualMailVolumeId: null,
    industry: ''
  });

  useEffect(() => {
    setShowGlows(true);
    setTimeout(() => {
      setIsStepZeroVisible(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!isStepZeroVisible) {
      setTimeout(() => {
        setIsStepOneVisible(true);
      }, 300);
    }
  }, [isStepZeroVisible]);

  useEffect(() => {
    if (isStepOneVisible) {
      setTimeout(() => {
        setShowGlows(false);
      }, 100);
    }
  }, [isStepOneVisible]);

  const handleBack = () => {
    setIsStepOneVisible(true);
    setIsStepTwoVisible(false);
  }

  const handleSave = async () => {
    setIsSaving(true);

    const objEnv: IProfile = {
        ...getUserData(),
        ...formData
    };

    if (!objEnv) {
        setIsSaving(false);
        return;
    }

    try {
        const response = await finishOboarding(objEnv);
        handleUpdateUserData(response);
        if(response?.id) {
          setIsStepZeroVisible(false);
          setIsStepOneVisible(false);
          setIsStepTwoVisible(false);
          navigate('/home')
          enqueueSnackbar('Onboarding successfully completed.', { variant: 'success' } );
        }
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later.', { variant: 'error' } );
    } finally {
      setIsSaving(false);
    }
  };

  const isCheckoutSession = location.pathname.includes("checkout");

  return (
    <Dialog open={(!isOnboardingDone() && !isCheckoutSession)} fullScreen fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          background:
            'linear-gradient(to bottom right, rgba(85, 81, 255, 0.1), rgba(238, 242, 254, 0.1), rgba(255, 202, 185, 0.1))',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <motion.div
          className="purple-glow"
          initial={{ opacity: 0 }}
          animate={{ opacity: showGlows ? 1 : 0 }}
          transition={{ duration: 1.3, ease: 'easeInOut' }}
        />

        <motion.div
          className="orange-glow"
          initial={{ opacity: 0 }}
          animate={{ opacity: showGlows ? 1 : 0 }}
          transition={{ duration: 1.3, ease: 'easeInOut' }}
        />

        <Box
          sx={{
            position: 'relative',
            width: '800px',
            minHeight: '600px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <motion.img
            src={Logo}
            alt="Logo"
            initial={{ left: '50%', top: 100, width: 306, height: 80, transform: 'translateX(-50%)', opacity: '0' }}
            animate={isStepOneVisible ? { left: '145px', width: 180, height: 47, transform: 'translateX(0%)', opacity: '1', top: 0 } : { opacity: showGlows ? 1 : 0 }}
            transition={{ duration: 0.7, ease: 'easeInOut' }}
            style={{
              position: 'absolute',
              zIndex: 10,
            }}
          />

          <AnimatePresence>
            {isStepZeroVisible && (
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <StepZero />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isStepOneVisible && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                style={{
                  position: 'absolute',
                }}
              >
                <StepOne
                  formData={formData}
                  setFormData={(value: IOnboardingData) => setFormData(value)}
                  handleNext={() => { setIsStepOneVisible(false); setIsStepTwoVisible(true)}}
                />
              </motion.div>
            )}
          </AnimatePresence>

          {isStepTwoVisible && (
            <StepTwo 
              isSaving={isSaving} 
              formData={formData} 
              setFormData={(value: IOnboardingData) => setFormData(value)} 
              handleBack={handleBack}
              handleSave={() => handleSave()} />
          )}
        </Box>
      </DialogContent>

      <style>
        {`
          .purple-glow {
            position: absolute;
            left: calc(50% - 500px);
            top: 0;
            height: 389px;
            width: 389px;
            background: rgba(85,81,255,.6);
            filter: blur(100px);
            border-radius: 50%;
          }

          .orange-glow {
            position: absolute;
            right: calc(50% - 500px);
            bottom: 0;
            height: 548px;
            width: 548px;
            background:rgba(255, 202, 185, .6);
            filter: blur(100px);
            border-radius: 50%;
            opacity: 0.4;
          }
        `}
      </style>
    </Dialog>
  );
};

export default Onboarding;
