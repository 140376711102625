import { useEffect, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Alert, Box, Chip, CircularProgress, Divider, Stack, Tooltip, Typography } from '@mui/material';
import {
  FontDownloadOutlined,
  InfoOutlined,
  PaidOutlined,
  ReportOutlined,
  ReportProblemOutlined,
  ScreenRotationAlt,
} from '@mui/icons-material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions, YesNo } from 'newStandard/src/services/order/types';
import { formatPrice } from 'newStandard/src/utils/format';
import { isLettrLabsUser } from 'helpers/profileHelper';
import MDButton from 'material-ui/components/MDButton';
import ProfileService from 'services/profile';

import { checkIsDoubleSidedCharged, errorMessages } from '../../utils/errorHelper';
import { includedCharacters, maxCharacters } from './OverviewPanel.utils';
import { useEditorContext } from '../../contexts/useEditorContext';
import useBlockValidations from '../../hooks/useBlockValidations';
import { checkHasCardBack, checkHasEnvelope } from '../../utils/templateHelper';
import { BlockNames } from '../../utils/sceneEnums';
import CardButton from './CardButton';

interface OverviewPanelProps {
  viewType: 'Card' | 'Envelope';
  setViewType: (viewType: 'Card' | 'Envelope') => void;
}

export default function OverviewPanel({ viewType, setViewType }: OverviewPanelProps) {
  const { getProfile } = ProfileService();
  const { template, changeStep, setMaxStepAllowed, isSaving } = useWorkflowContext();
  const { engine, saveCurrentScene, showCardSide, setShowCardSide } = useEditorContext();
  const { frontErrors, backErrors, backWarnings, envelopeErrors } = useBlockValidations();
  const { data: profile } = useSuspenseQuery({ queryKey: ['profile'], queryFn: getProfile });
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [charCount, setCharCount] = useState<number>(0);

  const includedChars = template.product in includedCharacters ? includedCharacters[template.product] : null;
  const maxChars = template.product in maxCharacters ? maxCharacters[template.product] : null;

  const hasEnvelope = checkHasEnvelope(template.product);
  const hasExtraChars = includedChars && charCount > includedChars;
  const hasDoubleSided = checkIsDoubleSidedCharged(template.product) && template.doubleSided === YesNo.Yes;
  const hasCardBack = checkHasCardBack(template.product);
  const isBifold = template.product === ProductOptions.HandwrittenBiFoldCard;
  const showFrontAddOns = hasExtraChars || (hasDoubleSided && isBifold);

  useEffect(() => {
    const hasErrors = frontErrors.size + (hasCardBack ? backErrors.size : 0) + envelopeErrors.size;
    setMaxStepAllowed((prev) => (hasErrors ? 2 : prev >= 3 ? prev : 3));
  }, [hasCardBack, backErrors.size, envelopeErrors.size, frontErrors.size, setMaxStepAllowed]);

  useEffect(() => {
    if (!engine?.editor) return;
    const unsubscribe = engine.event.subscribe([], () => {
      let newCharCount = 0;
      const textBlocks = [
        ...engine.block.findByName(BlockNames.HandwrittenText),
        ...engine.block.findByName(BlockNames.HandwrittenText2),
      ];
      textBlocks.forEach((textBlock) => (newCharCount += engine.block.getString(textBlock, 'text/text')?.length ?? 0));
      setCharCount((prev) => (newCharCount !== prev ? newCharCount : prev));
    });
    return () => {
      unsubscribe();
    };
  }, [engine]);

  useEffect(() => {
    if (showCardSide === undefined) return;
    handlePageSwitch(showCardSide);
    setShowCardSide(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCardSide, setShowCardSide]);

  const handlePageSwitch = (newPageIndex: 0 | 1) => {
    if (!engine || (viewType === 'Card' && newPageIndex === pageIndex)) return;
    const selectedIds = engine.block.findAllSelected();
    selectedIds.forEach((id) => engine.block.setSelected(id, false));
    const pages = engine.scene.getPages() || [];
    engine.block.setVisible(pages[pageIndex], false);
    engine.block.setVisible(pages[newPageIndex], true);
    setPageIndex(newPageIndex);
    setViewType('Card');
  };

  const handleFlipCard = () => {
    if (viewType === 'Envelope') handlePageSwitch(0);
    else if (pageIndex === 0) handlePageSwitch(1);
    else if (hasEnvelope) setViewType('Envelope');
    else handlePageSwitch(0);
  };

  const renderMessages = (errorType: string) => (
    <Typography key={errorType} fontSize={16} display={'flex'} alignItems={'center'}>
      {errorMessages[errorType].title}
      <Tooltip title={errorMessages[errorType].description}>
        <InfoOutlined sx={{ ml: 0.5, height: 18 }} />
      </Tooltip>
    </Typography>
  );

  if (!engine) return <OverviewPanel.Skeleton />
  return (
    <Stack width={310} p={2} pb={0}>
      {hasCardBack && (
        <>
          <MDButton sx={{ minHeight: 36, height: 36, mt: '0.5px' }} onClick={() => handleFlipCard()}>
            <ScreenRotationAlt sx={{ mr: 1, height: 20 }} />
            Flip Card
          </MDButton>
        </>
      )}
      <Stack spacing={1.5} flex={1} maxHeight={'72vh'} justifyContent={'center'} my={1} overflow={'auto'}>
        <Stack spacing={0.8}>
          <CardButton
            label={isBifold ? 'Card Inside' : 'Card Front'}
            onClick={() => handlePageSwitch(0)}
            isSelected={viewType === 'Card' && pageIndex === 0}
            hasQrCode={template.useQr && template.qrCodeSide === 'Front'}
            hasCallTracking={!!(template.trackingPhoneNumber && template.callTrackingSide === 'Front')}
          />
          <Stack spacing={0.8} px={1}>
            {includedChars && (
              <Alert severity="info" icon={false} sx={{ py: 0 }}>
                <Typography variant="paragraphRegular" fontSize={16} display={'flex'} alignItems={'center'}>
                  <FontDownloadOutlined sx={{ mr: 1, height: 20 }} />
                  Characters:
                </Typography>
                <Typography fontSize={16}>
                  {charCount} / {includedChars} ({maxChars} max)
                </Typography>
              </Alert>
            )}
            {showFrontAddOns && (
              <Alert severity="success" icon={false} sx={{ py: 0 }}>
                <Typography variant="paragraphRegular" fontSize={16} color={'darkgreen'} display={'flex'} alignItems={'center'}>
                  <PaidOutlined sx={{ mr: 1, height: 20 }} />
                  Add Ons:
                </Typography>
                <Stack spacing={0.1}>
                  {hasExtraChars && (
                    <Typography fontSize={16} display={'flex'} alignItems={'center'} gap={1}>
                      Extra Characters:
                      <Chip
                        size="small"
                        component="span"
                        label={formatPrice(Math.ceil((charCount - includedChars) / 50) * 0.08)}
                      />
                    </Typography>
                  )}
                  {hasDoubleSided && isBifold && (
                    <Typography fontSize={16} display={'flex'} alignItems={'center'} gap={1}>
                      Double Sided Printing:
                      <Chip
                        size="small"
                        component="span"
                        label={
                          profile.hasFreeDoubleSidedPrinting ? 'FREE' : profile.hasSubscription ? '$0.10' : '$0.20'
                        }
                      />
                    </Typography>
                  )}
                </Stack>
              </Alert>
            )}
            {!!frontErrors.size && (
              <Alert severity="error" icon={false} sx={{ py: 0 }}>
                <Typography variant="paragraphRegular" fontSize={16} color={'darkred'} display={'flex'} alignItems={'center'}>
                  <ReportProblemOutlined sx={{ mr: 1, height: 20 }} />
                  Errors:
                </Typography>
                {Array.from(frontErrors).map(renderMessages)}
              </Alert>
            )}
          </Stack>
        </Stack>

        {hasCardBack && (
          <>
            <Divider />
            <Stack spacing={0.8}>
              <CardButton
                label={isBifold ? 'Card Outside' : 'Card Back'}
                onClick={() => handlePageSwitch(1)}
                isSelected={viewType === 'Card' && pageIndex === 1}
                hasQrCode={template.useQr && template.qrCodeSide === 'Rear'}
                hasCallTracking={!!(template.trackingPhoneNumber && template.callTrackingSide === 'Rear')}
              />
              <Stack spacing={0.8} px={1}>
                {hasDoubleSided && !isBifold && (
                  <Alert severity="success" icon={false} sx={{ py: 0 }}>
                    <Typography variant="paragraphRegular" fontSize={16} color={'darkgreen'} display={'flex'} alignItems={'center'}>
                      <PaidOutlined sx={{ mr: 1, height: 20 }} />
                      Add Ons:
                    </Typography>
                    <Typography fontSize={16} display={'flex'} alignItems={'center'} gap={1}>
                      Double Sided Printing:
                      <Chip
                        size="small"
                        component="span"
                        label={profile.hasFreeDoubleSidedPrinting ? 'FREE' : profile.hasSubscription ? '$0.10' : '$0.20'}
                      />
                    </Typography>
                  </Alert>
                )}
                {!!backErrors.size && (
                  <Alert severity="error" icon={false} sx={{ py: 0 }}>
                    <Typography variant="paragraphRegular" fontSize={16} color={'darkred'} display={'flex'} alignItems={'center'}>
                      <ReportProblemOutlined sx={{ mr: 1, height: 20 }} />
                      Errors:
                    </Typography>
                    {Array.from(backErrors).map(renderMessages)}
                  </Alert>
                )}
                {!!backWarnings.size && (
                  <Alert severity="warning" icon={false} sx={{ py: 0 }}>
                    <Typography variant="paragraphRegular" fontSize={16} color={'goldenrod'} display={'flex'} alignItems={'center'}>
                      <ReportOutlined sx={{ mr: 1, height: 22 }} />
                      Warnings:
                    </Typography>
                    {Array.from(backWarnings).map(renderMessages)}
                  </Alert>
                )}
              </Stack>
            </Stack>
          </>
        )}
        {hasEnvelope && (
          <>
            <Divider />
            <Stack spacing={0.8}>
              <CardButton
                label="Envelope"
                onClick={() => setViewType('Envelope')}
                isSelected={viewType === 'Envelope'}
              />
              <Stack spacing={0.8} px={1}>
                {!!envelopeErrors.size && (
                  <Alert severity="error" icon={false} sx={{ py: 0 }}>
                    <Typography variant="paragraphRegular" fontSize={16} color={'darkred'} display={'flex'} alignItems={'center'}>
                      <ReportProblemOutlined sx={{ mr: 1, height: 20 }} />
                      Errors:
                    </Typography>
                    {Array.from(envelopeErrors).map(renderMessages)}
                  </Alert>
                )}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>

      {isLettrLabsUser(profile) && (
        <MDButton sx={{ mb: 1 }} fullWidth disabled={isSaving} color="primary" variant='transparent' onClick={() => saveCurrentScene()}>
          Save Draft
        </MDButton>
      )}
      <Box display={'flex'} gap={1}>
        <MDButton fullWidth disabled={isSaving} color="light" onClick={() => changeStep(1)}>
          Back
        </MDButton>
        <MDButton
          fullWidth
          onClick={() => saveCurrentScene()}
          disabled={frontErrors.size + (hasCardBack ? backErrors.size : 0 ) + envelopeErrors.size > 0 || isSaving}
        >
          {isSaving ? <CircularProgress size={18} color="inherit" /> : 'Next'}
        </MDButton>
      </Box>
    </Stack>
  );
}

OverviewPanel.Skeleton = function OverviewPanelSkeleton() {
  return (
    <Stack>
      <Stack width={280} spacing={1.5} p={2} pb={0} height={'100%'} justifyContent={'center'}>
        <CardButton label="Card Front" isSelected onClick={() => {}} />
        <Divider sx={{ m: 0 }} />
        <CardButton label="Card Back" onClick={() => {}} />
      </Stack>
      <Box display={'flex'} gap={1}>
        <MDButton fullWidth disabled color="light">
          Back
        </MDButton>
        <MDButton fullWidth disabled>
          Next
        </MDButton>
      </Box>
    </Stack>
  );
};
