import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

const SkeletonForm = () => {
  return (
    <Box sx={{ width: '1236px', margin: '0 auto'}}>
      {/* Step 1: Template */}
      <Grid container gap={3} py={1} width={600}>
        <Skeleton variant="circular" width={40} height={40} />
        <Divider orientation="vertical" flexItem light />
        <Grid item flex={1}>
          <Typography variant="h6" pb={2}>
            <Skeleton width={100} />
          </Typography>
          <Skeleton variant="rectangular" height={120} />
        </Grid>
      </Grid>

      <Divider light />

      {/* Step 2: Segment and Store */}
      <Grid container gap={3} py={1}>
        <Skeleton variant="circular" width={40} height={40} />
        <Divider orientation="vertical" flexItem light />
        <Grid item container flexDirection="column" flex={1} gap={2}>
          <Skeleton width={150} />
          <Skeleton variant="rectangular" width={300} height={40} />
          <Skeleton width={150} />
          <Skeleton variant="rectangular" width={300} height={40} />
        </Grid>
      </Grid>

      <Divider light />

      {/* Step 3: Postage Type */}
      <Grid container gap={3} py={1} width={300}>
        <Skeleton variant="circular" width={40} height={40} />
        <Divider orientation="vertical" flexItem light />
        <Grid item flex={1}>
          <Typography variant="h6" pb={2}>
            <Skeleton width={150} />
          </Typography>
          <Box>
            <Skeleton variant="rectangular" height={40} />
          </Box>
          <Box mt={1}>
            <Skeleton variant="rectangular" height={40} />
          </Box>
        </Grid>
      </Grid>

      <Divider light />

      {/* Step 4: Sending Options */}
      <Grid container gap={3} py={1}>
        <Skeleton variant="circular" width={40} height={40} />
        <Divider orientation="vertical" flexItem light />
        <Grid item flex={1}>
          <Typography variant="h6" pb={1}>
            <Skeleton width={150} />
          </Typography>
          <Box>
            <Skeleton width={200} />
          </Box>
          <Skeleton variant="rectangular" width={100} height={40} />
        </Grid>
      </Grid>

      <Divider light />

      {/* Step 5: Preview Matches Count */}
      <Grid container gap={3} py={1}>
        <Skeleton variant="circular" width={40} height={40} />
        <Divider orientation="vertical" flexItem light />
        <Grid item flex={1}>
          <Typography variant="h6" pb={1}>
            <Skeleton width={200} />
          </Typography>
          <Typography>
            <Skeleton width={300} />
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Skeleton variant="rectangular" width={100} height={40} />
          </Box>
        </Grid>
      </Grid>

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} marginTop={2} justifyContent="flex-end">
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
      </Stack>
    </Box>
  );
};

export default SkeletonForm;
