import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { useGlobal } from 'context/global-context';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from 'helpers/formatters';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import { PaymentMethod, loadStripe } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IMoverAutomation, ISmartAutomationPayload } from 'services/smart-automation/smart-automation.types';
import { DialogContent, DialogTitle, Dialog, Typography, Box, FormControl, FormLabel, Grid, } from '@mui/material';

import Loader from 'components/loader';
import MDButton from 'material-ui/components/MDButton';
import PaymentManager from 'newStandard/src/components/PaymentManager';
import CheckoutSummary from 'components/checkout-summary';
import useSmartAutomationService from 'services/smart-automation';

interface MoversEnableProps {
  open: boolean;
  payload?: ISmartAutomationPayload;
  setOpen: (open: boolean) => void;
}

const MoversEnable = ({ open, payload, setOpen }: MoversEnableProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { setShowLoader } = useGlobal();
  const { getMoversCheckout, enableMoversAutomation } = useSmartAutomationService();
  
  const [selectedCard, setSelectedCard] = useState<PaymentMethod | null>();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');
  const automation: IMoverAutomation = payload?.payload
    ? { ...convertKeysToCamelCase(JSON.parse(payload.payload)) }
    : {};

  const { mutate: enableAutomation } = useMutation({
    mutationFn: enableMoversAutomation,
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allSmartAutomations', 'allAutomations'] });
      navigate('/automations');
      setShowLoader(false);
    },
  });

  const { data, mutate: getCheckout, isPending } = useMutation({ mutationFn: getMoversCheckout });
  const checkout = data?.payload;
  const hasSavedPaymentMethod = !!checkout?.savedPaymentMethods?.length;
  const isCheckout = automation?.filter?.lookBackMonths && checkout;
  const buttonText = `Enable Automation${isCheckout ? ' & Checkout' : ''}`;
  const showPaymentMethod = !hasSavedPaymentMethod || (isCheckout && !(hasSavedPaymentMethod && !checkout.balance))

  useEffect(() => {
    if (payload?.id) getCheckout(payload);
  }, [getCheckout, payload]);

  useEffect(() => {
    if (hasSavedPaymentMethod) setSelectedCard(checkout.savedPaymentMethods[0]);
  }, [checkout?.savedPaymentMethods, hasSavedPaymentMethod]);

  const submit = () => {
    enableAutomation({ ...payload!, paymentMethodId: selectedCard?.id });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
      <DialogContent>
        <DialogTitle>
          <Typography variant="h6" fontSize={24}>
            MoverMail Automation
          </Typography>
        </DialogTitle>
        {isPending ? (
          <Box display="flex" justifyContent="center">
            <Loader />
          </Box>
        ) : (
          <>
            {isCheckout && (
              <Typography variant="h6" mb={2} mt={3}>
                Estimates for the Next Months
              </Typography>
            )}
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs>
                <Typography variant="paragraphRegular" fontSize={16} textAlign={'center'}>
                  Average
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="paragraphRegular" fontSize={16} textAlign={'center'}>
                  Maximum
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="paragraphRegular" fontSize={16}>
                  Cards Mailed{' '}
                  <Typography component="span" variant="paragraphRegular" fontSize={16} fontWeight={'normal'}>
                    (Per Month)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.monthlyAverageMovers ?? '-'}
              </Grid>
              <Grid item xs textAlign={'center'}>
                {(automation?.sendingOptions?.maxNumberCardsSentPerMonth && automation?.sendingOptions?.maxNumberCardsSentPerMonth > 0) ? automation?.sendingOptions?.maxNumberCardsSentPerMonth : 'No Limit'}
              </Grid>
            </Grid>
            <Grid container mb={1}>
              <Grid item xs={6}>
                <Typography variant="paragraphRegular" fontSize={16}>
                  Cost{' '}
                  <Typography component="span" variant="paragraphRegular" fontSize={16} fontWeight={'normal'}>
                    (Per Month)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.monthlyAverageEstimatedCost ? formatPrice(checkout?.monthlyAverageEstimatedCost) : '-'}
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.maxEstimatedCost
                  ? formatPrice(checkout.maxEstimatedCost)
                  : 'No Limit'}
              </Grid>
            </Grid>
            <Typography variant="paragraphRegular" fontSize={16}>
              Please Note:
            </Typography>
            <Box component={'ul'} ml={3} mb={2}>
              <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
                Existing credits will be used before charging your default payment method
              </Typography>
              <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
                This automation can be paused at any time, but cannot be edited once it has been enabled
              </Typography>
            </Box>

            {isCheckout && (
              <Box my={3}>
                <Typography variant="h6" mb={2}>
                  Lookback Period Checkout
                </Typography>
                <CheckoutSummary checkout={checkout} />
              </Box>
            )}
            {showPaymentMethod && (
              <>
                <FormControl fullWidth>
                  <FormLabel id="payment-method-label">
                    <Typography variant="h6" fontSize={18} mb={1}>
                      Choose a payment method
                    </Typography>
                  </FormLabel>
                </FormControl>
                <Elements stripe={stripePromise}>
                  <PaymentManager data={null} handleSelectedPayment={(value) => setSelectedCard(value)} />
                </Elements>
              </>
            )}
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <MDButton fullWidth onClick={() => submit()}>
                {buttonText}
              </MDButton>
              <MDButton color="light" onClick={() => setOpen(false)}>
                Cancel
              </MDButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MoversEnable;
