import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';

import Visa from 'assets/images/card-brands/visa.svg';
import MDRadio from 'material-ui/components/MDRadio';
import Mastercard from 'assets/images/card-brands/mastercard.svg';

interface ICreditCardList {
    loadingProfilePayments: boolean,
    userPaymentMethods: PaymentMethod[],
    selectedPaymentMethod: PaymentMethod | null,
    handleSelectPaymentMethod: (id: PaymentMethod) => void
}

const CreditCardList: React.FC<ICreditCardList> = ({ loadingProfilePayments, userPaymentMethods, selectedPaymentMethod, handleSelectPaymentMethod}) => {

    const getCreditCardBrandToRender = (brand: string) => {
        switch (brand?.toLowerCase()) {
            case 'visa':
                return <Box component="img" src={Visa} width="50px" mr={'15px'} />;
            case 'master':
            case 'mastercard':
                return <Box component="img" src={Mastercard} width="50px" mr={'15px'} />;
            default:
                return <Box component="img" src={Visa} width="50px"  mr={'15px'}/>;
        }
    };

    return (
        <React.Fragment>
            {!loadingProfilePayments &&
                Array.isArray(userPaymentMethods) &&
                userPaymentMethods.map((p: PaymentMethod) => (
                    <Box
                        key={p.id}
                        sx={{
                            padding: '.5rem 1rem',
                            margin: '1rem 0',
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            height: '70px',
                            gap: '5px',
                            position: 'relative',
                            background: 'linear-gradient(0deg, #F5F5F5, #FFFFFF)',
                            boxShadow: '-1px 2px 15px -7px rgba(0, 0, 0, 0.28)',
                        }}
                    >   
                        <MDRadio
                            value={p.id}
                            checked={p.id === selectedPaymentMethod?.id}
                            onChange={() => handleSelectPaymentMethod(p)}
                        />
                        {getCreditCardBrandToRender(p?.card?.brand ?? '')}
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="paragraphRegular">
                                Credit Card ending in {p?.card?.last4}
                            </Typography>
                            <Typography variant="paragraphRegular" sx={{ padding: '.25rem 0', opacity: 0.8 }}>
                                Valid Thru{' '}
                                {p?.card?.exp_month && p?.card?.exp_month < 10
                                    ? '0' + p?.card?.exp_month
                                    : p?.card?.exp_month}
                                /{p?.card?.exp_year}
                            </Typography>
                        </Box>
                    </Box>
                )
            )}
        </React.Fragment>
    );
}

export default CreditCardList;