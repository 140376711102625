import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Step } from 'pages/automations/context/types';
import { IPlatform } from 'services/automations/automatios.service.types';
import { useSnackbar } from 'notistack';
import { useAutomationContext } from 'pages/automations/context';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CircularProgress, Dialog, DialogContent, Grid, Typography } from '@mui/material';

import copy from 'copy-to-clipboard';
import MDBox from 'material-ui/components/MDBox';
import MDCard from 'material-ui/components/MDCard';
import MDInput from 'material-ui/components/MDInput';
import MDButton from 'material-ui/components/MDButton';
import CloseIcon from 'assets/icons/close';
import APIKeyIcon from 'assets/images/gold-key.png';
import ZapierTable from './components/Table';
import MDTypography from 'material-ui/components/MDTypography';
import ApiKeyService from 'services/api-key-service';

interface IZapierInstructions {
  platform: IPlatform
}

const ManageZapier: React.FC<IZapierInstructions> = ({ platform }) => {
  const queryClient = useQueryClient();

  const apiKeyType = { value: 1, description: 'Zapier' };
 
  const { enqueueSnackbar } = useSnackbar();
  const { setAutomationToSetup, setStepToRender } = useAutomationContext();
  const {getApiKeys, createApiKey, deleteApiKey} = ApiKeyService()
  const [isSaving, setIsSaving] = useState(false);
  const [apiKeyName, setApiKeyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [generatedAPIKey, setGeneratedAPIKey] = useState('');
  const [keyToDelete, setKeyToDelete] = useState<number | null>();
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [showNewAPIKeyInfoModal, setShowNewAPIKeyInfoModal] = useState(false);
  const { data, isLoading: isLoadingAPIKeys, isFetching } = useQuery({
    queryKey: ["zapierKeys", apiKeyType.value],
    queryFn: () => getApiKeys(apiKeyType.value)
  })

  const postApiKeyMutation = useMutation({
      mutationFn: () => {
          return createApiKey(apiKeyName, apiKeyType.value)
      },
      onMutate: () => {
        setIsSaving(true)
      },
      onSuccess: (data: any, variables, context) => {
        setApiKeyName('');
        setIsSaving(false);
        setGeneratedAPIKey(data.key);
        setShowNewAPIKeyInfoModal(true);
        queryClient.invalidateQueries({queryKey: ["zapierKeys"]})
      }
  })

  const deleteApiKeyMutation = useMutation({
      mutationFn: () => {
        return deleteApiKey(keyToDelete ?? 0);
      },
      onMutate: () => {
        setIsLoading(true);
      },
      onSuccess: (data: any, variables, context) => {
        setIsLoading(false);
        setShowNewAPIKeyInfoModal(false);
        setShowConfirmRemove(false);
        queryClient.invalidateQueries({queryKey: ["zapierKeys"]});
        queryClient.setQueryData(["zapierKeys"], (oldData: any) => {
          return oldData.filter((item: any) => item.id !== keyToDelete);
        });
        enqueueSnackbar('API key successfully removed!', { variant: 'success' });
      }
  })

  const handleDeleteAPIKey = () => {
    deleteApiKeyMutation.mutate();
  }
  const copyToClipboard = () => {
    copy(generatedAPIKey)
    setIsCopied(true)
  }

  useEffect(() => {
    if(data?.length === 0) {
      setStepToRender(Step.ZAPIER)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <DialogContent sx={{ padding: '2rem 6rem', minHeight: '830px', position: 'relative'}}>
      <Typography variant="h6" sx={{ margin: '1rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
        Zapier Automation
      </Typography>
      <CloseIcon data-testid="setup-modal-close-btn" sx={{ top: '3rem', opacity: .6, position: 'absolute', right: '6rem'}} onClick={() => { setStepToRender(Step.INITIAL); setAutomationToSetup('') }} />

      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '3rem 0 2rem 0'}}>
        <img style={{ marginLeft: '-30px'}} width={'180px'} src='https://res.cloudinary.com/zapier-media/image/upload/v1666030611/Contact%20Sales/logo_ptzjx8.png' alt="Zapier" />
      </Box>

      <Box sx={{ display: 'flex', height: '400px', padding: '1rem 0 0 0', width: '100%'}} mt={2}>
        <Box sx={{ padding: '0 0 2rem 0', width: '100%'}}>
          <Typography variant='paragraphMedium'>
            Manage your Zapier integration keys efficiently and securely. View, create, or delete keys to streamline workflows while maintaining full control and organization.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, marginTop: '2rem' }}>
            <MDInput
                fullWidth={true}
                value={apiKeyName}
                onChange={(e: any) => {
                  setApiKeyName(e.target.value);
                }}
                label={"Description*"}
                helperText="Please name your API Key, this will help you identify it later"
            />
            <MDButton
              sx={{ height: '40px', width: '180px'}}
              color={"primary"}
              disabled={!apiKeyName || isSaving}
              onClick={() => {
                postApiKeyMutation.mutate();
              }}
            >
              { isSaving ? <CircularProgress size={15} color='inherit' /> : 'Generate Key'}
            </MDButton>
          </Box>
          <Box sx={{ margin: '2rem 0', paddingBottom: '4rem'}}>
          <ZapierTable data={data ?? []} isLoading={isLoadingAPIKeys || isFetching || isLoading} handleDeleteAPIKey={(apiKeyId: number) => { setKeyToDelete(apiKeyId); setShowConfirmRemove(true)}}/>          </Box>
        </Box>
      </Box>

      <Dialog open={showNewAPIKeyInfoModal} onClose={() => setShowNewAPIKeyInfoModal(false)} maxWidth={'md'} fullWidth >
        <DialogContent>
            <Box sx={{ display: 'flex', position: 'relative' }}>
                <Typography variant="h6" pt={2} pb={4} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                  Your New API Key
                </Typography>
                <CloseIcon data-testid="update-status-modal-close-btn" sx={{ opacity: .6, position: 'absolute',top:'15px', right: 0}} onClick={() => { setShowNewAPIKeyInfoModal(false); }} />
            </Box>
            <Box sx={{display: 'flex', padding: '2rem 0'}}>
              <Box sx={{ width: '60%' }}>
                <Typography variant='paragraphMedium' fontWeight={500}>All set!</Typography>
                <Typography variant='paragraphMedium'>Your new API Key has been successfully created and is< br/> ready to use.</Typography>
                <Box>
                  <Typography variant='paragraphMedium' marginTop={4} marginBottom={2} fontWeight={500}>It’s important to remember:</Typography>
                  <Typography variant='paragraphMedium' sx={{ display: 'flex', gap: 1, fontSize: '14px', marginTop: '1rem'}}>
                    <Box mt={'7px'} minWidth={7} height={7} bgcolor={'red'} borderRadius={'100%'} />Please copy and save your API key below. Do not share this key with anyone.
                  </Typography>
                  <Typography variant='paragraphMedium' sx={{ display: 'flex', gap: 1, fontSize: '14px', marginTop: '.5rem'}}>
                    <Box mt={'7px'} minWidth={7} height={7} bgcolor={'red'} borderRadius={'100%'} />For security reasons, your API key can only be viewed once. Once you exit this screen, you will not be able to retrieve it. Be sure to store it in a safe place.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40%' }}>
                <img src={APIKeyIcon} alt='API Key Icon' width={'150px'} height={'150px'} />
              </Box>
            </Box>
            <MDBox>
              <MDCard border={true} borderColor={"light"} borderRadiusSize={"xl"}>
                  <Grid container p={1} alignItems={"center"} justifyContent={"space-between"} gap={1}>
                      <Grid item>
                          <MDTypography variant={"paragraphRegular"} color={"secondary"} fontWeight={"normal"}>{generatedAPIKey}</MDTypography>
                      </Grid>
                      <Grid item>
                          <MDButton color={"primary"} onClick={copyToClipboard}>{isCopied? "Copied!" : "Copy to Clipboard"}</MDButton>
                      </Grid>
                  </Grid>
              </MDCard>
          </MDBox>
        </DialogContent>
      </Dialog>

      <Dialog open={showConfirmRemove} onClose={() => setShowConfirmRemove(false)} maxWidth={'md'} fullWidth >
        <DialogContent>
            <Box sx={{ display: 'flex',position: 'relative', marginBottom: '2rem' }}>
                <Typography variant="h6" pt={2} pb={4} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                  Confirm Action
                </Typography>
                <CloseIcon data-testid="update-status-modal-close-btn" sx={{ opacity: .6, position: 'absolute',top:'15px', right: 0}} onClick={() => { setShowConfirmRemove(false); }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='paragraphMedium' fontWeight={500} mb={1}>
                Are you sure you want to delete your API key?
              </Typography>
              <Typography variant='paragraphMedium'>
                This will invalidate any Zaps that are dependent on it.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, margin: '2rem 0 0 0'}}>
                <MDButton color="light" sx={{ borderRadius: '5px !important' }} onClick={() => setShowConfirmRemove(false)}>
                    Cancel
                </MDButton>
                <MDButton color="primary" sx={{ borderRadius: '5px !important' }} onClick={handleDeleteAPIKey} disabled={isLoading}>
                    { isLoading ? <CircularProgress size={15} color='inherit' /> : 'Confirm'}
                </MDButton>
            </Box>
        </DialogContent>
      </Dialog>

    </DialogContent>
  );
}

export default ManageZapier;