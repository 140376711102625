import { Button, styled, Typography } from '@mui/material';
import colors from "material-ui/theme/base/colors";
import './index.scss';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const { text } = colors;

const SplashScreen = () => {

    const navigate = useNavigate()

    const CreateCampaignButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 12,
        display: 'flex',
        gap: 10,
        padding: '6px 12px',
        color: '#FFF',
        width: 'fit-content',
        lineHeight: 1.5,
        backgroundColor: '#5551ff',
        fontFamily: "'Segoe UI', sans-serif",
        '&:hover': {
            backgroundColor: '#5551ff',
            color: '#fff',
            boxShadow: 'none',
        },
    });

    const goToOrder = () => {
        navigate(`/orders`)
    }

    return (
        <div className='full-container'>
            <div className='container'>
                <div className='left-container'>
                    <Typography variant={'h6'} color={text.purple}>
                        It seems like you don't have any orders yet
                    </Typography>
                    <Typography variant={'paragraphRegular'}>
                        Sending cards is easier than ever!
                    </Typography>
                    <CreateCampaignButton onClick={ goToOrder }>
                        <Add />
                        Create Campaign Now
                    </CreateCampaignButton>
                </div>
                <div className='right-container'>
                    <div className='container-box container-one'>
                    </div>
                    <div className='container-box container-two'>
                        <div className='container-action-one'></div>
                    </div>
                    <div className='container-box container-three'>
                        <div className='container-action-two'></div>
                    </div>
                    <div className='container-box container-four'>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SplashScreen