import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import CreativeEditorSDK, { AssetResult, ConfigTypes } from '@cesdk/cesdk-js';

import { ITemplateOrderIntersection, useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions, YesNo } from 'newStandard/src/services/order/types';
import useTemplateService from 'newStandard/src/services/template';
import ProfileArtifactService from 'services/profile-artifact';
import { stretchImageToFullBleed } from 'helpers/ImgLyHelper';
import PremadeDesignService from 'services/premade-design';
import { isLettrLabsAdminUser } from "helpers/profileHelper";

import {
  advancedDockOrder,
  advancedInspectorBar,
  basicInspectorBar,
  basicOrderConfig,
  cardConfig,
  textModeCanvasMenuOrder,
} from '../utils/defaultConfig';

import {
  adminAdvancedDockOrder,
  adminAdvancedInspectorBar,
  adminBasicInspectorBar,
  adminBasicOrderConfig,
  adminCardConfig,
  adminTextModeCanvasMenuOrder,
} from '../utils/adminDefaultConfig';

import { useEditorContext } from '../contexts/useEditorContext';
import { checkHasEnvelope } from '../utils/templateHelper';
import { ArtifactType } from '../services/artifact/types';
import useArtifactService from '../services/artifact';
import useFontService from '../../../services/font';
import { createImage } from '../utils/imageHelper';
import { BlockNames } from '../utils/sceneEnums';
import ProfileService from 'services/profile';

export default function useCreateCardEditor(cesdkRef: React.MutableRefObject<HTMLDivElement | null>) {
  const { template, setTemplate } = useWorkflowContext();

  const {
    engine,
    setEngine,
    setLettrLabsPanel,
    setReturnAddressPanel,
    setShowCardSide,
    setIsSceneLoading,
    setIsApplyingTemplateId,
    setCesdk
  } = useEditorContext();

  const { getFonts } = useFontService();
  const { getPastOrderArtifacts, uploadOrderArtifact } = useArtifactService();
  const { getProfileArtifacts } = ProfileArtifactService();
  const { getProfile } = ProfileService();
  const { getPremadeDesigns } = PremadeDesignService();
  const { getTemplates } = useTemplateService();

  const { data: profile, isFetched: isProfileFetched } = useQuery({ queryKey: ['profile'], queryFn: getProfile, refetchOnWindowFocus: false });

  const { data: fonts } = useQuery({ queryKey: ['getFonts'], queryFn: getFonts });

  const { data: pastImages, isFetched: isPastImagesFetched } = useQuery({
    queryKey: ['getPastOrderArtifacts'],
    queryFn: getPastOrderArtifacts,
  });

  const { data: { payload: brandImages } = {}, isFetched: isBrandImagesFetched } = useQuery({
    queryKey: ['profileArtifacts'],
    queryFn: getProfileArtifacts,
  });

  const { data: premadeDesigns, isFetched: isPremadeDesignsFetched } = useQuery({
    queryKey: ['getPremadeDesigns'],
    queryFn: getPremadeDesigns,
  });

  const { data: templates, isFetched: isTemplatesFetched } = useQuery({
    queryKey: ['templates'],
    queryFn: getTemplates,
  });

  useEffect(() => {
    if (
      !cesdkRef?.current ||
      !fonts?.length ||
      !isPastImagesFetched ||
      !isBrandImagesFetched ||
      !isPremadeDesignsFetched ||
      !isTemplatesFetched ||
      !isProfileFetched
    ) {
      return;
    }

    const isAdmin = isLettrLabsAdminUser(profile);

    const uploadImageCallback: ConfigTypes.OnUploadCallback = async (file: File) => {
      const maxSizeBytes = 25 * 1024 * 1024; // 25 MB in bytes
      if (file.size >= maxSizeBytes) return Promise.reject(new Error('File size exceeds the limit of 25 MB'));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('artifactType', ArtifactType.Image);
      const image = await uploadOrderArtifact(template.id, formData);
      return Promise.resolve({ id: image.id.toString(), meta: { uri: image.blobUri, thumbUri: image.blobUri } });
    };

    const configToUse = isAdmin
      ? adminCardConfig(uploadImageCallback)
      : cardConfig(uploadImageCallback);

    let cleanedUp = false;
    let instance: CreativeEditorSDK;
    CreativeEditorSDK.create(cesdkRef.current, configToUse).then(async (cesdk) => {
      instance = cesdk;
      if (cleanedUp) return instance.dispose();
      setCesdk(instance);
      await instance.addDefaultAssetSources();
      await instance.addDemoAssetSources({ sceneMode: 'Design' });
      instance.disableNoSceneWarning();

      instance.engine.editor.setSettingBool('page/title/show', false);
      instance.ui.updateAssetLibraryEntry('ly.img.template', { sourceIds: ['my.templates'] });
      instance.ui.updateAssetLibraryEntry('ly.img.image', {
        sourceIds: ['ly.img.image.upload', 'brand.images'],
        canRemove: false,
        sortBy: {
          // Tells the SDK how to pick which field to sort on
          // Use `meta.createdAt` since we stored the date in meta
          sortKey: 'id',
          // or just 'createdAt' if that’s what your data has

          // ascending or descending
          sortingOrder: "Descending"
        }
      });
      instance.ui.addAssetLibraryEntry({ id: 'card.designs', sourceIds: premadeDesigns?.allCategories ?? [] });
      instance.ui.setReplaceAssetLibraryEntries((context) => {
        const { selectedBlocks, defaultEntryIds } = context;
        if (selectedBlocks.length !== 1) return [];
        const { id } = selectedBlocks[0];
        const name = instance.engine.block.getName(id);
        if (name !== BlockNames.RearImage && name !== BlockNames.OutsideFrontImage) return defaultEntryIds;
        return [...defaultEntryIds, 'card.designs'];
      });

      if (isAdmin) {
        cesdk.ui.setDockOrder(adminBasicOrderConfig);
        cesdk.ui.setInspectorBarOrder(adminBasicInspectorBar);
        cesdk.ui.setCanvasMenuOrder(adminTextModeCanvasMenuOrder, { editMode: 'Text' });
      } else {
        cesdk.ui.setDockOrder(basicOrderConfig);
        cesdk.ui.setInspectorBarOrder(basicInspectorBar);
        cesdk.ui.setCanvasMenuOrder(textModeCanvasMenuOrder, { editMode: 'Text' });
      }

      instance.engine.variable.setString('lettrLabs', 'true');
      instance.engine.variable.setString('firstAccess', 'true');
      instance.engine.variable.setString('returnAddress', 'false');

      // * Add assets
      pastImages?.forEach((image, i) => {
        const img = new Image();
        img.src = image.blobUri;
        img.onload = () => {
          instance.engine.asset?.addAssetToSource('ly.img.image.upload', {
            id: image.id.toString(),
            label: { en: image.fileName ?? `pastImage${i}` },
            meta: { thumbUri: image.blobUri, uri: image.blobUri, width: img.width, height: img.height },
          });
        };
      });

      instance.engine.asset?.addLocalSource('brand.images');
      brandImages?.forEach((image) => {
        const img = new Image();
        img.src = image.blobUri;
        img.onload = () => {
          instance.engine.asset?.addAssetToSource('brand.images', {
            id: image.id.toString(),
            label: { en: image.artifactFileName },
            meta: { thumbUri: image.blobUri, uri: image.blobUri, width: img.width, height: img.height },
          });
        };
      });

      // * Register components

      instance.ui.registerComponent('editor.mode', ({ builder: { Button }, engine }) => {
        const currentOrder = instance.ui.getDockOrder();
        const hasElements = currentOrder.find((el) => typeof el !== 'string' && el.key === 'ly.img.elements');
        Button('modeButton', {
          variant: 'regular',
          label: `Change to ${hasElements ? 'Basic' : 'Advanced'}`,
          onClick: () => {
            if (isAdmin) {
              instance.ui.setDockOrder(hasElements ? adminBasicOrderConfig : adminAdvancedDockOrder);
              instance.ui.setInspectorBarOrder(hasElements ? adminBasicInspectorBar : adminAdvancedInspectorBar);
            } else {
              instance.ui.setDockOrder(hasElements ? basicOrderConfig : advancedDockOrder);
              instance.ui.setInspectorBarOrder(hasElements ? basicInspectorBar : advancedInspectorBar);
            }
          },
        });
      });

      instance.ui.registerComponent('img.fullBleed', ({ builder: { Button }, engine }) => {
        const selectedIds = engine.block.findAllSelected();
        if (
          selectedIds.length === 1 &&
          engine.block.getKind(selectedIds[0]).includes('image') &&
          engine.block.getName(selectedIds[0]) !== BlockNames.QRCode
        ) {
          Button('fullBleedButton', {
            label: 'Full Bleed',
            onClick: () => stretchImageToFullBleed(engine, selectedIds[0], template.product),
          });
        }
      });

      instance.ui.registerPanel('myCustomPanelFormat', ({ builder, engine }) => {

        builder.Section('selectedAdam', {
          title: ``,
          children: () => {
          },

        });
      });

      cesdk.setTranslations({
        en: {
          'panel.myCustomPanelFormat': 'Format',
        }
      });

      instance.ui.registerComponent('returnAddress.dock', ({ builder: { Button } }) => {
        if (checkHasEnvelope(template.product)) return;
        const isSelected = instance.engine.variable.getString('returnAddress') === 'true';
        Button('returnAddress.button', {
          label: 'Return Address',
          isSelected,
          onClick: () => {
            if (instance.ui.isPanelOpen('//ly.img.panel/assetLibrary')) {
              instance.ui.closePanel('//ly.img.panel/assetLibrary');
              instance.engine.variable.setString('returnAddress', 'true');
            } else instance.engine.variable.setString('returnAddress', isSelected ? 'false' : 'true');
            instance.engine.variable.setString('lettrLabs', 'false');
          },
        });
      });

      // * Finish editor configuration

      setEngine(instance.engine);
    });

    return () => {
      cleanedUp = true;
      instance?.dispose();
      setEngine(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cesdkRef, fonts, isBrandImagesFetched, isPastImagesFetched, isPremadeDesignsFetched, isTemplatesFetched]);

  useEffect(() => {
    if (!engine?.event) return;
    const unsubscribe = engine.event.subscribe([], () => {
      setReturnAddressPanel(() => {
        const show = engine.variable.getString('returnAddress') === 'true';
        setShowCardSide((prevSide) => (show ? 0 : prevSide));
        return show;
      });
    });
    return () => {
      unsubscribe();
    };
  }, [engine, setLettrLabsPanel, setReturnAddressPanel, setShowCardSide]);

  useEffect(() => {
    if (!engine?.block || !premadeDesigns) return;
    try {
      premadeDesigns?.allCategories?.forEach((c) => {
        engine.asset?.addLocalSource(c, undefined, async (asset) => {
          const rearImages = [
            ...engine.block.findByName(BlockNames.RearImage),
            ...engine.block.findByName(BlockNames.OutsideFrontImage),
          ];
          setShowCardSide(1);
          if (rearImages.length) {
            const fill = engine.block.getFill(rearImages[0]);
            engine.block.setPlaceholderControlsOverlayEnabled(rearImages[0], false);
            engine.block.setPlaceholderBehaviorEnabled(fill, false);
            engine.block.setString(fill, 'fill/image/imageFileURI', asset.meta?.uri ?? '');
            return rearImages[0];
          }
          const isBifold = template.product === ProductOptions.HandwrittenBiFoldCard;
          const rearPage = engine.scene.getPages()[1];
          const pageHeight = engine.block.getHeight(rearPage);
          return createImage(engine, {
            name: isBifold ? BlockNames.OutsideFrontImage : BlockNames.RearImage,
            height: (isBifold ? pageHeight / 2 : pageHeight) + 0.2,
            width: engine.block.getWidth(rearPage) + 0.2,
            positionX: -0.1,
            positionY: isBifold ? 5.2 : -0.1,
            parent: rearPage,
            uri: asset.meta?.uri ?? '',
          });
        });
      });
      premadeDesigns?.designCategories?.forEach((designCategory) => {
        designCategory.files.forEach((file) => {
          engine.asset?.addAssetToSource(designCategory.name, {
            id: file.id.toString(),
            meta: { thumbUri: file.thumbUrl, uri: file.fullSizeUrl },
          });
        });
      });
    } catch (err) {
      console.error('Error fetching premade designs:', err);
    }
  }, [engine, premadeDesigns, template.product, setShowCardSide]);

  useEffect(() => {
    if (!engine?.block || !templates) return;
    try {
      engine.asset.addSource({
        id: 'my.templates',
        findAssets: async ({ query }) => {
          const assets: AssetResult[] = [];
          await templates.forEach((t) => {
            if (t.name?.includes(query ?? '') && t.product === template.product && t.id !== template.id && t.sceneFrontThumb) {
              const { protocol, host } = window.location;
              const random = Math.floor(Math.random() * 1000000000000000);
              const uri = `${protocol}//${host}/api/scene/${t.id}/Card/?${random}`;
              assets.push({
                id: t.id.toString(),
                label: t.name,
                meta: {
                  uri,
                  thumbUri: t.sceneFrontThumb,
                  text: t.text,
                  text2: t.text2,
                  font: t.font,
                  fontSize: t.fontSize,
                  fontSizeReturnAddress: t.fontSizeReturnAddress,
                  useQr: t.useQr,
                  qrUrl: t.qrUrl ?? '',
                  qrCodeY: t.qrCodeY,
                  qrCodeX: t.qrCodeX,
                  qrCodeWidth: t.qrCodeWidth,
                  qrCodeHeight: t.qrCodeHeight,
                  qrAngle: t.qrAngle,
                  trackingPhoneNumber: t.trackingPhoneNumber ?? '',
                  hasDiscount: t.hasDiscount,
                  qrCodeSide: t.qrCodeSide,
                  returnFirstName: t.returnFirstName ?? '',
                  returnLastName: t.returnLastName ?? '',
                  returnOrganization: t.returnOrganization ?? '',
                  returnAddress1: t.returnAddress1 ?? '',
                  returnAddress2: t.returnAddress2 ?? '',
                  returnCity: t.returnCity ?? '',
                  returnState: t.returnState ?? '',
                  returnZip: t.returnZip ?? ''
                },
              });
            }
          });
          return { assets, currentPage: 1, total: assets.length };
        },
        // @ts-ignore
        applyAsset: async (asset: { id: string, meta: ITemplateOrderIntersection & { uri: string } }) => {
          setIsApplyingTemplateId(Number(asset.id));
          setTemplate((prev) => ({
            ...prev,
            text: asset.meta.text.toString(),
            text2: asset.meta.text2.toString(),
            font: asset.meta.font.toString(),
            fontSize: Number(asset.meta.fontSize),
            fontSizeReturnAddress: Number(asset.meta.fontSizeReturnAddress),
            useQr: !!asset.meta.useQr,
            qrUrl: asset.meta.qrUrl?.toString(),
            doubleSided: asset.meta.doubleSided as YesNo,
            noFrontLogo: null,
            qrCodeY: asset.meta.qrCodeY ? Number(asset.meta.qrCodeY) : null,
            qrCodeX: asset.meta.qrCodeX ? Number(asset.meta.qrCodeX) : null,
            qrCodeWidth: asset.meta.qrCodeWidth ? Number(asset.meta.qrCodeWidth) : null,
            qrCodeHeight: asset.meta.qrCodeHeight ? Number(asset.meta.qrCodeHeight) : null,
            qrAngle: asset.meta.qrAngle ? Number(asset.meta.qrAngle) : null,
            trackingPhoneNumber: asset.meta.trackingPhoneNumber ? asset.meta.trackingPhoneNumber.toString() : null,
            hasDiscount: false,
            qrCodeSide: asset.meta.qrCodeSide as 'Rear' | 'Front',
            orderArtifacts: [],
            returnFirstName: asset.meta.returnFirstName.toString(),
            returnLastName: asset.meta.returnLastName.toString(),
            returnOrganization: asset.meta.returnOrganization.toString(),
            returnAddress1: asset.meta.returnAddress1.toString(),
            returnAddress2: asset.meta.returnAddress2.toString(),
            returnCity: asset.meta.returnCity.toString(),
            returnState: asset.meta.returnState.toString(),
            returnZip: asset.meta.returnZip ? asset.meta.returnZip.toString() : null
          }));
          await engine.scene.applyTemplateFromURL(asset.meta.uri).then(() => setIsSceneLoading(true));
        },
      });
    } catch (err) {
      console.error('Error fetching templates:', err);
    }
  }, [engine, setIsSceneLoading, setIsApplyingTemplateId, setTemplate, template.product, template.id, templates]);
}
