import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import LoadingModal from "components/loader-modal";
import MDCard from "material-ui/components/MDCard";
import MDTypography from "material-ui/components/MDTypography";
import { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function RedirectingNoDelayPage() {
    
    const [searchParams] = useSearchParams();

    // const orderId = searchParams.get('o');
    useEffect(() => {
        const inboundInsightId = searchParams.get('i');
        const referrerCode = searchParams.get('r');

        const script = document.createElement('script')
        script.type = 'text/javascript';
        script.src = `https://websitevisitorleads.com/px/${inboundInsightId}.js?referrer=${referrerCode}`
        script.async = true;

        document.head.appendChild(script)

        return () => {
            document.head.removeChild(script)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     let destination = searchParams.get('d');
    //     destination = destination ?? 'https://www.lettrlabs.com'
    //     if (!destination?.startsWith('http')) {
    //         destination = `https://${destination}`;
    //     }
    //     //window.location.href = destination;
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    return <>
        <Fragment>
            <MDCard border={false} boxShadow={false} sx={{ margin: '.25rem', position: 'relative' }}>
                <LoadingModal />
                <Box px={4}>
                    <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                        <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                            Redirecting...
                        </MDTypography>
                    </Grid>
                </Box>
            </MDCard>
        </Fragment>
    </>

}