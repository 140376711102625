import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, TextField, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import CloseIcon from 'assets/icons/close';
import CheckedImage from 'assets/images/checked.png';
import ConfettisImage from 'assets/images/confettis.png';
import ErrorDeniedAccess from 'assets/images/monitor.png';

import API_ENDPOINT from 'services/endpoint';

interface ISuccessCallbackSetupModal {
  platform: string,
  isOpenShopifySuccessfullyModal: boolean;
  setIsOpenShopifySuccessfullyModal: (value: boolean) => void;
}

const SuccessCallbackSetupModal: React.FC<ISuccessCallbackSetupModal> = ({ platform, isOpenShopifySuccessfullyModal, setIsOpenShopifySuccessfullyModal }) => {
  const navigate = useNavigate();

  const { search, pathname } = useLocation();

  const queryParams = new URLSearchParams(search);

  const [automationName, setAutomationName] = useState('');
  const [showConfirmationAutomationNameModal, setShowConfirmationAutomationNameModal] = useState(false);

  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');

  useEffect(() => {
    const getCallback = async () => {
      const endpoint = `${API_ENDPOINT}/data-sources/${platform}/oauth/callback?_code=${code}&state=${state}`;
      const fetchOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
      try {
        const res = await fetch(endpoint, fetchOptions);
        const response = await res.json();
        if (response.payload) return
        else navigate(`${pathname}?error=true`, { replace: true });
      } catch {
        navigate(`${pathname}?error=true`, { replace: true });
      }
    };

    if (code && state && platform) getCallback();
  }, [code, error, navigate, pathname, platform, state]);

  const handleConfirm = () => {
    if(!platform) return
    switch(platform?.toLowerCase()) {
        case 'shopify' :
            navigate(`/smart-automation/new?source=1&name=${automationName}`);
        break;
        case 'klaviyo' :
            navigate(`/smart-automation/new?source=2&name=${automationName}`);
        break;
    }
  }

  return (
    <Dialog open={isOpenShopifySuccessfullyModal} maxWidth={'md'} fullWidth onClose={() => {navigate('/automations'); setIsOpenShopifySuccessfullyModal(false)}}>
      <DialogContent sx={{ minHeight: error ? '200px' : '400px', padding: '4rem', position: 'relative' }}>
        <CloseIcon data-testid="setup-modal-close-btn" sx={{ top: '20px', opacity: .6, position: 'absolute', right: 20 }} onClick={() => {navigate('/automations'); setIsOpenShopifySuccessfullyModal(false)}} />

        {
          error ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={ErrorDeniedAccess} alt='Checked' width={'120px'}/>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '-40px' }}>
                <img src={ConfettisImage} alt='Conffetis' width={'450px'}  />
                <img src={CheckedImage} alt='Checked' width={'45px'} style={{ position: 'absolute', top: '80px'}}/>
            </Box>
          )
        }

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 4, justifyContent: 'center' }}>
            <Typography variant='paragraphMedium' fontSize={'22px'} fontWeight={'bold'} sx={{ color: error ? '#b70808' : 'inherit'}}>
              { error ? `Something went wrong with ${platform.charAt(0).toUpperCase() + platform.slice(1)} set up` : 'Congratulations!'}
            </Typography>
            <Typography variant='paragraphMedium'>
                {
                  error && errorDescription
                }

                {
                  !error && (
                    <React.Fragment>
                      Your <b style={{ textTransform: 'capitalize' }}>{platform}</b> automation has been successfully set up. <br />Take advantage of this and start creating your automations right now!
                    </React.Fragment>
                  )
                }
            </Typography>
            {
              !error ? (
                <MDButton onClick={() => setShowConfirmationAutomationNameModal(true)} color='primary' fullWidth>Create my first <span style={{ textTransform: 'capitalize', margin: '0 .25rem' }}>{platform}</span>Automation</MDButton>
              ) : (
                <MDButton onClick={() => setIsOpenShopifySuccessfullyModal(false)}>Close</MDButton>
              )
            }
        </Box>
      </DialogContent>

      <Dialog
                maxWidth={'md'}
                open={showConfirmationAutomationNameModal}
                onClose={() => { setShowConfirmationAutomationNameModal(false); setAutomationName('') }}
            >
                <DialogContent sx={{ padding: '1rem 4rem 2rem 4rem', width: '600px'}}>
                    <Box sx={{ display: 'flex', position: 'relative' }}>
                        <Typography variant="h6" py={4} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                            New <span style={{ margin: '0 .25rem', textTransform: 'capitalize' }}>{platform}</span> Automation
                        </Typography>
                        <CloseIcon data-testid="update-status-modal-close-btn" sx={{ opacity: .6, position: 'absolute',top: 35, right: 0}} onClick={() => { setShowConfirmationAutomationNameModal(false) }} />
                    </Box>
                    <Box sx={{ padding: '2rem 0 1rem 0'}}>
                        <Typography variant='paragraphMedium'>What name would you like to use for this automation?</Typography>
                    </Box>
                    <TextField 
                        label="Automation Name"
                        placeholder={'Enter the name for your new automation here.'} 
                        data-testid="automation-name-input-search"
                        onChange={(e) => setAutomationName(e.target.value)}
                        error={false}
                        sx={{ width: '100%', marginBottom: '2rem' }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <MDButton disabled={!automationName} color="primary" sx={{ borderRadius: '5px !important' }} onClick={handleConfirm}>
                            Save
                        </MDButton>
                    </Box>
                </DialogContent>
            </Dialog>
    </Dialog>
  );
};

export default SuccessCallbackSetupModal;
