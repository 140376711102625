import React from 'react';

import { Box } from '@mui/system';
import { getUserData } from 'helpers/getUserInfos';
import { IOnboardingData } from '../..';
import { IAnnualMailVolumeVm } from 'models/profile';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import MDInput from 'material-ui/components/MDInput';
import MDButton from 'material-ui/components/MDButton';

interface IStepOne {
  formData: IOnboardingData,
  setFormData: (form: IOnboardingData) => void,
  handleNext: () => void
}

const StepOne: React.FC<IStepOne> = ({ formData, setFormData, handleNext }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: 520, paddingTop: '50px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#5551FF', background: 'white', width: 100, height: 22, borderRadius: 16 }}>Step 1 of 2</Box>
      <Typography variant='h4' color={'#5551FF'} my={2}>Tell us about your business</Typography>
      <Typography variant='paragraphRegular'>Help us get to know you better by providing your business details.</Typography>
      <Box sx={{ display: 'flex', marginY: '40px', gap: 4 }}>
        <MDInput value={formData?.firstName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, firstName: event.target.value })} label={"First Name"} sx={{ width: '50%' }} />
        <MDInput value={formData?.lastName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, lastName: event.target.value })} label={"Last Name"} sx={{ width: '50%' }}/>
      </Box>
      <Box sx={{ display: 'flex', marginBottom: '40px', gap: 4 }}>
        <MDInput value={formData?.companyName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, companyName: event.target.value })} label={"Company Name"}  sx={{ width: '100%'}} />
      </Box>
      <Box sx={{ display: 'flex', marginBottom: '40px', gap: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="sa-source">
          Annual Mail Volume
          </InputLabel>
          <Select fullWidth label="Annual Mail Volume" onChange={(event: any) => setFormData({ ...formData, annualMailVolumeId: event.target.value}) }>
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            { getUserData()?.annualMailVolumes.map((volume: IAnnualMailVolumeVm, index: number) => {
                return <MenuItem key={index} value={volume.id}>{volume.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
        <MDButton disabled={!formData.firstName || !formData.lastName || !formData.companyName || !formData.annualMailVolumeId} onClick={handleNext}>Next</MDButton>
      </Box>
    </Box>
  );
}

export default StepOne;