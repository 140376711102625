import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Box, Collapse, Grid, MenuItem, Select, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import MDCard from "material-ui/components/MDCard";
import OrderService from "services/order";
import { IVM_GridOrder } from "models/views/gridOrderVm";
import MDTypography from "material-ui/components/MDTypography";
import MailboxIcon from "assets/icons/mailbox";
import MDButton from "material-ui/components/MDButton";
import { Order } from "models/order";
import EmptyCartIcon from "assets/icons/empty-cart";
import { OrderStatus } from "models/enums/orderStatus";
import FilterIcon from "assets/icons/filter";
import { useGlobal } from "context/global-context";
import { GridView, Reorder } from "@mui/icons-material";
import GenericTable, { createColumn } from "components/generic-table";
import "./index.scss"
import Actions from "./components/order-actions";
import OrderCard from "./components/order-card/order-card";
import { OrderStatusTag } from "./components/order-status-tag";

const notPaidStatus: string[] = [OrderStatus.Cancelled, OrderStatus.Deleted, OrderStatus.Draft, OrderStatus.EditsNeeded]
const filterStatusOptions: { [key: string]: string } = { All: "Show all orders", Draft: "Draft", EditsNeeded: "Edits Needed", Paid: "Paid" }

const OrdersPage = () => {
  const navigate = useNavigate()
  const { setShowLoader } = useGlobal()
  const { getOrders, postOrder } = OrderService()

  const [orders, setOrders] = useState<IVM_GridOrder[]>([])
  const [statusFilter, setStatusFilter] = useState<string>("All")
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [view, setView] = useState<string | null>('grid');

  const { data: ordersData, isLoading } = useQuery({
    queryKey: ["orders"],
    queryFn: getOrders
  })

  const { mutate: createOrder } = useMutation({
    mutationFn: () => postOrder(new Order(0)),
    onMutate: () => setShowLoader(true),
    onSuccess: ({ id }: { id: string }) => {
      setShowLoader(false)
      navigate(`/order/${id}`)
    }
  })

  useEffect(() => {
    setShowLoader(isLoading)
  }, [isLoading, setShowLoader]);

  useEffect(() => {
    if (ordersData?.orders) setOrders(ordersData.orders)
  }, [ordersData?.orders]);

  const handleStatusChange = (value: string) => {
    setStatusFilter(value)
    if (value === "All") {
      setOrders(ordersData.orders)
    } else if (value !== "Paid") {
      setOrders(ordersData.orders.filter((order: IVM_GridOrder) => order.status === filterStatusOptions[value]))
    } else {
      setOrders(ordersData.orders.filter((order: IVM_GridOrder) => !notPaidStatus.includes(order.status)))
    }
  }


  //Todo - Ajusta height da div
  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null,
  ) => {
    setView(newView);
  };

  const handleOrderId = (order: IVM_GridOrder) => (
    <div className="order-id">
      {order.id}
    </div>
  )

  const handlePercentage = (value: number, status:string) => {
    return (
      <div className="percentage-box">
        {
          status !== "Draft" && <div className="bullet"></div>
        }
        <div className="info">
          {
            status === "Draft" ? '-' : `${value || 0}%`
          }  
        </div>
      </div>
    )
  }

  const statusForTracker = (status: string) => {
    if (status === OrderStatus.Draft || status === OrderStatus.EditsNeeded) return "Draft"
    if (status === OrderStatus.Paid || status === OrderStatus.PaymentNeeded || status === OrderStatus.ReadyForProduction || status === OrderStatus.InShipping) return "Production"
    if (status === OrderStatus.Mailed) return "Mailed"
    return status
  }

  const columns = [
    createColumn<IVM_GridOrder>({
      key: 'id',
      label: 'Order ID',
      sortable: true,
      width: "100px",
      render: (order: IVM_GridOrder) => handleOrderId(order)
    }),
    createColumn<IVM_GridOrder>({
      key: 'name',
      label: 'Name',
      sortable: true
    }),
    createColumn<IVM_GridOrder>({
      key: 'product',
      label: 'Product',
      sortable: true
    }),
    createColumn<IVM_GridOrder>({
      key: 'totalRecipients',
      label: 'Recipient',
      sortable: true
    }),
    createColumn<IVM_GridOrder>({
      key: 'status',
      label: 'Status',
      sortable: true,
      render: (item: IVM_GridOrder) => <OrderStatusTag status={statusForTracker(item.status)} />
    }),
    createColumn<IVM_GridOrder>({
      key: 'mailed',
      label: 'Mailed',
      sortable: true,
      render: (item: IVM_GridOrder) => handlePercentage(item.mailed, item.status)
    }),
    createColumn<IVM_GridOrder>({
      key: 'inTransit',
      label: 'In Transit',
      sortable: true,
      render: (item: IVM_GridOrder) => handlePercentage(item.inTransit, item.status)
    }),
    createColumn<IVM_GridOrder>({
      key: 'atLocalFacility',
      label: 'At Local Facility',
      sortable: true,
      render: (item: IVM_GridOrder) => handlePercentage(item.atLocalFacility, item.status)
    }),
    createColumn<IVM_GridOrder>({
      key: 'delivered',
      label: 'Delivered',
      sortable: true,
      render: (item: IVM_GridOrder) => handlePercentage(item.delivered, item.status)
    }),
    createColumn<IVM_GridOrder>({
      key: 'actions',
      label: 'Actions',
      sortable: true,
      render: (item: IVM_GridOrder) => <Actions row={item} />
    })
  ];

  return (
    <MDCard sx={{ margin: '.25rem', minHeight: '87vh', position: 'relative' }}>
      <Box px={4}>
        <Grid container alignItems={'center'} justifyContent={'space-between'} height={'65px'}>
          <MDTypography sx={{ fontSize: '18px' }} fontWeight={'bold'} data-testid="automation-title">
            Orders
          </MDTypography>
          <Box display={'flex'} gap={1}>
            <Grid item>
              <ToggleButtonGroup
                sx={{ height: "45px" }}
                value={view}
                size="small"
                exclusive
                aria-label="change-view"
                onChange={handleView}>
                <ToggleButton value="grid" aria-label="left aligned">
                  <GridView />
                </ToggleButton>
                <ToggleButton value="list" aria-label="right aligned">
                  <Reorder />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <Collapse orientation="horizontal" in={showFilter} collapsedSize={115}>
                <Box display={"flex"} gap={1}>
                  <Tooltip title="Filter by status">
                    <MDButton color={"light"} onClick={() => setShowFilter(!showFilter)}>
                      <FilterIcon sx={{ marginRight: 1 }} />
                      Filter
                    </MDButton>
                  </Tooltip>
                  <Select
                    sx={{ width: 150 }}
                    aria-label="Status"
                    value={statusFilter}
                    onChange={(event) => handleStatusChange(event.target.value)}
                  >
                    {Object.entries(filterStatusOptions).map(([value, name]) => <MenuItem key={value} value={value}>{name}</MenuItem>)}
                  </Select>
                </Box>
              </Collapse>
            </Grid>
            <Grid item>
              <MDButton color={"primary"} onClick={() => createOrder()}>
                <MailboxIcon sx={{ marginRight: 1 }} />
                New Order
              </MDButton>
            </Grid>
          </Box>
        </Grid>
        {
          view === 'grid' && (
            <>
              {!isLoading && (
                ordersData?.orders?.length ?
                  <Grid container my={6}>
                    {orders.length ?
                      orders.map((order: IVM_GridOrder) => <OrderCard order={order} statusTracker={statusForTracker(order.status)} />)
                      :
                      <MDTypography sx={{ fontSize: 18 }} fontWeight={"bold"}>You don't have any orders in {filterStatusOptions[statusFilter]} status yet.
                        <MDTypography component="span" fontWeight={"bold"} color={"primary"} isLink onClick={() => handleStatusChange("All")} display={"inline-block"} ml={0.5} sx={{ fontSize: 18 }}>
                          Show all orders
                        </MDTypography>
                      </MDTypography>
                    }
                  </Grid>
                  :
                  <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={2} mt={4}>
                    <EmptyCartIcon sx={{ width: "60px", height: "60px" }} color={"primary"} />
                    <MDTypography sx={{ fontSize: 18 }} fontWeight={"bold"}>You don't have any orders yet. Create one by clicking
                      <MDTypography component="span" fontWeight={"bold"} color={"primary"} isLink onClick={() => createOrder()} display={"inline-block"} ml={0.5} sx={{ fontSize: 18 }}>
                        here
                      </MDTypography>
                    </MDTypography>
                  </Box>
              )}
            </>
          )
        }
        {
          view === 'list' && (
            <Box>
              <GenericTable<IVM_GridOrder>
                columns={columns}
                data={orders}
                isLoading={isLoading}
              />
            </ Box>
          )
        }
        <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%' }} />

      </Box>
    </MDCard>
  )
}

export default OrdersPage