import React from 'react';

import { Box } from '@mui/system';
import { Step } from 'pages/automations/context/types';
import { IPlatform } from 'services/automations/automatios.service.types';
import { useNavigate } from 'react-router-dom';
import { useAutomationContext } from 'pages/automations/context';
import { CircularProgress, DialogContent, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import MDButton from 'material-ui/components/MDButton';
import useDataSourcesService from 'services/data-sources';

interface IKlavioInstructions {
  platform: IPlatform
}

const KlaviyoInstructions: React.FC<IKlavioInstructions> = ({ platform }) => {

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { revokeDataSource } = useDataSourcesService();
  const { setStepToRender } = useAutomationContext();
  
  const { mutate: revoke, isPending: isRevokePending } = useMutation({
    mutationFn: revokeDataSource,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] }),
  });
    
  const handleRevoke = () => {
    revoke('klaviyo');
  }

  const handleSetup = () => {
    navigate('/oauth/setup/klaviyo')
  }

  return (
  <DialogContent sx={{ padding: '2rem 4rem'}}>
    <Typography variant="h6" sx={{ margin: '1rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
        Klaviyo Automation 
    </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '3rem 0 2rem 0'}}>
        <img style={{ marginLeft: '-20px'}} width={'180px'} src='https://bemeir.com/wp-content/uploads/2022/02/klaviyo-new-logo.png' alt="Shopify" />
      </Box>


    { platform?.authenticationStatus?.toLowerCase() === 'not authenticated' && (
      <Box sx={{ display: 'flex', height: '400px'}} mt={2}>
        <Box sx={{ width: '100%'}}>
          <Typography variant='paragraphMedium'>
            Klaviyo automation requires a direct setup on the Klaviyo website to get you started with your automations.
          </Typography>
          <Typography variant='paragraphMedium' sx={{ margin: '.5rem 0 0 0'}}>
            But don’t worry, we’ll explain how it works right below:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}> 
            <Typography variant='paragraphMedium' sx={{ margin: '2rem 0 0 0'}}>
              <b>Step 1:</b> Click the <b>Setup Now</b> button below to start the setup.
            </Typography>
            <Typography variant='paragraphMedium' sx={{ margin: '1rem 0'}}>
              <b>Step 2:</b> Sign up or log in if you already have an account.
            </Typography>
            <Typography variant='paragraphMedium'>
              <b>Step 3:</b> After completing step two, you’ll be automatically redirected back to our application.
            </Typography>
            <Typography variant='paragraphMedium' sx={{ margin: '2rem 0 1rem 0'}}>
              This setup will allow you to start creating and managing your automations easily and efficiently.
            </Typography>
            <Typography variant='paragraphMedium'> 
              Enjoy the benefits of Klaviyo automation to optimize your strategies!
            </Typography>
          </Box>
        
          <Box sx={{  display: 'flex', justifyContent: 'flex-end', margin: '1rem 0', gap: 2}}>
            <MDButton color="light" sx={{ borderRadius: '5px !important', marginTop: '2rem' }} onClick={() => setStepToRender(Step.INITIAL)}>
                Back
            </MDButton>
            <MDButton color="primary" sx={{ borderRadius: '5px !important', marginTop: '2rem' }} onClick={handleSetup}>
                Setup Now
            </MDButton>
          </Box>
        </Box>
      </Box>
    )}

    { platform?.authenticationStatus?.toLowerCase() === 'pending' && (
      <Box>
        <span>Finalize a condifuração</span>
      </Box>
    )}

    { platform?.authenticationStatus?.toLowerCase() === 'authenticated' && (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: '1rem 0 0 0'}}>
        <Typography variant='paragraphMedium' sx={{ margin: '1rem 0 0 0'}}>Everything is set with your Klaviyo automation! You're all ready to engage your customers seamlessly.</Typography>
        <Typography variant='paragraphMedium'>Now, you can focus on what matters: growing your business.</Typography>
        <Box sx={{  display: 'flex', justifyContent: 'flex-end', margin: '1rem 0', gap: 2}}>
            <MDButton color="light" sx={{ 
                borderRadius: '5px !important',
                '&:hover': {
                  backgroundColor: '#d3d5db',
                  color: '#2c2d36'
                },
              }} onClick={() =>setStepToRender(Step.INITIAL)}>
              Back
            </MDButton>
            <MDButton disabled={isRevokePending} color="error" sx={{ 
                borderRadius: '5px !important',
                height: '30px',
                '&:hover': {
                  backgroundColor: '#d32f2f',
                },
              }} onClick={handleRevoke}>
                { isRevokePending ? <CircularProgress size={15} color='inherit'  /> : 'Revoke'}
            </MDButton>
        </Box>
      </Box>
    )}
    </DialogContent>
  );
}

export default KlaviyoInstructions;