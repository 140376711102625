const paper = {
  styleOverrides: {
    rounded: {
      borderRadius: 8,
    },
    outlined: {
      border: '1px solid rgba(0,0,0,.07)',
    },
  },
};

export default paper;
