import '../index.scss'

interface IStatusOrder {
    status: string
}

export const OrderStatusTag = ({ status }: IStatusOrder) => {
    const formatToClass = (status: string) => {
        return status.trim().replace(/\s+/g, ' ').toLowerCase();
    }
    return (
        <div className={`tag-status ${formatToClass(status)}`}>
            { status }
        </div>
    )
}