/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base typography styles for the Material Dashboard 2 PRO React TSUI Dashboard PRO Material.
 * You can add search typography style using this file.
 * You can customized the typography styles for the entire Material Dashboard 2 PRO React TSUI Dashboard PRO Material using thie file.
 */

// Material Dashboard 2 PRO React TS Base Styles
const globalFontFamily = "'Segoe UI', sans-serif"; 


// types
interface Types {
  h1: {
    color: string;
    fontSize: string;
    fontWeight: number | string;
    lineHeight: number;
    fontFamily: string;
  };
  h2: {
    color: string;
    fontSize: string;
    fontWeight: number | string;
    fontFamily: string;
    lineHeight: number;
  };
  h3: {
    color: string;
    fontSize: string;
    fontWeight: number | string;
    fontFamily: string;
    lineHeight: number;
  };
  h4: {
    color: string;
    fontSize: string;
    fontWeight: number | string;
    fontFamily: string;
    lineHeight: number;
  };
  h5: {
    color: string;
    fontSize: string;
    fontWeight: number | string;
    fontFamily: string;
    lineHeight: number;
  };
  h6: {
    color: string;
    fontSize: string;
    fontWeight: number | string;
    fontFamily: string;
    lineHeight: number;
  };
  paragraphLarge: {
    color: string;
    fontSize: string;
    fontFamily: string;
    fontWeight: number | string
    lineHeight: number;
  },
  paragraphMedium: {
    color: string;
    fontSize: string;
    fontFamily: string;
    fontWeight: number | string;
    lineHeight: number;
  },
  paragraphRegular: {
    color: string;
    fontSize: string;
    fontFamily: string;
    fontWeight: number | string;
    lineHeight: number;
  },
  paragraphSmall: {
    color: string;
    fontSize: string;
    fontFamily: string;
    fontWeight: number | string;
    lineHeight: number;
  },
  lineHeight: {
    sm: number;
    md: number;
    lg: number;
  };
}

const typography: Types = {
  h1: {
    fontSize: '64px !important',
    lineHeight: 1.10,
    fontWeight: 'bold',
    color: '#2C2D36',
    fontFamily: globalFontFamily
  },
  h2: {
    fontSize: '40px !important',
    lineHeight: 1.10,
    color: '#2C2D36',
    fontWeight: 'bold',
    fontFamily: globalFontFamily
  },
  h3: {
    fontSize: '36px !important',
    lineHeight: 1.10,
    color: '#2C2D36',
    fontWeight: '500',
    fontFamily: globalFontFamily
  },
  h4: {
    fontSize: '32px',
    lineHeight: 1.10,
    color: '#2C2D36',
    fontWeight: '500',
    fontFamily: globalFontFamily
  },
  h5: {
    fontSize: '24px',
    lineHeight: 1.10,
    color: '#2C2D36',
    fontWeight: '500',
    fontFamily: globalFontFamily
  },
  h6: {
    fontSize: '20px',
    lineHeight: 1.10,
    color: '#2C2D36',
    fontWeight: '500',
    fontFamily: globalFontFamily
  },
  paragraphLarge: {
    fontSize: '20px',
    fontWeight: '300',
    color: '#2C2D36',
    lineHeight: 1.40,
    fontFamily: globalFontFamily
  },
  paragraphMedium: {
    fontSize: '18px',
    fontWeight: '300',
    color: '#2C2D36',
    lineHeight: 1.50,
    fontFamily: globalFontFamily
  },
  paragraphRegular: {
    fontSize: '16px',
    color: '#2C2D36',
    fontWeight: '300',
    lineHeight: 1.50,
    fontFamily: globalFontFamily
  },
  paragraphSmall: {
    fontSize: '12px',
    color: '#2C2D36',
    fontWeight: '300',
    lineHeight: 1.50,
    fontFamily: globalFontFamily
  },
  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  }
};

export default typography;
