import { ExpandMore, Google } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import useSourceInstallRequest from 'hooks/useSourceInstallRequest';
import MDButton from 'material-ui/components/MDButton';
import logo from 'assets/images/logo.svg';

export default function LeadRevealSetupModal() {
  const { setInstallSource } = useSourceInstallRequest();
  const [searchParams, setSearchParams] = useSearchParams();
  const open = searchParams.get('modal') === 'SetupLeadReveal';

  return (
    <Dialog open={open} onClose={() => setSearchParams('', { replace: true })} maxWidth="sm">
      <DialogContent>
        <DialogTitle>Unmask Anonymous Website Traffic with LeadReveal</DialogTitle>
        <Typography variant="paragraphRegular" fontSize={16}>
          What is LeadReveal?
        </Typography>
        <Box component={'ul'} ml={3} my={2}>
          <Typography component={'li'} fontSize={16}>
            LeadReveal unmasks your anonymous website traffic for direct mail retargeting
          </Typography>
        </Box>
        <Typography variant="paragraphRegular" fontSize={16}>
          How Does it Work?
        </Typography>
        <Box component={'ul'} ml={3} my={2}>
          <Typography component={'li'} fontSize={16}>
            LeadReveal identifies your anonymous traffic and you decide who you send direct mail to
          </Typography>
          <Typography component={'li'} fontSize={16}>
            Unlike competitors our patent pending solution doesn't use IP addresses and isn't impacted by iOS14 privacy
            changes.
          </Typography>
        </Box>
        <MDButton fullWidth color="secondary" onClick={() => setSearchParams('modal=LeadReveal')} sx={{ mb: 1 }}>
          <img alt="" src={logo} height={24} style={{ marginRight: 8 }} />
          Setup LeadReveal
        </MDButton>

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ backgroundColor: '#F3F5FB' }}>
            Advanced Options
          </AccordionSummary>
          <AccordionDetails>
            <MDButton fullWidth color="light" onClick={() => setInstallSource('lead-reveal')}>
              <Google sx={{ mr: 1 }} />
              Setup LeadReveal with my Google Tag Manager account
            </MDButton>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
}
