import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import CheckedImage from 'assets/images/checked.png';

interface IStep3 {
  handleReset: Function
}

const Step3: React.FC<IStep3> = ({ handleReset }) => {
  return (
    <MDCard sx={{ width: '100%' }}>
      <Box sx={{ position: 'relative', height: '50px', display: 'flex', alignItems: 'center', padding: '0 2rem' }}>
        <Typography fontSize={14} fontWeight={'bold'} variant='paragraphRegular'>It's all set</Typography>
        <Box sx={{ border: '1.5px solid #eef2ff', position: 'absolute', left: 0, top: '50px', width: '100%' }} />
      </Box>
      <Box sx={{ padding: '2rem 0 0 0', gap: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img src={CheckedImage} alt='Checked' width={'60px'} />
        <Typography variant='h4'>Thank you!</Typography>
        <Typography variant='paragraphRegular'>Your submission has been sent.</Typography>
      </Box>
      <MDButton
          sx={{ 
              width: '300px',
              margin: '1rem auto 2rem auto',
              backgroundColor: '#5551FF',
              '&:hover': {
                  backgroundColor: 'rgba(68, 64, 230, 1)'
              },
              '&.Mui-disabled': {
                  backgroundColor: '#5551FF !important',
              }
          }}
          onClick={() => handleReset()}
      >
        Create New
      </MDButton>
    </MDCard>
  )
}

export default Step3;