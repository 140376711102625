import React from 'react';

import { Box } from '@mui/system';
import { formatPrice } from 'helpers/formatters';
import { Skeleton, Typography } from '@mui/material';

import CoreIcon from 'assets/icons/core';
import PremiumIcon from 'assets/icons/premium';
import StarterIcon from 'assets/icons/starter';
import EnterpirseIcon from 'assets/icons/enterprise';

interface IPlanInfo {
    plan?: 'Starter' | 'Core' | 'Premium' | 'Enterprise' | string,
    price?: number,
    isLoading: boolean,
    recorrency?: number
}

const PlanInfo: React.FC<IPlanInfo> = ({ plan, price, recorrency, isLoading }) => {

    const formatRecorrencyDescription = () => {
        switch (recorrency) {
            case 0:
                return 'One Off'
            case 1:
                return 'day'
            case 2:
                return 'week'
            case 3:
                return 'month'
            case 4:
                return 'year'
            case 5:
                return 'every 3 months'
            case 6:
                return 'every 6 months'
            case 7:
                return 'Custom'
            default:
                return ''
        }
    }

    const getPlanInfo = () => {
        switch (plan?.toLowerCase()) {
            case 'starter':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: '150px' }}>
                        <Box sx={{ width: '50px'}}>
                            <StarterIcon />
                        </Box>
                        <Box>
                            <Box component={'h1'} sx={{ fontSize: '26px', fontWeight: 'bold' }}>{plan}</Box>
                            <Box sx={{ display: 'flex', gap: 1, marginTop: '-5px' }}>
                                <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>Free</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            case 'core':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: '150px' }}>
                        <Box sx={{ width: '50px'}}>
                            <CoreIcon />
                        </Box>
                        <Box>
                            <Box component={'h1'} sx={{ fontSize: '26px', fontWeight: 'bold' }}>{plan}</Box>
                            <Box sx={{ display: 'flex', gap: 1, marginTop: '-5px' }}>
                                <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>{price ? formatPrice(price) : formatPrice(0)}</Typography>
                                <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>/</Typography>
                                <Typography variant="paragraphMedium">{formatRecorrencyDescription()}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            case 'premium':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: '150px' }}>
                        <Box sx={{ width: '50px'}}>
                            <PremiumIcon />
                        </Box>
                        <Box>
                            <Box component={'h1'} sx={{ fontSize: '26px', fontWeight: 'bold' }}>{plan}</Box>
                            <Box sx={{ display: 'flex', gap: 1, marginTop: '-5px' }}>
                                <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>{price ? formatPrice(price) : formatPrice(0)}</Typography>
                                <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>/</Typography>
                                <Typography variant="paragraphMedium">{formatRecorrencyDescription()}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            case 'enterprise':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: '150px' }}>
                        <Box sx={{ width: '50px'}}>
                            <EnterpirseIcon />
                        </Box>
                        <Box>
                            <Box component={'h1'} sx={{ fontSize: '26px', fontWeight: 'bold' }}>{plan}</Box>
                            <Box sx={{ display: 'flex', gap: 1, marginTop: '-5px' }}>
                                <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>{price ? formatPrice(price) : formatPrice(0)}</Typography>
                                <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>/</Typography>
                                <Typography variant="paragraphMedium">{formatRecorrencyDescription()}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            default:
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: '150px' }}>
                        <Box sx={{ width: '50px'}}>
                            <StarterIcon />
                        </Box>
                        <Box>
                            <Box component={'h1'} sx={{ fontSize: '26px', fontWeight: 'bold' }}>{plan}</Box>
                            {
                                price && price > 0 ? (
                                    <Box sx={{ display: 'flex', gap: 1, marginTop: '-5px' }}>
                                        <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>{price ? formatPrice(price) : formatPrice(0)}</Typography>
                                        <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>/</Typography>
                                        <Typography variant="paragraphMedium">{formatRecorrencyDescription()}</Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', gap: 1, marginTop: '-5px' }}>
                                        <Typography variant="paragraphMedium" sx={{ fontWeight: 'bold'}}>Free</Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                )
        }
    }

    return (
        <Box>
            { !isLoading && getPlanInfo() }

            {
                isLoading && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: '150px' }}>
                        <Box sx={{ width: '50px'}}>
                            <Skeleton width={'50px'} height={'80px'}  sx={{ borderRadius: '100%'}} />
                        </Box>
                        <Box>
                            <Box component={'h1'} sx={{ fontSize: '26px', fontWeight: 'bold' }}>
                                <Skeleton width={'100px'} height={'35px'}  />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 1, marginTop: '-5px' }}>
                                <Skeleton width={'100px'} height={'25px'}  />
                            </Box>
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
}

export default PlanInfo;