import MDCard from 'material-ui/components/MDCard';
import React from 'react';
import { Typography } from '@mui/material';
import { IDashboardProfileOverview } from 'newStandard/src/services/shopifyDashboard/types';
import { formatNumber } from 'helpers/formatters';

interface IIndicatorCard {
    data: IDashboardProfileOverview
}

const IndicatorCard: React.FC<IIndicatorCard> = ({ data }) => {
  return (
    <MDCard sx={{display: 'flex', justifyContent: 'center', padding: '2rem', minWidth: '240px', width: '240.8px', height: '107px', borderRadius: '16px !important' }}>
        <Typography variant='paragraphMedium' fontWeight={'600'}>{data.title}</Typography>
        <Typography variant='h2'>{Number(data.value) ? formatNumber(Number(data.value)) : data.value}</Typography>
    </MDCard>
  )
}

export default IndicatorCard;