import React, { useState } from 'react';

import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getUserData } from 'helpers/getUserInfos';
import { useMutation } from '@tanstack/react-query';
import { useSelfManagementPlanModal } from 'context/selfmanageplan-context';
import { CircularProgress, Dialog, DialogContent, Skeleton, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import CoreIcon from 'assets/icons/core';
import PremiumIcon from 'assets/icons/premium';
import EnterpirseIcon from 'assets/icons/enterprise';
import useCheckoutDataService from 'services/checkout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SelfManagementPlanModal: React.FC = () => {
    const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar();

    const { enterpriseUpgradeContactSalesTeam } = useCheckoutDataService();
    const [ loadingContactSales, setLoadingContactSales ] = useState(false);
    const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);
    const { isModalVisible, hideModal, tierToUpgrade, setTierToUpgrade, checkoutData, isLoadingCheckoutData } = useSelfManagementPlanModal();

    const PlanFeatures: { [key in 'core' | 'premium' | 'enterprise']: { includes: string, features: string[] } } = {
        core: {
            includes: 'All Starter Tier features',
            features: [
                "Lower Handwritten Mail Prices",
                "Automation & Integrations",
                "Industry Specific Templates",
                "Trackable QR Codes",
                "Automation Setup Support",
                "Advanced Attribution Dashboard (Coming Soon)"
            ]
        },
        premium: {
            includes: 'All Core Tier features',
            features: [
                "Lower Handwritten Mail Prices",
                "Acct. Mgmt: Guided Support",
                "Custom Direct Mail Designs",
                "Do Not Mail List",
                "Lower Postcard Prices"
            ]
        },
        enterprise: {
            includes: 'All Premium features',
            features: [
                "Lower Handwritten Mail Prices",
                "Acct. Mgmt: Fully Managed Service",
                "Open API Access",
                "Unlimited Custom Direct Mail Designs",
                "Automation Setup & Onboarding",
                "Lowest Optional add on Pricing",
                "Lowest Postcard Prices"
            ]
        }
    };
    

    const createContactSalesMutation = useMutation({
        mutationFn: () => {
            return enterpriseUpgradeContactSalesTeam()
        },
        onMutate: () => {
            setLoadingContactSales(true);
        },
        onSuccess: (data) => {
            if(!data.hasErrors) {
                setShowSuccessMessage(true);
            } else {
                enqueueSnackbar('Something went wrong, try again later.', { variant: 'error' } );
            }
            setLoadingContactSales(false);
        }
    })


    const goToCheckout = () => {
        if(tierToUpgrade === 'Core Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Monthly') {
            hideModal();
            navigate('/subscriptions/checkout/saas-core-monthly');
        }

        if(tierToUpgrade === 'Core Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Annual') {
            hideModal();
            navigate('/subscriptions/checkout/saas-core-annual');
        }

        if(tierToUpgrade === 'Premium Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Monthly') {
            hideModal();
            navigate('/subscriptions/checkout/saas-premium-monthly');
        }

        if(tierToUpgrade === 'Premium Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Annual') {
            hideModal();
            navigate('/subscriptions/checkout/saas-premium-annual');
        }

    }

    const handleContactSales = () => {
        createContactSalesMutation.mutate();
    }

    const getPlanIcon = (plan: string) => {
        if(!plan) return

        switch (plan.toLowerCase()) {
            case 'core' :
                return <Box style={{ width: '45px', display: 'flex', alignItems: 'center', marginLeft: '-5px' }} ><CoreIcon/></Box>
            case 'premium' :
                return <Box style={{ width: '45px', display: 'flex', alignItems: 'center', marginLeft: '-5px' }} ><PremiumIcon /></Box>
            case 'enterprise' :
                return <Box style={{ width: '45px', display: 'flex', alignItems: 'center', marginLeft: '-5px' }} ><EnterpirseIcon /></Box>
            default:
                return
        }
    }


    return (
        <Dialog open={isModalVisible} maxWidth="lg">
            <DialogContent sx={{ width: !showSuccessMessage ? '530px' : 'auto' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: !showSuccessMessage ? 'space-between' : 'flex-start' }}>
                    {
                        !isLoadingCheckoutData ? (
                            <Typography variant='paragraphMedium' fontWeight={'bold'} fontSize={22} display={'flex'} gap={1} alignItems={'center'}>
                                {
                                    !showSuccessMessage && (
                                        <React.Fragment>
                                            {getPlanIcon(tierToUpgrade ? tierToUpgrade.split(' ')[0] : '')}
                                        </React.Fragment>
                                    )
                                }
                            { !showSuccessMessage ? `Upgrade to ${tierToUpgrade ? tierToUpgrade.split(' ')[0] : ''}` : 'Thank you!'}
                            </Typography>
                        ) : (
                            <Skeleton width={180} height={25}/>
                        )
                    }
                            

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {
                            !isLoadingCheckoutData ? (
                                <React.Fragment>
                                    <Typography variant='paragraphMedium' fontWeight={'bold'} fontSize={22} display={'flex'} gap={1} alignItems={'center'} color={'#F05A2A'}>
                                        {
                                            tierToUpgrade !== 'Enterprise Tier' && (
                                                <React.Fragment>
                                                    ${checkoutData?.payload?.price}
                                                </React.Fragment>
                                            ) 
                                        }
                                    </Typography>
                                    {
                                        tierToUpgrade !== 'Enterprise Tier' && (
                                            <Typography variant='paragraphMedium' fontSize={16} display={'flex'} gap={1} alignItems={'center'}>/month</Typography>
                                        )
                                    }
                                </React.Fragment>
                            ) : (
                                <Skeleton width={120} height={25}/>
                            )
                        }
                    </Box>
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, borderRadius: '5px', padding: '1rem 0' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box>
                            {
                                !isLoadingCheckoutData ? (
                                    <Typography variant='paragraphMedium' sx={{ display: 'flex', flexDirection: 'column' }}>
                                        {
                                            (tierToUpgrade !== 'Enterprise Tier' && !showSuccessMessage) && (
                                                <Box>
                                                    This feature requires a {tierToUpgrade ? tierToUpgrade.split(' ')[0] : ''} Plan. Upgrade your plan today to instantly unlock it.
                                                </Box>
                                            )
                                        }

                                        {
                                            (tierToUpgrade === 'Enterprise Tier' && !showSuccessMessage) && (
                                                <Box>
                                                    This feature requires an Enterprise Plan. Connect with a sales representative to tailor the right plan for your needs.
                                                </Box>
                                            )
                                        }

                                        {
                                            !showSuccessMessage && (
                                                <React.Fragment>

                                                    <Box sx={{ marginTop: 1.5, marginBottom: 1.5, fontWeight: 700 }}>
                                                        Includes
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, marginBottom: 1 }}>
                                                        <CheckCircleOutlineIcon sx={{ display: 'flex', alignItems: 'center', width: '24px', color: '#e14817' }} />
                                                        <Typography variant='paragraphMedium'>{PlanFeatures[tierToUpgrade.split(' ')[0].toLowerCase() as 'core' | 'premium' | 'enterprise']?.includes}</Typography>
                                                    </Box>
                                                </React.Fragment>
                                            )
                                        }

{
                                            !showSuccessMessage && (
                                                <Box sx={{ marginTop: 1.5, marginBottom: 1.5, fontWeight: 700 }}>
                                                    With Additional Access To
                                                </Box>
                                            )
                                        }
                                        
                                        {
                                            !showSuccessMessage && tierToUpgrade && PlanFeatures[tierToUpgrade.split(' ')[0].toLowerCase() as 'core' | 'premium' | 'enterprise']?.features.map((p, idx, arr) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1.5,
                                                        marginBottom: idx !== arr.length - 1 ? 1 : 0,
                                                    }}
                                                    key={idx}
                                                >
                                                    <CheckCircleOutlineIcon sx={{ display: 'flex', alignItems: 'center', width: '24px', color: '#e14817' }} />
                                                    <Typography variant='paragraphMedium'>{p}</Typography>
                                                </Box>
                                            ))
                                        }


                                        {
                                            (tierToUpgrade === 'Enterprise Tier' && showSuccessMessage) && (
                                                'Our sales team will contact you as soon as possible.'
                                            )
                                        }

                                    </Typography>
                                ) : (
                                    <Box>
                                        <Skeleton width={400} height={25}/>
                                        <Skeleton width={210} height={25}/>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                    <Box>
                        {
                            (!showSuccessMessage && !isLoadingCheckoutData) && ( 
                                <Box sx={{ mt: '10px' }}>
                                    <Typography component={'a'} href='https://www.lettrlabs.com/products/pricing' target='_blank' variant='paragraphMedium' fontWeight={'bold'} color={'#005BD3'} sx={{ textDecoration: 'underline !important', cursor: 'pointer'}}>Compare Plans</Typography>
                                </Box>
                            )
                        }
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, marginTop: '.5rem' }}>
                        <MDButton 
                            onClick={() => {
                                setTierToUpgrade('');
                                setShowSuccessMessage(false);
                                hideModal();
                            }} color='light'>{ showSuccessMessage ? 'Close' : 'Cancel'}</MDButton>

                        {
                            tierToUpgrade === 'Enterprise Tier' && !showSuccessMessage && (
                                <MDButton disabled={isLoadingCheckoutData || loadingContactSales} onClick={handleContactSales}>{loadingContactSales ? <CircularProgress size={24} color={'inherit'} /> : 'Talk to Sales'}</MDButton>
                            ) 
                        }

                        { 
                            tierToUpgrade !== 'Enterprise Tier' && (
                                <MDButton disabled={isLoadingCheckoutData} onClick={goToCheckout}>Go to Checkout</MDButton>
                            )
                        }
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default SelfManagementPlanModal;
