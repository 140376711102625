import {Grid, Link} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";

function ErrorPage(){
    return <Grid pl={"280px"} container alignItems={"center"} justifyContent={"center"} width={"100vw"} height={"100vh"} flexDirection={"column"}>
        <Icon sx={{fontSize: "80px !important"}} color={"error"}>report_gmailerrorred</Icon>

        <MDBox mt={1}>
            <MDTypography variant={"h3"} align={"center"} color={"text"}>Oops...</MDTypography>
            <MDTypography variant={"h6"} align={"center"} color={"text"}>
                Something went wrong. Please try refreshing the page or contact us at <Link color={"primary"} href={"mailto: billing@lettrlabs.com"} display={"inline-block"}>support@lettrlabs.com</Link>
            </MDTypography>
        </MDBox>
    </Grid>
}

export default ErrorPage