import { Box, Chip, Divider, Grid, Skeleton, Typography } from '@mui/material';

import { formatNumber, formatPrice } from 'helpers/formatters';
import { ICheckout } from 'models/checkout';

interface CheckoutSummaryProps {
  checkout?: ICheckout;
  isLoading: boolean;
}

export default function CheckoutSummary({ checkout, isLoading }: CheckoutSummaryProps) {
  return (
    <>
      <Grid container py={1}>
        <Grid item xs={6}>
          <Typography variant="paragraphRegular">Product</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="paragraphRegular">Cost</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="paragraphRegular" textAlign={'center'}>
            Qty
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="paragraphRegular" textAlign={'end'}>
            Amount
          </Typography>
        </Grid>
      </Grid>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        checkout?.lineItems.map((lineItem) => (
          <>
            <Grid container key={lineItem.name}>
              <Grid item xs={6}>
                <Typography fontSize={14} fontWeight={'bold'}>
                  {lineItem.name}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={14} fontWeight={'bold'}>
                  {formatPrice(lineItem.rate)}
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                <Typography fontSize={14} fontWeight={'bold'}>
                  x {lineItem.quantity ? formatNumber(lineItem.quantity) : '0'}
                </Typography>
              </Grid>
              <Grid item xs={3} textAlign={'end'}>
                <Typography fontSize={14} fontWeight={'bold'}>
                  {formatPrice(lineItem.quantity * lineItem.rate)}
                </Typography>
              </Grid>
            </Grid>

            <Box pl={2}>
              {lineItem.subItems.map((subItem) => (
                <Grid container key={`${lineItem.name}${subItem.name}`} alignItems={'center'}>
                  <Grid item xs={6} mr={-2}>
                    <Typography fontSize={14}>{subItem.name}</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Chip size="small" label={formatPrice(subItem.cost)} />
                  </Grid>

                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              ))}
            </Box>
          </>
        ))
      )}
      <Divider light />

      <Grid container>
        <Grid item xs>
          <Typography fontSize={14} fontWeight={'bold'}>
            Total Tax
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          <Typography fontSize={14} fontWeight={'bold'}>
            {isLoading ? <Skeleton /> : formatPrice(0)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography fontSize={14}>Credits</Typography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          <Typography fontSize={14}>
            {isLoading ? <Skeleton /> : formatPrice(checkout?.credits?.reduce((a, b) => a + b.total, 0) ?? 0)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography variant="h6" fontSize={18} color="#DA4819">
            Total Due
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          <Typography variant="h6" fontSize={18} color="#DA4819">
            {isLoading ? <Skeleton /> : formatPrice(checkout?.balance ?? 0)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
