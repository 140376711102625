import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import MDCard from "material-ui/components/MDCard";
import {Grid, IconButton, Tooltip} from "@mui/material";
import MDInput from "material-ui/components/MDInput";
import MDButton from "material-ui/components/MDButton";
import StyledDataGrid from "components/styled-data-grid";
import {GridColDef} from "@mui/x-data-grid-pro";
import React, {useState} from "react";
import moment from "moment/moment";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import ApiKeyService from "services/api-key-service";
import ApiKeyModal from "./components/api-key-modal";
import DeleteIcon from "assets/icons/delete";
import InfoModal from "components/info-modal";
import { useGlobal } from "context/global-context";

function IntegrationSetupPage(){
    const queryClient = useQueryClient()
    const {setShowLoader} = useGlobal()

    const apiKeyType = { value: 1, description: 'Zapier' }

    const {getApiKeys, createApiKey, deleteApiKey} = ApiKeyService()

    const [apiKeyName, setApiKeyName] = useState<string>("")
    const [apiKeyToDelete, setApiKeyToDelete] = useState<number | null>(null)

    const [showDeleteApiKeyModal, setShowDeleteApiKeyModal] = useState<boolean>(false)
    const [showApiKeyModal, setShowApiKeyModal] = useState<boolean>(false)

    const [newApiKey, setNewApiKey] = useState<string>("")

    const getApiKeysQuery = useQuery({
        queryKey: ["getApiKeysQuery", apiKeyType.value],
        queryFn: () => getApiKeys(apiKeyType.value)
    })

    const postApiKeyMutation = useMutation({
        mutationFn: () => {
            return createApiKey(apiKeyName, 1)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any, variables, context) => {
            setShowLoader(false)

            setNewApiKey(data.key)
            setShowApiKeyModal(true)

            queryClient.invalidateQueries({queryKey: ["apiKeys"]})
        }
    })

    const deleteApiKeyMutation = useMutation({
        mutationFn: () => {
            return deleteApiKey(apiKeyToDelete!)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any, variables, context) => {
            setShowLoader(false)

            setShowDeleteApiKeyModal(false)

            queryClient.invalidateQueries({queryKey: ["apiKeys"]})
        }
    })

    const columns: GridColDef[] = [
        {
            headerName: "#",
            field: "id",
            width: 300,
            valueFormatter: (params) => {
                return `Api Key #${params.value}`
            },
        },
        {
            headerName: "Description",
            field: "description",
            width: 300
        },
        {
            headerName: "Created Date",
            field: "createdDate",
            width: 300,
            valueFormatter: (params) => {
                return moment(params.value).format("MM/DD/YYYY hh:mm")
            },
        },
        {
            type: "actions",
            field: "actions",
            align: "right",
            width: 100,
            flex: 1,
            getActions: (params) => {
                let actions = [
                    <Tooltip title={"Delete"} arrow={false}>
                        <IconButton onClick={() => {
                            setApiKeyToDelete(params.row.id)
                            setShowDeleteApiKeyModal(true)
                        }}>
                            <DeleteIcon color={"error"}/>
                        </IconButton>
                    </Tooltip>,
                ]

                return actions
            }
        }
    ]

    return <MDCard>
        <MDBox p={4} width={"100%"}>
            <MDTypography variant={"h6"} fontWeight={"bold"} color={"secondary"}>What is Zapier and why would I want to connect it to LettrLabs?</MDTypography>

            <MDTypography mt={3} sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>Zapier is a virtual assistant which automates tasks using workflows called Zaps and is the glue that connects more than 5,000 web apps together for your needs. Click here for a list of all apps Zapier connects with.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>Using Zapier you can connect applications like Salesforce, Google Sheets, Mailchimp, & 5,000 of the most popular software platforms on the internet with LettrLabs. This will allow you to send cards automatically using the data you already have in your other applications.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>Below we've included a quick guide to connect Zapier with the LettrLabs direct mail platform.</MDTypography>

            <MDTypography mt={3} variant={"h6"} fontWeight={"bold"} color={"secondary"}>Zapier Setup Guide</MDTypography>

            <MDTypography mt={3} sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>1. First click on the Zapier icon below to accept LettrLabs beta invite with Zapier. This allows you to connect with and search for our app when creating zaps.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>2. In the section below create an API key, which will help make the connection between the LettrLabs platform and your Zapier account.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>3. You'll do this by first entering a name for your API key below.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>4. After entering the name click "Add New". A popup will appear with a long string of letters and numbers.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>5. Copy the API text string. The API key will only appear once (due to security policies), so make sure to copy it. If you need to create another one thers no issue.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>6. Now that you have your API key copied, now go to Zapier and create your first Zap.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>7. In zapier click the create zap.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>8. Enter your trigger. This will be the application that will send LettrLabs data when a trigger or event occurs. For more information on setting up Zaps check out this helpful link by Zapier.</MDTypography>
            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"} color={"secondary"}>9. Now select your action. First search for the LettrLabs appNext connect your LettrLabs account in zapier by adding your API keyAfter your account has been connected now you can setup your zap.</MDTypography>

            <MDBox mt={2}>
                <MDCard boxShadow={false} border={true} borderColor={"light"} borderRadiusSize={"lg"}>
                    <MDBox p={2}>
                        <MDTypography variant={"h6"} fontWeight={"bold"} color={"secondary"}>Api Keys</MDTypography>

                        <Grid container mt={2} gap={2} maxWidth={"1000px"}>
                            <Grid item flex={1}>
                                <MDInput
                                    fullWidth={true}
                                    value={apiKeyName}
                                    onChange={(e: any) => {
                                        setApiKeyName(e.target.value)
                                    }}
                                    label={"Description*"}
                                    helperText="Please name your API Key, this will help you identify it later"
                                />
                            </Grid>

                            <Grid item>
                                <MDButton
                                    color={"primary"}
                                    disabled={!apiKeyName}
                                    onClick={() => {
                                        postApiKeyMutation.mutate()
                                    }}
                                >
                                    Add New
                                </MDButton>
                            </Grid>
                        </Grid>

                        <MDBox mt={4}>
                            <StyledDataGrid
                                columns={columns}
                                rows={getApiKeysQuery.data?? []}
                                loading={getApiKeysQuery.isLoading}
                            />
                        </MDBox>
                    </MDBox>
                </MDCard>
            </MDBox>
        </MDBox>

        <ApiKeyModal show={showApiKeyModal} setShow={setShowApiKeyModal} apiKey={newApiKey}/>

        <InfoModal
            show={showDeleteApiKeyModal}
            setShow={setShowDeleteApiKeyModal}
            headerText={`Are you sure you want to delete your API key? This will invalidate any Zaps that are dependent on it`}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                setApiKeyToDelete(null)
                setShowDeleteApiKeyModal(false)
            }}
            confirmButtonOnClick={() => {
                deleteApiKeyMutation.mutate()
            }}
        />
    </MDCard>
}

export default IntegrationSetupPage