import { useEffect, useRef, useState } from 'react';
import { Box, Collapse } from '@mui/material';

import { useEditorContext } from '../contexts/useEditorContext';
import useCreateCardEditor from '../hooks/useCreateCardEditor';
import useBlockSelection from '../hooks/useBlockSelection';
import useLoadCardScene from '../hooks/useLoadCardScene';
import useStyledEditor from '../hooks/useStyledEditor';
import ReturnAddressPanel from './ReturnAddressPanel';
import LettrLabsPanel from './LettrLabsPanel';
import PanelWrapper from './PanelWrapper';
import ZoomButtons from './ZoomButtons';
import { motion, AnimatePresence } from 'framer-motion';

import './styles.scss';

export default function CardEditor() {
  const cesdkRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [firstAccess, setFirstAccess] = useState(true);
  const { engine, cesdk, lettrLabsPanel, returnAddressPanel, setLettrLabsPanel, setReturnAddressPanel } = useEditorContext();

  useCreateCardEditor(cesdkRef);
  useStyledEditor();
  useLoadCardScene();
  useBlockSelection();

  const handlePanelClose = () => {
    engine?.variable?.setString('returnAddress', 'false');
    setReturnAddressPanel(false);
  };

  useEffect(() => {
    if (cesdk) {
      
      const handleClick = (event: Event) => {
        const path = event.composedPath();
        const button = path.find((el) => {
          const element = el as HTMLElement;
          return (
            element.classList?.contains("UBQ_Button-module__block--114Ku") &&
            element.getAttribute("aria-label") === "Close"
          );
        });

        if (button) {
          const instance = cesdk;
          if (instance) {
            instance.ui.closePanel('myCustomPanelFormat');
            instance.ui.closePanel('//ly.img.panel/assetLibrary');
          }
        }
      };

      document.addEventListener("click", handleClick, true);
      const instance = cesdk;
      instance.ui.registerComponent('lettrLabs.dock', ({ builder: { Button } }) => {
        const isSelected = instance.engine.variable.getString('lettrLabs') === 'true';

        Button('lettrLabs.button', {
          label: 'Format',
          isSelected,
          onClick: () => {
            setFirstAccess(false)
            const isOpen = instance.ui.isPanelOpen('myCustomPanelFormat');
            setIsOpen(prev => !prev);
            if (isOpen) {
              setLettrLabsPanel(false)
              instance.ui.closePanel('myCustomPanelFormat');
            } else {
              setLettrLabsPanel(true)
              setTimeout( () => instance.ui.openPanel('myCustomPanelFormat') , 500)     
            }
          },
        });
      });

    }
  }, [cesdk, setLettrLabsPanel]);

  useEffect(() => {
    if(lettrLabsPanel === false && !firstAccess){
      const instance = cesdk;
      if(instance){
        instance.ui.closePanel('myCustomPanelFormat');
        setIsOpen(false)
      }
    }
  },[lettrLabsPanel, cesdk, firstAccess])

  return (
    <>
      <Box ref={cesdkRef} style={{ height: '85vh', flex: 1 }} />
      <div className='card-editor-box'>
      < LettrLabsPanel />
      </div>
      <AnimatePresence>
        {isOpen && (
          <div className='container-to-hide'>
            <motion.div
              initial={{ left: -500, position: 'relative', zIndex: 14, width: '100%', height: '100%'}}
              animate={{ left: 0, position: 'relative' }}
              exit={{ left: -800, position: 'relative' }}
              transition={{ duration: 0.5 }}
            >
              <LettrLabsPanel />
            </motion.div>
          </div>

        )}
      </AnimatePresence>
      <Collapse in={returnAddressPanel}>
        <PanelWrapper title="Return Addresss" onPanelClose={handlePanelClose}>
          <ReturnAddressPanel />
        </PanelWrapper>
      </Collapse>
      <ZoomButtons />
    </>
  );
}
