import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import useDataSourcesService from 'services/data-sources';
import MDButton from 'material-ui/components/MDButton';
import { useGlobal } from 'context/global-context';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function LeadRevealRevokeModal() {
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();
  const navigate = useNavigate();
  const { revokeDataSource } = useDataSourcesService();
  const [searchParams, setSearchParams] = useSearchParams();
  const open = searchParams.get('modal') === 'RevokeLeadReveal';

  const { mutate: revoke } = useMutation({
    mutationFn: () => revokeDataSource('lead-reveal'),
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
      setSearchParams('', { replace: true });
      setShowLoader(false);
    },
  });

  return (
    <Dialog open={open} onClose={() => navigate(-1)}>
      <DialogContent>
        <DialogTitle display={'flex'} alignItems={'center'} sx={{ mb: 1, svg: { mr: 1, height: 32 } }}>
          Are you sure you want to revoke your setup?
        </DialogTitle>
        <Box component={'ul'}>
          <Typography component={'li'} fontSize={16} ml={3}>
            Revoking your setup will permanently delete any old tags and discard any collected leads that were not yet processed
          </Typography>
        </Box>

        <Typography variant="h6" fontSize={18} mt={2} mb={1}>
          To re-setup LeadReveal, please follow the following steps
        </Typography>
        <Typography fontSize={16} ml={3}>
          <strong>Step 1:</strong> Remove the old LeadReveal header and footer tags
        </Typography>
        <Typography fontSize={16} ml={3}>
          <strong>Step 2:</strong> Follow the LeadReveal setup process again, adding new header and footer tags to your site
        </Typography>
        <Box display={'flex'} gap={1}>
          <MDButton fullWidth sx={{ mt: 2 }} onClick={() => navigate(-1)}>
            Cancel
          </MDButton>
          <MDButton fullWidth color="light" sx={{ mt: 2 }} onClick={() => revoke()}>
            Revoke LeadReveal
          </MDButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
