import { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, InfoOutlined, RemoveCircleOutline, SettingsOutlined } from '@mui/icons-material';
import { AuthenticatedStatusType } from 'services/data-sources/data-sources.types';
import MDIconButton from 'material-ui/components/MDIconButton';
import MDSelect from 'material-ui/components/MDSelect';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import { useGlobal } from 'context/global-context';
import { ISetupOption } from '..';

const SmartAutomationSetupItem = ({
  name,
  isEnabled,
  authenticationStatus,
  isComingSoon,
  learnMoreText = '',
  learnMoreVideo = '',
  logo,
  onSetup,
  onFinishSetup,
  onManage,
  onRevoke,
  onDisable,
}: ISetupOption) => {
  const { showLoader } = useGlobal();
  const [showLearnMoreModal, setShowLearnMoreModal] = useState<boolean>(false);
  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false);

  const renderActionButton = () => {
    if (showLoader) return;
    if (authenticationStatus !== AuthenticatedStatusType.Authenticated) {
      const isPending = authenticationStatus === AuthenticatedStatusType.Pending;
      if (isPending && onFinishSetup) {
        return (
          <MDButton fullWidth color={'secondary'} onClick={() => onFinishSetup()}>
            <SettingsOutlined sx={{ mr: 1 }} />
            Finish Setup
          </MDButton>
        );
      }
      return (
        <MDButton fullWidth color={'primary'} onClick={() => onSetup?.()}>
          <SettingsOutlined sx={{ mr: 1 }} />
          Setup
        </MDButton>
      );
    }
    if (onManage) {
      return (
        <MDButton fullWidth color={'light'} onClick={() => onManage()}>
          <SettingsOutlined sx={{ mr: 1 }} />
          Manage
        </MDButton>
      );
    }
    if (onRevoke) {
      return (
        <MDButton fullWidth color={'error'} variant="transparent" onClick={() => setShowRevokeModal(true)}>
          <RemoveCircleOutline sx={{ mr: 1 }} />
          Revoke
        </MDButton>
      );
    }
    if (onDisable) {
      return (
        <MDSelect
          fullWidth
          value={Number(isEnabled)}
          onChange={(event) => (event.target.value !== isEnabled && event.target.value ? onSetup?.() : onDisable())}
          color={isEnabled ? 'success' : 'error'}
        >
          <MenuItem value={1} onClick={() => !isEnabled && onSetup?.()}>
            Active
          </MenuItem>
          <MenuItem value={0} onClick={() => isEnabled && onDisable()}>
            Inactive
          </MenuItem>
        </MDSelect>
      );
    }
  };

  return (
    <Grid item width={'100%'} xs={12} md={6} lg={4}>
      <Paper square variant="outlined">
        <Box display="flex" height={140} p={2} alignItems={'center'} justifyContent={'center'}>
          <img
            alt={name}
            src={logo}
            width={'90%'}
            height={'auto'}
            style={{ objectFit: 'contain', objectPosition: 'center', maxHeight: 108 }}
          />
        </Box>
        <MDCard borderRadiusSize={0}>
          <Stack height={54} justifyContent={'bottom'} px={1} textAlign={'center'}>
            {isComingSoon ? (
              <Typography>Coming Soon!</Typography>
            ) : (
              <Box display={'flex'} gap={1} mb={3}>
                {authenticationStatus === AuthenticatedStatusType.Authenticated && isEnabled && !onDisable && (
                  <MDButton
                    disabled
                    fullWidth
                    sx={{ '&:disabled': { color: 'green', backgroundColor: '#E8F2EE', opacity: 1 } }}
                  >
                    <Check sx={{ mr: 1 }} />
                    Active
                  </MDButton>
                )}
                {renderActionButton()}
                {(learnMoreText || learnMoreVideo) && (
                  <Tooltip title="Learn more">
                    <MDIconButton backgroundColor="light" onClick={() => setShowLearnMoreModal(true)}>
                      <InfoOutlined />
                    </MDIconButton>
                  </Tooltip>
                )}
              </Box>
            )}
          </Stack>
        </MDCard>
      </Paper>

      <Dialog open={showRevokeModal} onClose={() => setShowRevokeModal(false)} maxWidth="sm">
        <DialogContent>
          <Typography variant="h6">Are you sure you want to revoke your {name} setup?</Typography>
          <Typography fontSize={16} mt={2}>
            Related automations will no longer run, and you won't be able to create new ones until you set up again.
          </Typography>
          <Stack direction={'row'} spacing={2} mt={2}>
            <MDButton
              fullWidth
              onClick={() => {
                setShowRevokeModal(false);
                onRevoke?.();
              }}
            >
              Revoke
            </MDButton>
            <MDButton fullWidth color="light" onClick={() => setShowRevokeModal(false)}>
              Cancel
            </MDButton>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth={learnMoreText && learnMoreVideo ? 'lg' : 'md'}
        open={showLearnMoreModal}
        onClose={() => setShowLearnMoreModal(false)}
      >
        <DialogContent>
          <DialogTitle>Learn more about {name} setup</DialogTitle>
          <Grid container spacing={2}>
            {learnMoreText && (
              <Grid item xs={12} md={learnMoreVideo ? 6 : 12}>
                <Typography variant="paragraphMedium">{learnMoreText}</Typography>
              </Grid>
            )}
            {learnMoreVideo && (
              <Grid item xs={12} md={learnMoreText ? 6 : 12}>
                <video width="100%" controls>
                  <source src={learnMoreVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default SmartAutomationSetupItem;
